$primary-color: $td-blue;
$secondary-color: $warm-gray;

.user-profile__card {
  .card-body {
    padding: 20px 20px 5px;
  }

  .onboarding-status {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .btn-sm {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.experience-list {
  margin-bottom: 0 !important;
  .fa-user-clock {
    font-size: 50px;
    color: #D7DDE5;
  }
  &--item {
    position: relative;
    background-color: #FAFAFA;
    padding: 15px;
    z-index: 10;
    .company {
      font-size: 18px;
      line-height: 22px;
    }
    .period {
      font-size: 14px;
      color: $warm-gray;
    }
    &__with-actions {
      padding: 15px 35px;
    }
    &__drag-handle {
      position: absolute;
      left: -5px;
      top: 3px;
      z-index: 2;
      &:hover {
        cursor: move;
      }
    }
    &__editable {
      padding-left: 35px;
    }
  }
}

.user-profile {
  .onboarding-status {
    margin-bottom: 0;

    .status--tag {
      font-size: 14px;
    }
  }

  &_tabs {
    margin-bottom: -5px;
  }

  &__summary {
    color: $greyish-brown;
    line-height: 20px;

    .dropdown {
      &.show {
        color: $black;
      }

      .dropdown-menu {
        min-width: 170px;
      }

      .dropdown-toggle{
        padding: 0;
      }
    }

    .profile-pic {
      margin-right: 20px;
      display: block;
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 65px;
    }

    .rating {
      vertical-align: baseline;
    }

    .tag span {
      color: $warm-gray;
    }
  }

  &__basic-info {
    flex: 1;

    .markdown-content  p {
      margin: 0;
      padding: 0 5px 0 0;
      display: inline;
    }
  }

  &__extra-info {
    font-size: 14px;
    color: $black;
    margin: 8px 0 10px;
  }

  &__rating {
    color: $warm-gray;
  }

  &__meta {
    font-size: 13px;
    margin-top: 15px;
    padding-top: 30px;
    padding-bottom: 25px;
    border-top: 1px solid $graphite-light;

    > div {
      flex-basis: 250px;
    }

    .value {
      color: $black;
      font-weight: $font-weight-semibold;
      font-size: 13px;
    }

    .label {
      color: $warm-gray;
      font-size: 13px;
    }
  }

  &__name {
    color: $black;
    font-size: 18px;
    line-height: 22px;
    font-weight: $font-weight-semibold;
  }

  &__job-title {
    max-width: 80%;
  }

  &__address {
    font-size: 14px;
    max-width: 240px;

    i {
      line-height: 18px;
    }
  }

  &__email,
  &__email:hover {
    color: $greyish-brown;
  }

  &__skills {
    .skill-list {
      margin-bottom: -10px;
    }
  }

  &__tabs {
    align-self: stretch;

    .label {
      color: $warm-gray;
      display: block;
      font-size: 14px;
      text-align: left;
      padding-left: 0;
      font-weight: 200;
    }
  }
  &__buttons {
    a {
      cursor: pointer;
      color: $warm-gray !important;
    }
    .fab,
    .fal {
      font-size: 16px;
      &:hover {
        color: $td-blue;
      }
    }
  }

  &__light-border-top {
    border-top: 1px solid $pale-gray;
  }

  &__joined-at {
    padding-top: 16px;;
    @include details-rows;

    &.onboarding-status-visible {
      padding-top: 32px;
    }
  }

  &__onboarding-progress {
    .card-header, .step {
      border-bottom: 1px solid $pale-gray;
    }

    .card-header .get-started-progress {
      font-size: 14px;

      .bar-wrapper {
        margin-top: 6px;
      }
    }
    .step {
      .description.completed {
        color: $warm-gray;
        text-decoration: line-through;
      }

      span {
        color: $medium-green;
      }
    }
  }
}

.page--profile {
  .td_api_connected-loaded {
    align-self: stretch;
    width:  100%;
    display: flex;
    flex-direction: row;
  }
}

.onBoarding-progress-bar {
  background-color: #fff;
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 4px;
  border-radius: 4px;

  &__line {
    position: absolute;
    left: 0;
    top: 0;
    height: 4px;
    background-color: $warning;
    border-radius: 4px;
  }
}

.account-setup-list {
  &.dropdown-menu {
    width: 350px;
    max-width: 100%;
    padding: 15px 0;

    &:after,
    &:before {
      display: none;
    }
  }

  &__progress-indicator {
    font-size: 1rem;
    line-height: 1.6rem;
    margin-left: auto;
    position: relative;
  }

  &__toggle {
    margin: 0 15px;
    color: $greyish-brown;
    font-size: 14px;
    font-weight: $font-weight-semibold;
    display: flex;
    align-items: center;
    padding: 16px;
    border-radius: $border-radius;

    &:hover {
      background-color: $athens-gray;
    }

    i {
      font-size: 16px;
      margin-right: 15px;
    }
  }

  &.step-category,
  &.step-option.completed {
    cursor: default;
    &:hover,
    &:active,
    &:focus {
      cursor: default;
      background-color: transparent;
    }
  }

  &.step-option:not(.completed):hover {
    .account-setup__step-icon {
      color: $primary-color;
    }
  }

  &.step-option:last-child {
    margin-bottom: 7px;
  }


  .account-setup {
    &__header {
      color: $greyish-brown;
      font-size: 1.3rem;
      position: relative;
      width: 100%;
      margin-left: 15px;
      margin-right: 15px;

      span {
        position: relative;
        background-color: $white;
        padding-right: 10px;
      }

      &::before {
        content: "";
        height: 1px;
        background: $pale-gray-seven;
        top: 49%;
        width: calc(100% - 30px);
        position: absolute;
        left: 0;
      }
    }

    &__step {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 15px;

      .fas.fa-chevron-right {
        color: $secondary-color;
        margin-right: 2px;
      }

      &--completed {
        color: $secondary-color;
        .account-setup__step-description {
          text-decoration: line-through;
        }
        .fal.fa-check {
          color: $primary-color;
        }
        &:hover {
          background-color: transparent;
        }
      }

      &-description {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: map-get($grid-breakpoints, md)) {
  .user-profile {
    &__column--1 {
      flex-basis: 100%;
      max-width: 100%;
      flex-shrink: 0;
      flex-grow: 0;
      padding: 0;
    }

    &__column--2 {
      width: auto;
      flex: 1;
      padding: 0;
    }

    &__summary {
      flex-wrap: wrap;
      justify-content: center;

      .profile-pic {
        margin: 0;
        width: 125px !important;
        height: 125px !important;
        flex-basis: 125px !important;
      }
    }

    &__basic-info {
      flex-basis: 100%;
    }

    &__extra-info {
      > span {
        display: block;
        width: 100%;
        max-width: 100%;
        text-align: center;
      }
    }

    &__buttons {
      .btn {
        padding: 5px 10px;
        font-size: 11px;

        + .btn {
          margin-left: 10px;
        }
      }
    }
  }
}

@media (max-width: map-get($grid-breakpoints, sm)) {
}
