.skill-list-field {
  .skill-list {
    padding: 8px 8px 0;
    min-height: 40px;
    border: 1px solid $silver;
    border-radius: $border-radius;
  }
}

.skill-list {
  .add-skill-button {
    font-size: 18px !important;
  }
}

.add-skill-button,
.add-skill-button:not([href]) {
  font-size: 16px;
  color: $warm-gray;
  vertical-align: middle;

  &:hover {
    color: $td-blue;
  }
}

.add-skill-button--circle {
  width: 32px;
  padding-left: 0!important;
  padding-right: 0!important;
}

.skill-selector-tag {
  .btn-vote {
    &.up-vote {
      .fas {
        display: none;
      }
    }
  }
}

.skill-tag {
  padding: 4px 6px;
  margin: 0 5px 5px 0;
  cursor: default;

  &__remove-button {
    padding: 0;

    i {
      font-size: 12px !important;
      line-height: 1 !important;
      color: $greyish-brown;
    }

    &:hover {
      i {
        color: $black;
      }
    }
  }
}

.skill-list,
.tag-list {
  position: relative;
  text-align: left;

  .show-all-link {
    color: $warm-gray;
  }

  .tag {
    display: inline-block;
    margin: 0 10px 10px 0;

    &:hover {
      background-color: rgba(21,132,198,0.05);
    }

    &:last-child {
      margin-right: 0;
    }

    &.bg-lightblue {
      span {
        background-color: $lightblue;
      }
    }

    &.pr-0 {
      > span {
        padding-right: 0 !important;
      }
    }

    span {
      margin: 0;
    }

  }

  &--collapsible {
    overflow: hidden;
    max-height: 34px;
    transition: all 0.3s ease-in-out;
  }

  &--expanded {
    height: auto;
    max-height: 400px;
  }

  &--inline {
    display: flex;
    padding-bottom: 2px;

    .tag {
      display: inline-block;
    }
  }

  &--inverted-colors {
    .tag > span{
      background-color: $white;
    }
  }

  &__toggle {
    display: inline-block;
    padding: 5px;
    line-height: 25px;
  }

  &__trimmed-list {
    overflow: hidden;
    :hover {
      cursor: pointer;
    }
  }

  .toggle-popup {
    color: $warm-gray;
    margin: 0 0 0 10px;

    &:hover {
      color: $black;
    }
  }

  &--right-aligned {
    text-align: right;
    padding-left: 20px;
    padding-right: 0;

    .tag {
      float: right;
    }
  }
}

.card-footer .skill-list,
.card-footer .tag-list {
  padding: 2px 0;
}

.skill-directory-card .rename-button {
  font-size: 16px;
  color: $warm-gray;
}

.skill-directory-card--preview {
  i {
    font-size: 35px;
    --fa-primary-color: #{$black};
    --fa-primary-opacity: 1;
    --fa-secondary-color: #{$pending};
    --fa-secondary-opacity: 1;
  }
}