.timeOff-list-item {
  border: 1px solid $graphite-light;
  background-color: $list-item-background-color;
  padding: 15px;
  border-radius: 4px;

  + .timeOff-list-item {
    margin-top: 10px;
  }

  &__period {
    font-size: 14px;
    color: $greyish-brown;
    font-weight: $font-weight-semibold;
    margin: 0;

    &--highlighted {
      colr: red;
    }
  }

  &__reason {
    font-size: 13px;
    color: $warm-gray;
  }
}
