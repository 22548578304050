@charset "utf-8";

/**
 * Styleguide specific SASS code
 */

.page--styleguide {

  section + section {
    margin-top: 50px;
  }

  section > h2 {
    border-bottom: 1px solid #aaa;
    padding-bottom: 15px;
  }

  .icons {
    .fal,
    .fas {
      font-size: 22px;
    }
  }

  #icons {
    i, .fal, .fas {
      font-size: 2.5rem;
    }
  }
}

.styleguide-menu {
  a {
    text-transform: capitalize;
    color: #505050;

    &.active {
      color: #0e6ead;
    }
  }
}
