.search-form {
  position: relative;

  form {
    position: relative;
  }

  form > .form-group {
    margin-bottom: 0;
  }

  &--clear-filters {
    text-align: center;
    display: block;
    margin: 15px 0 0;
  }

  .kw-field {
    .form-group {
      margin-bottom: 0;
    }
  }

  .search-button {
    position: absolute;
    top: 3px;
    right: 0;
    padding: 8px 10px;
    font-size: 19px;
    text-align: center;
    line-height: 14px;

    &.discreet {
      background-color: transparent;
      border-color: transparent;
      pointer-events: none;
    }

    span {
      margin-right: 0;
      font-size: 14px;
    }
  }

  .clear-kw {
    color: $warm-gray;
    position: absolute;
    top: 0;
    right: 30px;
    padding: 13px 5px;
    font-size: 13px;
    text-align: center;

    &:hover {
      cursor: pointer;
      color: $gray;
    }
  }

  .btn-default {
    border-color: $graphite-light;
  }

  .input-field {
    padding-right: 55px;
    height: 40px;

    &--active {
      color: $black;
      border-color: $warm-gray;
    }
  }
}

.modal-panel {
  .kw-field {
    flex: 1;
  }
}

.btn.toggle-filters-button {
  font-weight: $font-weight-regular;

  &--active:not(.toggle-filters-button--open),
  &--active:active,
  &--active:hover,
  &--active:focus,
  &--active:active:focus,
  &--active:active:hover {
    color: $white;
    background-color: $td-blue;
  }

  &--active {
    font-weight: $font-weight-semibold;
  }

  &--open.toggle-filters-button--active {
    border-color: $td-blue !important;
    background-color: $white !important;
    color: $black !important;
  }
}

.sort-by-dropdown {
  .btn {
    line-height: 16px;
    font-weight: $font-weight-regular !important;

    i {
      font-weight: $font-weight-regular;
    }
  }


  &--active {
    .btn {
      border-color: $td-blue !important;
    }
  }

  .dropdown-toggle {
    padding-right: 10px;

    strong {
      font-weight: $font-weight-semibold;
    }

    * {
      pointer-events: none;
    }

    i {
      font-size: 10px !important;
    }
  }
}

.filter-by-dropdown {
  .btn {
    line-height: 16px;
    font-weight: $font-weight-regular !important;

    i {
      font-weight: $font-weight-regular;
    }
  }


  &--active {
    .btn {
      border-color: $td-blue !important;
    }
  }

  .dropdown-toggle {
    padding-right: 10px;

    strong {
      font-weight: $font-weight-semibold;
    }

    * {
      pointer-events: none;
    }

    a {
      z-index:  9;
    }

    i {
      font-size: 10px !important;
      float: right;
    }
  }

  .clear-filter {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 9;
    cursor: pointer;
  }
}

.direction-popover {
  padding: 0;
  margin-top: 10px !important;
  width: auto;
  max-width: 100%;
  min-width: 240px;
  z-index: 9995;

  .form-group {
    margin-bottom: 0;
  }

  .sort-by-item + .sort-by-item{
    margin-top: 10px;
  }

  .popover-content {
    min-width: 245px;
    width: 100%;
    max-width: 100%;
  }

  label {
    display: block;
    margin: 0;
  }

  .rc-slider {
    margin-top: 25px !important;
  }

  .form-group--slider {
    padding-top: 1px;
  }

  .btn-cta {
    color: $td-blue;
    font-weight: $font-weight-regular !important;
  }
}

.search-filter-item {
  h4 {
    color: $black;
    font-size: 14px;
    font-weight: $font-weight-semibold;
    box-shadow: 0 1px 0 0 $graphite-light;
    padding: 10px 0;
    margin: 15px 0;
  }

  .rating {
    margin-left: 0;
  }

  &__label {
    display: block;
    color: $warm-gray;
    padding: 10px 23px 10px 12px;
    width: 100%;
    max-width: 100%;
    font-size: 14px;
    font-weight: $font-weight-regular;
    border: 1px solid $graphite-light;
    background-color: $white;
    line-height: 16px;
    cursor: pointer;
    border-radius: $borderRadius;

    .fal {
      color: $warm-gray !important;
      font-size: 10px;
      position: absolute;
      top: 14px;
      right: 13px;
      pointer-events: none;
    }

    &--active,
    &:hover,
    &:active {
      border-color: $warm-gray;
    }

    &--active {
      color: $black;
    }
  }

  &__header {
    padding: 15px;
    color: $greyish-brown;
    font-size: 14px;
    font-weight: $font-weight-regular;

    .close {
      padding: 2px;
      font-size: 17px;
    }
  }

  &__body {
    background-color: $pale-gray-two;
    padding: 20px 15px;
    max-height: 380px;
    width: 100%;
    overflow-y: auto;
    box-shadow: 0 -1px 0 0 #EDEDED, 0 1px 0 0 #EDEDED;

    label {
      cursor: pointer;
    }
  }

  &__footer .btn{
    font-weight: $font-weight-regular;
    font-size: 14px;
    padding: 15px;
  }
}

@media (max-width: map-get($grid-breakpoints, sm)) {
  // For mobile views, in case sorting dropdown is in place, show the search input in full width in a new row.
  .sort-by-dropdown {
    + .toggle-filters-button
    + .kw-field {
      width: 100% !important;
      margin: 10px 0 0!important;
      flex-grow: 1;
      flex-basis: 100%;
      z-index: 5;
    }
  }
  .filter-by-dropdown {
    + .toggle-filters-button
    + .kw-field {
      width: 100% !important;
      margin: 10px 0 0!important;
      flex-grow: 1;
      flex-basis: 100%;
      z-index: 5;
    }
  }
}

@media (min-width: map-get($grid-breakpoints, sm)) {
  .search-filters-list {
    button[type="submit"] {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
}

@media (max-width: (strip-unit(map-get($grid-breakpoints, md)) - 1) * 1px) {
  .search-form.search-open > form > div {
    padding-bottom: 50px;
  }

  .kw-field {
    position: absolute !important;
    width: calc(100vw - 42px) !important;
    top: 50px!important;
    max-width: 100vw;
  }

  .selectable-list-wrapper {
    .kw-field {
      position: absolute !important;
      width: 100% !important;
      top: 50px!important;
      max-width: 100vw;
    }
  }
}

.search-filters-container {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 26rem);

  @media (max-width: map-get($grid-breakpoints, lg)) { 
    height: calc(100vh - 32rem);
  }
}

.page--finance .search-filters-container,
.upgrade-trial-banner + div .search-filters-container {
  height: calc(100vh - 33rem);

  @media (max-width: map-get($grid-breakpoints, lg)) { 
    height: calc(100vh - 39rem);
  }
}



