.signup-form, .project-form {
  &__hourly-rate-container {
    > div:first-child{
      padding-right: 5px;
    }

    > div + div {
      padding-left: 5px;
    }

    .dropdown-menu {
      min-width: 0;
    }
  }
  .form-group.input--checkbox {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    justify-content: flex-end;
    label {
      flex: 1;
    }
    .help-block {
      flex: 1 0 100%;
    }
  }
}
