.custom-select-field {
  .show, &:hover, &:focus {
    .custom-select-field__toggle {
      border-color: $warm-gray;
    }
  }

  .Select {
    &.is-focused,
    &.is-open {
      .Select-arrow {
        top: auto;
        border: none;
      }

      .Select-control {
        border-color: $warm-gray;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }
  }

  .Select-option,
  .Select-menu,
  .Select-menu-outer {
    background-color: $white;
    width: 100%;
  }

  .Select-value {
    display: flex;
    position: relative !important;
    width: 100%;
  }

  .Select-multi-value-wrapper,
  .Select-value-label {
    display: flex;
    flex: 1;
  }

  .Select-arrow {
    border: none;
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before,
    &:after {
      position: absolute;
      content: "";
    }

    &:before {
      border-top: 7px solid $gray;
      border-right: 7px solid transparent;
      border-bottom: 0;
      border-left: 7px solid transparent;
      z-index: 0;
      right: 10px;
    }


    &:after {
      margin-right: 4px;
      border-top: 6px solid $white;
      border-right: 6px solid transparent;
      border-bottom: 0;
      border-left: 6px solid transparent;
      z-index: 1;
      right: 7px;
      margin-top: -1px;
    }
  }

  .Select-menu-outer {
    margin-top: 5px;
  }

  .Select-menu {
    padding: 0;
  }

  .Select-option {
    display: flex;

    &.is-selected,
    &.is-focused {
      background-color: inherit;
    }

    &:hover {
      background-color: $lightblue;
    }
  }
}

.currency-select {
  &__control {
    &--is-focused.currency-select__control {
      box-shadow: none;
      border-color: $td-blue;
    }
  }
  &__single-value {
    display: flex;
    width: 100%;
  }

  &__value-container {
    width: 100%;
    padding-right: 50px;
  }

  &__indicator {
    cursor: pointer;
  }

  &__currency {
    color: $black;
  }

  &__currency-description {
    font-size: 12px;
    color: $greyish-brown;
  }
}
