$color-gray: #e0dfdf;
$color-darkgrey: #ababab;

.fileuploader {
  display: block;
  padding: 10px;
  background-color: $pale-gray-two;
  overflow: hidden;
  border: 1px dashed $graphite-light;
  color: $color-darkgrey;
  border-radius: $borderRadius;
  font-style: italic;
  cursor: pointer;
  box-sizing: border-box;
  transition: 0.2s all;

  .attachments-list {
    margin: 5px -1%;

    .fileuploader-item {
      width: 31%;
    }
  }

  &--white {
    .fileuploader {
      background-color: $white;
    }
  }
  &--inverted-colors {
    background-color: $white;
  }
}

.attachments-list--grid-view {
  display: grid;
  justify-items: start;
  grid-template-columns: 25% 25% 25% 25%;
  width: 100%;
  grid-gap: 10px;
  margin: 0 -15px;
  padding: 0 15px;

  .fileuploader-item {
    width: 100%;
    margin: 0 !important;
  }
}

.has-error .fileuploader {
  border-color: $tomato-red;
}

.fileuploader__wrapper {
  display: inline-block;
  width: 100%;
}

.fileuploader__files {
  display: block;
  position: relative;
}

.fileuploader-item {
  position: relative;
  padding: 10px;
  background-color: $pale-gray-two;
  border-radius: $borderRadius;
  display: block;
  margin-bottom: 10px;
  max-width: 100%;

  &--inverted-colors {
    background-color: $white;

    .fileuploader-item__icon-wrapper {
      background-color: $pale-gray-two;
    }
  }

  a {
    color: $greyish-brown;
  }

  &__meta {
    @extend .truncate;
    padding-right: 25px;
    word-break: break-all;
  }

  &__icon-wrapper {
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: $white;
    border-radius: $borderRadius;
    display: inline-block;
    margin-right: 10px;

    .fal, .fas {
      font-size: 20px;
      color: $graphite-dark;
      vertical-align: middle;
    }
  }

  &__thumb, img {
    width: auto;
    max-height: 100%;
    max-width: 100%;
  }

  &__size {
    font-size: 12px;
    color: $warm-gray;
  }

  &__download,
  &__additional-actions {
    float: right;
    margin: 12px 5px 0 0;

    &:hover {
      color: $black;
    }
  }

  &__remove {
    float: right;
    margin: 15px 10px 0 10px;
    color: $graphite-light;
    font-weight: $font-weight-semibold;
    font-size: 14px;
    transition: 0.2s opacity;
    cursor: pointer;

    &:hover {
      color: $graphite-dark;
    }
  }
}

.fileuploader__attnt {
  color: $td-blue;
}

.fileuploader__text {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 24px 0;
}

.file-uploader-disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: default;
}
