.project-worksheets-list {
  max-height: 425px;
  overflow-x: hidden;
  overflow-y: auto;

  &__meta {
    box-shadow: 0 1px 0 $graphite-light;
  }
}

.project-worksheets-list-item {
  position: relative;
  &:last-child {
    margin-bottom: 0 !important;
  }
}

.project-worksheets-list__line-container {
  width: 100%;
  position: relative;
  display: flex;
}

.project-worksheets-list__line {
  position: relative;
  height: 8px;
  border: 2px solid $white;
  display: inline-flex;
  background-color: gray;

  & + .project-worksheets-list__line {
    margin-left: -4px;
  }

  &:nth-child(1) {
    z-index: 2;
    border-left-width: 0;
    border-radius: 3px 4px 4px 3px;
  }

  &:nth-child(2) {
    z-index: 1;
    border-right-width: 0;
    border-radius: 4px 3px 3px 4px;
  }

  &--success {
    background-color: $medium-green;
  }

  &--pending {
    background-color: $pending;
  }
}

.billing-period-list-item {
  > a {
    box-shadow: 0 1px 0 $pale-gray-two inset;

    &:hover {
      background-color: $pale-gray-four;
    }
  }
}
