.page--roles {
  .roles-table {
    margin-bottom: 75px;
  }

  .permissions-wrapper {
    gap: 25px;

    @media (max-width: map-get($grid-breakpoints, md)) {
      gap: 0;
    }
  }

  .permissions-list {
    @media (min-width: map-get($grid-breakpoints, md)) {
      width: 50% !important;
    }
  }
}

.page--roles, .permission-members-list__body {
  .fa-duotone.fa-user, .fa-duotone.fa-key {
    --fa-primary-color: #{$black};
    --fa-secondary-color: #{$pending-orange};
  }

  .fa-duotone.fa-user-gear {
    --fa-primary-color: #{$pending-orange};
    --fa-secondary-color: #{$black};
  }

  .fa-duotone.fa-calculator {
    --fa-primary-color: #{$black};
    --fa-secondary-color: #{$td-blue};
  }

  .fa-duotone.fa-bars-progress {
    --fa-primary-color: #{$black};
    --fa-secondary-color: #{$tomato-red};
  }

  .fa-duotone.fa-gear {
    --fa-primary-color: #{$td-blue};
    --fa-secondary-color: #{$black};
  }

  .fa-duotone.fa-list-check {
    --fa-secondary-color: #{$pending};
    --fa-secondary-opacity: 1.0;
  }

  .fa-duotone.fa-list-check {
    --fa-primary-color: #{$black};
    --fa-secondary-color: #{$pending-orange};
  }

  .fa-duotone.fa-user-crown,
  .fa-duotone.fa-file-invoice,
  .fa-duotone.fa-file-chart-pie {
    --fa-primary-color: #{$black};
    --fa-secondary-color: #{$tomato-red};
  }

  .fa-duotone.fa-clipboard-list-check {
    --fa-secondary-color: #{$td-blue};
  }

  [class*="fa-"] {
    --fa-secondary-opacity: 1;
  }

  .fal.fa-circle-half-stroke,
  .fal.fa-circle-check,
  .fal.fa-circle {
    font-size: 14px;
  }

  .icon {
    position: relative;
    font-size: 24px;

    i.fa-file {
      --fa-primary-color: #{$black};
      --fa-secondary-color: #{$pending};
      --fa-secondary-opacity: 1;

    }

    span {
      font-size: 7px;
      font-weight: 1000;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.permissions-field {
  &__list-item {
    border-radius: $border-radius;
    border: 1px solid $pale-gray;
    cursor: pointer;

    &:hover {
      background-color: $pale-gray-two;
    }

    + .permissions-field__list-item {
      margin-top: .8rem;
    }

    &.selected {
      background-color: $greyish-green-transparent;
      border-color: $medium-green;
    }
  }

  &__label {
    font-size: 18px;
    color: $black;
    margin: 0;
  }

  &__description {
    font-size: 14px;
    color: $warm-gray;
    margin: 0;
  }
}

.modal-body {
  .permission-members-list__body {
    margin: 0;

    h3 {
      background: $white;
      color: $black;
      font-size: 18px;
      font-weight: 400;
      text-transform: none;

      &:first-child {
        padding-top: 0;
        margin-top: 0 !important;
      }
    }
  }
}

.permission-members-list__members-lenght {
  color: $warm-gray;
}

.permission-user-cards {
  &__list-item {
    border-radius: $border-radius;
    background-color: $pale-gray-two;
    border: 1px solid $pale-gray;
  }

  &__name {
    color: $black;
    font-size: 18px;
    margin: 0;
  }

  &__roles {
    color: $warm-gray;
    font-size: 14px;
    margin: 0 0 0 5px;
  }
}

.permission-roles-cards {
  &__list-item {
    border-radius: $border-radius;
    background-color: $pale-gray-two;
    border: 1px solid $pale-gray;
  }

  &__name {
    color: $td-blue;
    font-size: 18px;
    margin: 0;
  }
}

.roles-form {
  &__wrapper {
    gap: 25px;

    i.fa-pen {
      color: $warm-gray;
      cursor: pointer;
      font-size: 20px;
      margin-left: 10px;
    }
  }

  &__title {
    margin-bottom: -15px;
  }

  &__subgroups {
    @media (min-width: map-get($grid-breakpoints, md)) {
      width: 50% !important;
    }
  }

  &__action-buttons {
    background-color: $white;
    bottom: 0;
    position: fixed;
    right: 0;
    width: calc(100% - #{$nav-bar-width});

    &-wrapper {
      margin: 10px 25px;
    }

    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 100%;
    }
  }

  &__system-role-label {
    color: $black;
    font-size: 26px;
    margin: 0;
  }

  @media (max-width: map-get($grid-breakpoints, md)) {
    &__wrapper {
      gap: 0;
    }

    &__wrapper {
      width: 100%;
    }

    &__modal {
      display: none;
    }
  }
}

.subgroup-permissions {
  &__container {
    flex: 1;
    width: calc(50% - 12.5px);
    position: relative;

    > .modal-content {
      position: sticky;
      top: 0;
    }
  }

  &__description {
    color: $warm-gray;
    font-size: 14px;
  }

  &__separator {
    background-color: $pale-gray;
    height: 1px;
    margin: 22px 0;
    width: 100%;
  }

  &__list-item {
    .custom-switch {
      min-width: 30px;
    }
  }

  &__item-label {
    color: $black;
    font-size: 14px;
  }

  &__item-description {
    color: $warm-gray;
    font-size: 12px;
  }
}

.roles-field{
  &__list-item {
    border-radius: $border-radius;
    background-color: $white;
    border: 1px solid $graphite-light;

    .fa-duotone {
      font-size: 36px !important;
    }

    &:hover {
      background-color: $pale-gray-two;
    }
  }

  &__info-wrapper {
    width: 40%;
  }

  &__label {
    color: $black;
    font-size: 22px;
    margin-bottom: 5px;

    a {
      color: $black;
    }
  }

  &__type {
    color: $warm-gray;
    font-size: 14px;
    margin: 0;
  }

  &__permissions-wrapper {
    i {
      color: #{$pending};
      font-size: 20px !important;
    }
  }

  &__members-wrapper {
    i {
      color: #{$tomato-red};
      font-size: 20px !important;
    }
  }

  &__actions-button-mobile {
    margin-left: auto;
  }

  @media (max-width: map-get($grid-breakpoints, sm)) {
    &__list-item {
      flex-direction: column;
      align-items: flex-start;
    }

    &__info-wrapper {
      width: 100%;
    }

    &__permissions-wrapper, &__members-wrapper {
      margin: 10px 0 0 62px;
    }

    &__actions-button {
      display: none;
    }
  }

  @media (min-width: map-get($grid-breakpoints, sm)) {
    &__actions-button-mobile {
      display: none;
    }
  }
}

.subgroups-permissions-card {
  &__list-item {
    border-radius: $border-radius;
    border: 1px solid $graphite-light;
    cursor: pointer;

    .icon--service-orders .fa-duotone {
      // --fa-primary-color: #{$td-blue};
      --fa-secondary-color: #{$td-blue};
      //  --fa-secondary-opacity: 0.3;
    }

    &:hover {
      border-color: $medium-green;
    }

    &.selected {
      background-color: $greyish-green-transparent;
      border-color: $medium-green;
    }
  }

  &__label {
    color: $black;
    font-size: 18px;
    margin: 0;
  }

  &__state {
    color: $medium-green;
    font-size: 14px;
    margin: 0;
    min-width: 100px;

    i {
      margin-right: 5px;
    }
  }
}

.roles-list {
  &__item-actions {
    margin-top: 15px !important;

    &:before {
      right: 25px !important;
    }
    &:after {
      right: 26px !important;
    }

    &-empty {
      width: 50px;
    }
  }
}

.empty-list-message {
  .fa-duotone.fa-user {
    --fa-primary-color: #{$graphite-dark};
    --fa-secondary-color: #{$graphite-dark};
    --fa-secondary-opacity: 0.3;

    font-size: 64px;
  }
}