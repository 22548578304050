.tasks-widget-list {

  &__item {
    padding: 15px;
    border-bottom: 1px solid $pale-gray;

    &:last-child {
      border-bottom: 0px;
    }

    .status--tag.rate {
      background-color: #EDEFF2;
    }

    .task-card {
      &__details {
        font-size: 12px;
        color: $warm-gray;
      }
    
      &__assignments {
        min-height: 36px;
    
        span + span {
          margin-left: -5px;
        }
      }
    
      &__status {
        flex: 1;
        font-weight: $font-weight-semibold;
      }
    
      &__basic-info {
        flex: 1;
        font-size: 13px;
      }
    
      &__title {
        flex: 1;
        font-size: 15px;
      }
    
      &--disabled {
        opacity: 0.5;
      }
    
      .profile-pic {
        border: 2px solid #fff;
      }
    
      .profile-pic + .profile-pic,
      .profile-pic-wrapper + .profile-pic-wrapper {
        margin-left: -14px;
      }
    }
  }
}
