textarea.form-control {
  height: 117px;
  resize: vertical; /* user can resize vertically, but width is fixed */

  &.textarea-half {
    height: 50px;
  }
}

.form-control {
  height: 40px;
  box-shadow: none;
  outline: none;
  border-color: $silver;

  &:focus {
    outline: none;
    border-color: $td-blue;
    box-shadow: none;
  }
}

.form-group {
  &:not(.has-error) {
    margin-bottom: 2.5rem;
  }

  &.has-error + {
    .help-block {
      margin-bottom: 2.5rem;
    }
  }

  &.input {
    &--checkbox {
      label {
        margin: 0;
      }
    }
  }

  .radio-inline {
    margin-left: 13px;
    margin-right: 15px;
  }

  &.input, .input {
    &--markdown-input,
    &--markdown-textarea {
      position: relative;
    }
  }

  &.input, .input {
    &--markdown-input {
      input {
        padding-right: 60px;

        &:focus, &:active, &:hover {
          & + .add-link {
            display: inline-block;
          }
        }

        & + .add-link {
          &:focus, &:hover, &:active {
            display: inline-block;
          }
        }
      }

      .add-link {
        font-weight: $font-weight-semibold;
        position: absolute;
        padding: 3px 10px;
        top: 8px;
        right: 8px;
        font-size: 12px;
        color: $warm-gray;
        display: none;
      }
    }
  }

  &.selection-field-disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
  }
}

.form-group + .warning {
  opacity: 0.5;
  font-weight: bold;
  margin-bottom: 1em;
  display: block;
}

.form-dirty {
  background: #eee;
}

input[type="checkbox"] + label {
  padding-left: 5px;
}

.input-group-text {
  background-color: $pale-gray-two;
  border-color: $silver;
  font-size: 1.5rem;
}

input[type="checkbox"].form-control {
  display: inline-block;
  width: auto;
  height: auto;
  margin: 3px 10px 0 0;

  + .hint {
    padding-left: 21px;
  }
}

input.input-lg {
  height: 45px;
  font-size: 14px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: $borderRadius;
}

.has-error .form-control,
.has-error .dropdown .btn {
  border-color: $tomato-red;

  &:focus {
    border-color: $tomato-red;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px $tomato-red;
  }
}

.has-error .currency-select__control {
  border-color: $tomato-red;
  &--is-focused {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px $tomato-red;
  }
}

.has-error .input-group {
  .input-group-prepend {
    border: 1px solid $tomato-red;
    border-right: none;

    & + input {
      border-left-color: $gray-400;
    }
  }
}

.has-error .help-block {
  display: block;
  clear: both;
  color: $tomato-red;
  font-weight: $font-weight-regular;
  word-break: break-word;
}

.button-set {
  .btn {
    margin-bottom: 15px;
  }
}

.search-container {
  padding-right: 5px;

  .icon-search {
    position: absolute;
    left: 25px;
    top: 14px;
    font-size: 20px;
    color: $greyish-brown;
    pointer-events: none;
  }
}


label .sublabel {
  display: block;
}

.input-email-tags {
  &__tag {
    background-color: $pale-gray;
    display: inline-flex;
    padding: 16px;
    border-radius: 4px;
    border: 1px solid $pale-gray;
    align-items: center;

    &--user-icon {
      color: $nice-blue;
      font-size: 20px;
    }

    &--value {
      background-color: $pale-gray;;
      padding: 10px 0px;
      border-radius: 4px;
      min-width: 200px;
    }

    &--remove-btn {
      font-size: 21px;
      margin: 10px 8px;
      cursor: pointer;
    }

    &--add-btn-wrapper {
      display: inline-flex;
      vertical-align: top;
      margin-top: 20px;
    }

    &--add-btn {
      font-size: 21px;
      margin: 10px 15px;
      cursor: pointer;
      color: #52C2CC;
    }

    .react-tagsinput-input {
      background-color: $white;
      padding: 10px 16px;
      border-radius: 4px;
      width: 220px;
      line-height: 18px;
      margin: 0px;
      width: 230px;
      border-radius: 4px;
    }
  }

  .react-tagsinput {
    border: 0px;
  }
}
