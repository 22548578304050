
.container-fluid__footer {
  padding-bottom: 80px !important;
}

footer.static-footer {
  margin: 100px 0 0;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 0 30px 50px 0 rgba(0,0,0,0.1);

  h4 {
    color: #fff;
    font-weight: $font-weight-semibold;
    font-size: 32px;
    line-height: 37px;
    margin-bottom: 10px;
  }

  p {
    color: #fff;
    font-weight: 400;
    font-size: 20px;
    margin: 0;
    line-height: 1.5em;
  }

  .request-trial-banner {
    display: flex;
    padding: 50px 65px;
    color: $black;
    background-color: rgba(21, 132, 198, 0.05);

    h4, p {
      color: $black;
    }
  }

  .quick-links {
    padding: 40px 65px;

    h4 {
      font-size: 13px;
      font-weight: 500;
      text-transform: uppercase;
      color: #999;
      margin-bottom: 10px;
    }

    ul {
      display: block;
      margin: 0;
      padding: 0;

      li {
        display: block;

        a {
          color: $black;
          font-size: 16px;
          font-weight: 400;

          &:hover {
            color: $black;
            text-decoration: underline;
          }
        }

        & + li {
          margin-top: 12px;
        }
      }
    }
  }

  .social-links ul {
    display: block;
    margin-top: 20px;

    li {
      display: inline-block;
      margin: 0;

      a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        background-color: #999;
        border-radius: 15px;
        color: #fff;

        &:hover {
          color: #fff;
          text-decoration: none;
          background-color: #777;
        }
      }

      + li {
        margin-left: 15px;
      }
    }
  }

  @media (max-width: 991px) {
    margin: 80px 0 0;
  }

  @media (max-width: 767px) {
    .container-fluid__footer {
      padding-bottom: 150px !important; // So that hubspot widget is not covering the footer.
    }
  }

  @media (max-width: 576px) {
    .request-trial-banner {
      padding: 50px
    }
    .quick-links {
      padding: 30px 15px;
    }
  }
}
