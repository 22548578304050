.system-message {
  position: relative;
  background-color: $white;
  border: 1px solid;
  border-radius: $borderRadius;
  overflow: hidden;

  .text-dark {
    color: $greyish-brown !important;
  }

  &__title {
    display: flex;
    color: $black;
    font-size: 14px;
    line-height: 17px;
    font-weight: $font-weight-semibold;
    padding: 15px;
    border-radius: $borderRadius $borderRadius 0 0;

  }
  &__title-icon {
    font-size: 16px;
    margin-right: 5px;
  }

  &__content {
    color: $warm-gray;
    font-size: 14px;
    line-height: 19px;
    padding: 15px;
    font-weight: $font-weight-regular;

    p:last-child {
      margin-bottom: 0;
    }
  }

  &--danger {
    border-color: $tomato-red;

    .system-message__title {
      color: $tomato-red;
      background-color: $error-bg;
    }
  }

  &--info {
    border-color: $lightblue-two;

    .system-message__title {
      color: $lightblue-two;
      background-color: $info-bg;
    }
  }

  &--success {
    border-color: $medium-green;

    .system-message__title {
      color: $medium-green;
      background-color: $success-bg;
    }
  }

  &--warning {
    border-color: $pending;

    .system-message__title {
      color: $pending;
      background-color: $warning-bg;
    }
  }

  &--large-icon {
    .system-message__title-icon {
      font-size: 22px;
      margin: 0 20px 0 3px;
    }
  }

  &__close-button {
    display: inline-flex;
    margin-left: auto;
    font-size: 16px;
    color: $warm-gray !important;
    margin-right: 4px;

    &:hover {
      color: $gray !important;
    }
  }
}
