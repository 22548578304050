.project-opportunities-card {
  &__project-title {
    margin: 0;
    display: inline-block;
    font-weight: 400;

    a {
      line-height: 22px;
      font-size: 18px;
      color: #303030;
    }
  }

  aside {
    font-size: 13px;
  }

  h4 {
    font-weight: $font-weight-semibold;
    font-size: 14px;
  }

  &__brief {
    color: $greyish-brown;
    line-height: 1.2;
    font-size: 14px;
  }

  &__applicants {
    font-size: 14px;
  }

  &__status-bar {
    font-size: 13px;
    bottom: 0;
    left: 0;
    border-bottom: 1px solid $warm-gray;
    z-index: 1;
    position: absolute;
    width: 100%;

    .status-dot {
      background-color: $warm-gray;
      border-color: $warm-gray;
    }

    > span {
      position: absolute;
      right: 0;
      pointer-events: none;
      background-color: $white;
      padding: 0 15px 0 10px;
      top: -9px;
      color: $warm-gray;
    }

    &--accepted {
      border-bottom: 1px solid $medium-green;

      .status-dot {
        background-color: $medium-green;
        border-color: $medium-green;
      }

      > span {
        color: $medium-green;
      }
    }

    &--pending {
      border-bottom: 1px solid $pending;

      .status-dot {
        background-color: $pending;
        border-color: $pending;
      }

      > span {
        color: $pending;
      }
    }

    &--rejected {
      border-bottom: 1px solid $tomato-red;

      .status-dot {
        background-color: $tomato-red;
        border-color: $tomato-red;
      }

      > span {
        color: $tomato-red;
      }
    }
  }

  .provider-name {
    color: $black;

    .new {
      background-color: $tomato-red;
      border-radius: 20px;
      color: $white;
      font-size: 12px;
    }
  }

  a:hover {
    text-decoration: none;
  }

  @media (max-width: map-get($grid-breakpoints, md)) {
    .status, .score-amount-view {
      margin-left: 80px;
    }
  }
}

.project-opportunities-card, .project-invitees-card {
  .rate {
    background-color: $pale-gray;
    border-radius: 20px;
    color: $black;
  }
}

.project-application-form,
.project-opportunities-card,
.page--project-application {
  .card-header aside {
    padding-top: 5px;
    font-size: 13px;
    line-height: 1.2;
  }

  .user-item__details {
    font-size: 14px;
  }

  .user-item__basic-info {
    font-size: 14px;
    line-height: 1.2;
    font-weight: $font-weight-bold;

    a {
      color: $greyish-brown;
      font-size: 14px;
    }
  }

  .new {
    background: $tomato-red;
    border-radius: 18px;
    color: $white;
    font-size: 12px;
  }

  .area-collapsible__toggle {
    color: $td-blue;
  }

  .rate-guide-column {
    border-left: 1px solid $graphite-light;

    @media (max-width: map-get($grid-breakpoints, md)) {
      border: 0;
    }
  }
}

.page--project-application {
  .border-bottom {
    border-bottom: 1px solid $pale-gray;
  }
}

.project-invitees-card {
  .name {
    color: $black;
  }

  .members-count i {
    color: $tomato-red;
  }

  .avatar {
    width: 60px;
  }

  @media (max-width: map-get($grid-breakpoints, md)) {
    .status-container, .extra-container {
      padding-left: 80px;
    }
  }
}
