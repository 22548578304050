.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__container--open{
  & > input {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.react-autosuggest__suggestions-container {
  display: none;
  position: absolute;
  width: 100%;
  border: 1px solid $graphite-light;
  border-top: 0;
  background-color: $white;
  font-weight: $font-weight-semibold;
  font-size: 14px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-container--open {
  display: block;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: $pale-gray-three;
}
