/*
  Copied from react-bootstrap-table
  https://github.com/AllenFang/react-bootstrap-table/blob/master/css/react-bootstrap-table.css
*/

.react-bs-table-container .react-bs-table-search-form {
  margin-bottom: 0;
}

.react-bs-table-bordered {
  border: 1px solid #ddd;
  border-radius: 5px;
}

.react-bs-table table {
  margin-bottom: 0;
  table-layout: fixed;
}

.react-bs-table table td,
.react-bs-table table th {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.react-bs-table-pagination {
  margin-top: 10px;
}

.react-bs-table-tool-bar {
  margin-bottom: 5px;
}

.react-bs-container-header,
.react-bs-container-footer {
  overflow: hidden;
  width: 100%;
}

.react-bs-container-body {
  /*height: 154px;*/
  overflow: auto;
  width: 100%;
}

.react-bootstrap-table-page-btns-ul {
  float: right;
  /* override the margin-top defined in .pagination class in bootstrap. */
  margin-top: 0;
}

.react-bs-table .table-bordered {
    border: 0;
    outline: none !important;
}

.react-bs-table .table-bordered > thead > tr > th,
.react-bs-table .table-bordered > thead > tr > td {
  border-bottom-width: 2px;
}

.react-bs-table .table-bordered > tbody > tr > td {
  outline: none !important;
}

.react-bs-table .table-bordered > tbody > tr > td.default-focus-cell {
  outline: 3px solid cornflowerblue !important;
  outline-offset: -1px;
}

.react-bs-table .table-bordered > tfoot > tr > th,
.react-bs-table .table-bordered > tfoot > tr > td {
  border-top-width: 2px;
}

.react-bs-table .table-bordered > tfoot > tr > th,
.react-bs-table .table-bordered > tfoot > tr > td {
  border-bottom-width: 0;
}

/*Esquerda*/
.react-bs-table .table-bordered > thead > tr > th:first-child,
.react-bs-table .table-bordered > tbody > tr > th:first-child,
.react-bs-table .table-bordered > tfoot > tr > th:first-child,
.react-bs-table .table-bordered > thead > tr > td:first-child,
.react-bs-table .table-bordered > tbody > tr > td:first-child,
.react-bs-table .table-bordered > tfoot > tr > td:first-child {
  border-left-width: 0;
}

/*Direita*/
.react-bs-table .table-bordered > thead > tr > th:last-child,
.react-bs-table .table-bordered > tbody > tr > th:last-child,
.react-bs-table .table-bordered > tfoot > tr > th:last-child,
.react-bs-table .table-bordered > thead > tr > td:last-child,
.react-bs-table .table-bordered > tbody > tr > td:last-child,
.react-bs-table .table-bordered > tfoot > tr > td:last-child {
  border-right-width: 0;
}

/*Topo*/
.react-bs-table .table-bordered > thead > tr:first-child > th,
.react-bs-table .table-bordered > thead > tr:first-child > td {
  border-top-width: 0;
}

/*Baixo*/
.react-bs-table .table-bordered > tfoot > tr:last-child > th,
.react-bs-table .table-bordered > tfoot > tr:last-child > td {
  border-bottom-width: 0;
}

.react-bs-table .react-bs-container-header > table > thead > tr > th,
.react-bs-table .react-bs-container-footer > table > thead > tr > th {
  vertical-align: middle;
}

.react-bs-table .react-bs-container-header > table > thead > tr > th .filter,
.react-bs-table .react-bs-container-footer > table > thead > tr > th .filter {
  font-weight: normal;
}

.react-bs-table .react-bs-container-header > table > thead > tr > th .select-filter option[value=''],
.react-bs-table .react-bs-container-header > table > thead > tr > th .select-filter.placeholder-selected,
.react-bs-table .react-bs-container-header > table > thead > tr > th .filter::-webkit-input-placeholder,
.react-bs-table .react-bs-container-header > table > thead > tr > th .number-filter-input::-webkit-input-placeholder{
  color: lightgrey;
  font-style: italic;
}

.react-bs-table .react-bs-container-footer > table > thead > tr > th .select-filter option[value=''],
.react-bs-table .react-bs-container-footer > table > thead > tr > th .select-filter.placeholder-selected,
.react-bs-table .react-bs-container-footer > table > thead > tr > th .filter::-webkit-input-placeholder,
.react-bs-table .react-bs-container-footer > table > thead > tr > th .number-filter-input::-webkit-input-placeholder{
  color: lightgrey;
  font-style: italic;
}

.react-bs-table .react-bs-container-header > table > thead > tr > th .select-filter.placeholder-selected option:not([value='']),
.react-bs-table .react-bs-container-footer > table > thead > tr > th .select-filter.placeholder-selected option:not([value='']) {
  color: initial;
  font-style: initial;
}

.react-bs-table .react-bs-container-header > table > thead > tr > th .number-filter,
.react-bs-table .react-bs-container-header > table > thead > tr > th .date-filter {
  display: flex;
}

.react-bs-table .react-bs-container-footer > table > thead > tr > th .number-filter,
.react-bs-table .react-bs-container-footer > table > thead > tr > th .date-filter {
  display: flex;
}

.react-bs-table .react-bs-container-header > table > thead > tr > th .number-filter-input,
.react-bs-table .react-bs-container-header > table > thead > tr > th .date-filter-input {
  margin-left: 5px;
  float: left;
  width: calc(100% - 67px - 5px);
}

.react-bs-table .react-bs-container-footer > table > thead > tr > th .number-filter-input,
.react-bs-table .react-bs-container-footer > table > thead > tr > th .date-filter-input {
  margin-left: 5px;
  float: left;
  width: calc(100% - 67px - 5px);
}

.react-bs-table .react-bs-container-header > table > thead > tr > th .number-filter-comparator,
.react-bs-table .react-bs-container-header > table > thead > tr > th .date-filter-comparator {
  width: 67px;
  float: left;
}

.react-bs-table .react-bs-container-footer > table > thead > tr > th .number-filter-comparator,
.react-bs-table .react-bs-container-footer > table > thead > tr > th .date-filter-comparator {
  width: 67px;
  float: left;
}

.react-bs-table .react-bs-container-header .sort-column {
  cursor: pointer;
}

/*inline editor default style*/
.react-bs-container .form-control.editor{
  /*width:100%;
  top:0;
  left:0;*/
  /*height: 100%;
  position: absolute;*/
}

.react-bs-container  .textarea-save-btn{
  position: absolute;
  z-index: 100;
  right: 0;
  top: -21px;
}

.react-bs-table-no-data {
  text-align: center;
}


.ReactModal__Overlay {
  -webkit-perspective: 600;
  perspective: 600;
  opacity: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 101;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transition: opacity 150ms ease-out;
}

.ReactModal__Content {
  -webkit-transform: scale(0.5) rotateX(-30deg);
  transform: scale(0.5) rotateX(-30deg);
}

.ReactModal__Content--after-open {
  -webkit-transform: scale(1) rotateX(0deg);
  transform: scale(1) rotateX(0deg);
  transition: all 150ms ease-in;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content--before-close {
  -webkit-transform: scale(0.5) rotateX(30deg);
  transform: scale(0.5) rotateX(30deg);
  transition: all 150ms ease-in;
}

.ReactModal__Content.modal-dialog {
  border: none;
  background-color: transparent;
}

/*error tip style*/
.animated {
  animation-fill-mode: both;
}

.animated.bounceIn,
.animated.bounceOut{
  animation-duration: .75s;
}

.animated.shake{
  animation-duration: .3s;
}

td.react-bs-table-expand-cell {
    cursor: pointer;
}

th.react-bs-table-expand-cell > div {
  cursor: pointer;
}


@keyframes shake {
  from, to {
    transform: translate3d(0, 0, 0);
  }

  10%,  50%,  90% {
    transform: translate3d(-10px, 0, 0);
  }

  30%, 70%{
    transform: translate3d(10px, 0, 0);
  }
}

.shake {
  animation-name: shake;
}

@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }

  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(.9, .9, .9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(.97, .97, .97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

.bounceIn {
  animation-name: bounceIn;
}

@keyframes bounceOut {
  20% {
    transform: scale3d(.9, .9, .9);
  }

  50%, 55% {
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }
}

.bounceOut {
  animation-name: bounceOut;
}

/// End of copied from react-bootstrap-table

.react-bs-table-expand-cell {
  opacity: 0.45;
  text-align: center;
  padding-right: 0 !important;

  &:hover {
    opacity: 0.85;
  }

  & + th,
  & + td {
    border-left: none !important;
  }

  i {
    padding: 10px 5px;
  }
}

.table-bordered,
.table-bordered>tbody>tr>td,
.table-bordered>tbody>tr>th,
.table-bordered>tfoot>tr>td,
.table-bordered>tfoot>tr>th,
.table-bordered>thead>tr>td,
.table-bordered>thead>tr>th {
  border-color: $pale-gray;
}

.react-bs-table {
  font-size: 14px;
  font-weight: $font-weight-regular;
  color: $greyish-brown;
  background-color: $white;

  .text-right {
    text-align: right !important;
  }

  .table > thead > tr > th,
  .table > thead > tr > td,
  .table > tbody > tr > th,
  .table > tbody > tr > td,
  .table > tfoot > tr > th,
  .table > tfoot > tr > td {
    padding: 15px 15px;
    text-overflow: initial;
    white-space: normal;
    overflow: visible;
    word-break: break-word;
    vertical-align: middle;
    border-bottom-width: 0;
  }

  .table-bordered {
    > thead {
      > tr:first-child > th,
      > tr:first-child > td{
        text-transform: uppercase;
        border-bottom: 1px solid $warm-gray;
      }

      > tr > th,
      > tr > td{
        color: $black;
        font-size: 12px;
        font-weight: $font-weight-semibold;
        text-transform: uppercase;
      }
    }

    > thead > tr > th,
    > tbody > tr > td {
      border-right: none;
      border-top: none;
    }
  }

  .table-striped > tbody > tr:nth-of-type(odd) {
    background-color: $pale-gray-four;;
  }
  .table-striped > tbody > tr:nth-of-type(even) {
    background-color: $white;
  }

  .table-hover {
    > tbody > tr:hover {
      background-color: $pale-gray-two;
    }
    > tbody > tr.expanded-row-parent{
      @include no-text-select;
      border-color: transparent;
      background-color: $lightblue;

      td {
        border-bottom: none;
      }
    }
    > tbody > tr.expanded-row {
      @include no-text-select;
      background-color: $lightblue;

      td {
        border-top: none;
      }

      p {
        color: $gray;
        font-weight: $font-weight-regular;
        margin: 0;
      }
    }
  }

  tr.clickable {
    cursor: pointer;
  }

  .table-row-description{
    color: $warm-gray;
    font-weight: $font-weight-semibold;

    strong {
      color: $black;
      font-weight: $font-weight-semibold;
    }
  }

  tr td:last-child .td-drop-button__toggle--active > button {
    background-color: $graphite-dark;
    border-color: $graphite-dark;
    color: #fff;

    .fa-chevron-down:before {
      content: quote($fa-var-chevron-up),
    }
  }
}

.react-bs-table-container {
  overflow: auto;
}

.finance-report__table-wrapper {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  margin-bottom: 25px;
}

.modal-hover-effect {
  i {
    display: none;
  }
}

@media (min-width: map-get($grid-breakpoints, md)) {
  .finance-report__table-wrapper {
    .modal-hover-effect {
      position: relative;

      &__text {
        margin-left: 0;
        transition: margin-left 0.15s;
        z-index: 2;
      }

      i {
        font-size: 12px;
        color: $greyish-brown;
        display: inline-block;
        visibility: hidden;
        position: absolute;
        top: 4px;
        left: 20px;
        transition: left .15s;
      }
    }

    .clickable:hover {
      .modal-hover-effect {
        i {
          visibility: visible;
          left: 0;
        }

        &__text {
          margin-left: 14px;
          background-color: #f7f8f9;
        }
      }
    }
  }
}

@media (max-width: map-get($grid-breakpoints, xl)) {
  .finance-report__summary {
    overflow: visible !important;
    min-width: auto !important;
    margin-top: 15px;
    border: 1px solid $pale-gray;
    box-shadow: 0 1px 5px 0 rgba(0,0,0,.15);
  }

  .react-bs-table--responsive.react-bs-table--normal {
    overflow: visible !important;
    min-width: auto !important;

    colgroup {
      display: none;
    }

    .status {
      font-size: 14px;
    }

    tr:not(.expanded-row) {
      td:first-child:not(.react-bs-table-expand-cell):not(.react-bs-table-no-data),
      .react-bs-table-expand-cell + td {
        border-bottom: 1px solid $athens-gray !important;
      }
    }

    .react-bs-container-body,
    .td-table {
      overflow: visible;
    }

    .react-bs-table {
      background-color: transparent;
      border: none;
    }

    .finance-report__table-wrapper {
      border: none;
    }

    tbody {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }

    thead {
      display: none;
    }

    table {
      display: flex;
    }

    tr {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      flex-basis: 100%;
      background-color: $white;
      box-shadow: 0 1px 5px 0 rgba(0,0,0,.15);
      border-radius: $borderRadius;
      padding-bottom: 8px;
      position: relative;
      z-index: 2;
      overflow: hidden;

      &:hover,
      &.expanded-row-parent,
      &.expanded-row-parent:hover {
        background-color: $white !important;
      }

      &.expanded-row-parent {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      &.expanded-row {
        z-index: 2;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        padding-top: 5px;
        padding-bottom: 5px;
        margin: -2px 0 0;
        box-shadow: 0 3px 7px -3px rgba(0,0,0,.35);
        background: $lightblue !important;

        td {
          padding: 15px 15px 3px !important;
          flex-basis: 100%;
          color: $greyish-brown;
          line-height: 1.2;
          font-size: 14px;
        }
      }

      + tr {
        margin-top: 15px;
      }
    }

    .expanded-row-parent {
      .react-bs-table-expand-cell > span {
        transform: rotate(0deg);
      }
    }

    table tbody {
      .expanded-row > td {
        border-bottom: none !important;
      }

      td {
        display: flex;
        flex-basis: 50%;
        flex-wrap: wrap;
        align-items: center;
        border: none;
        padding: 10px 10px 2px !important;
        align-content: flex-start;


        &:first-child:not(.react-bs-table-expand-cell):not(.show-label),
        &.react-bs-table-expand-cell + td {
          flex-basis: 100%;
          padding: 8px 0 5px !important;
          margin: 0 10px;

          &:before {
            display: none;
          }
        }


        &.actions-cell {
          flex-basis: 100%;
          padding: 8px 15px 8px 5px!important;
          border-radius: 0;
          order: 4;
          position: absolute;
          top: 0;
          right: 0;
          margin: 0;

          &:before {
            display: none;
          }
        }

        .profile-pic {
          flex-basis: 26px !important;
          height: 26px !important;
          width: 26px !important;
        }

        .status--tag {
          background-color: transparent;
          padding: 0;
        }

        &:before {
          content: attr(data-label);
          display: block;
          text-align: left;
          color: #999;
          font-weight: bold;
          flex-basis: 100%;
          padding-right: 15px;
        }

        &[data-label=""] {
          flex: 100%;

          .td-drop-button__toggle {
            margin-left: auto;
          }
        }
        &[data-label=""]:before {
          display: none;
        }

        &.react-bs-table-expand-cell {
          flex-basis: 100%;
          opacity: 1;
          margin: 5px 0 -8px;
          padding: 10px 15px 10px 10px !important;
          background: $pale-gray-two;
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
          order: 99;
          justify-content: space-between;

          &:before {
            content: 'More';
            color: $gray;
            font-weight: $font-weight-semibold;
          }
        }
      }
    }

    + .react-bs-table-container {
      min-width: auto !important;
    }
  }

  .skeleton--finance-table td:before {
    display: none !important;
  }
}

@media (max-width: 1440px) {
  .finance-report__summary {
    overflow: visible !important;
    min-width: auto !important;
    margin-top: 15px;
    border: 1px solid $pale-gray;
    box-shadow: 0 1px 5px 0 rgba(0,0,0,.15);
  }

  .react-bs-table--responsive.react-bs-table--large {
    overflow: visible !important;
    min-width: auto !important;

    colgroup {
      display: none;
    }

    .status {
      font-size: 14px;
    }

    tr:not(.expanded-row) {
      td:first-child:not(.react-bs-table-expand-cell):not(.react-bs-table-no-data),
      .react-bs-table-expand-cell + td {
        border-bottom: 1px solid $athens-gray !important;
        pointer-events: none;
      }
    }

    .react-bs-container-body,
    .td-table {
      overflow: visible;
    }

    .react-bs-table {
      background-color: transparent;
      border: none;
    }

    .finance-report__table-wrapper {
      border: none;
    }

    tbody {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }

    thead {
      display: none;
    }

    table {
      display: flex;
    }

    tr {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      flex-basis: 100%;
      background-color: $white;
      box-shadow: 0 1px 5px 0 rgba(0,0,0,.15);
      border-radius: $borderRadius;
      padding-bottom: 8px;
      position: relative;
      z-index: 2;
      overflow: hidden;

      &:hover,
      &.expanded-row-parent,
      &.expanded-row-parent:hover {
        background-color: $white !important;
      }

      &.expanded-row-parent {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      &.expanded-row {
        z-index: 2;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        padding-top: 5px;
        padding-bottom: 5px;
        margin: -2px 0 0;
        box-shadow: 0 3px 7px -3px rgba(0,0,0,.35);
        background: $lightblue !important;

        td {
          padding: 15px 15px 3px !important;
          flex-basis: 100%;
          color: $greyish-brown;
          line-height: 1.2;
          font-size: 14px;
        }
      }

      + tr {
        margin-top: 15px;
      }
    }

    .expanded-row-parent {
      .react-bs-table-expand-cell > span {
        transform: rotate(0deg);
      }
    }

    table tbody {
      .expanded-row > td {
        border-bottom: none !important;
      }

      td {
        display: flex;
        flex-basis: 50%;
        flex-wrap: wrap;
        align-items: center;
        border: none;
        padding: 10px 10px 2px !important;
        align-content: flex-start;


        &:first-child:not(.react-bs-table-expand-cell):not(.show-label),
        &.react-bs-table-expand-cell + td {
          flex-basis: 100%;
          padding: 8px 0 5px !important;
          margin: 0 10px;

          &:before {
            display: none;
          }
        }


        &.actions-cell {
          flex-basis: 100%;
          padding: 8px 15px 8px 5px!important;
          border-radius: 0;
          order: 4;
          position: absolute;
          top: 0;
          right: 0;
          margin: 0;

          &:before {
            display: none;
          }
        }

        .profile-pic {
          flex-basis: 26px !important;
          height: 26px !important;
          width: 26px !important;
        }

        .status--tag {
          background-color: transparent;
          padding: 0;
        }

        &:before {
          content: attr(data-label);
          display: block;
          text-align: left;
          color: #999;
          font-weight: bold;
          flex-basis: 40%;
          padding-right: 15px;
        }

        &.react-bs-table-expand-cell {
          flex-basis: 100%;
          opacity: 1;
          margin: 5px 0 -8px;
          padding: 10px 15px 10px 10px !important;
          background: $pale-gray-two;
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
          order: 99;
          justify-content: space-between;

          &:before {
            content: 'More';
            color: $gray;
            font-weight: $font-weight-semibold;
          }
        }
      }
    }

    + .react-bs-table-container {
      min-width: auto !important;
    }
  }

  .skeleton--finance-table td:before {
    display: none !important;
  }
}

@media (max-width: map-get($grid-breakpoints, sm)) {
  .react-bs-table--responsive {
    &.react-bs-table--normal,
    &.react-bs-table--large {
      table tbody {
        td:before {
          flex-basis: 100%;
        }

        .react-bs-table-expand-cell:before {
          flex-basis: 40%;
        }
      }
    }
  }
}
