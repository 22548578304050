$accountTogglePadding: 20px 13px;

.td-dropdown {
  display: inline-flex;

  // show grey colored tip (ie: for notifications and user menu drop downs).
  &--grey-tip {
    .dropdown-menu {
      &:after {
        border-bottom-color: $pale-gray-two !important;
      }
    }
  }

  &--auto {
    min-width: auto !important;
  }

  .dropdown-toggle {
    cursor: pointer;
    display: inline-flex;
    min-width: 35px;
    justify-content: center;
    min-height: 14px;
    vertical-align: middle;

    &:before,
    &:after {
      display: none;
    }
  }

  .dropdown-item {
    white-space: normal;
  }

  &__account-menu {
    .dropdown-menu {
      width: 225px;
      left: 6px !important;
    }
  }

  &__user-meta {
    position: relative;
    padding-left: 12px;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__user-name {
    display: inline-block;
    padding-bottom: 2px;
    font-size: 13px;
    font-weight: $font-weight-semibold;
    color: $greyish-brown;
    max-width: 350px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: middle;
    line-height: 30px;
  }

  &__status,
  &__job-title {
    display: inline-block;
    max-width: 150px;
    font-size: 12px;
    font-weight: $font-weight-semibold;
    line-height: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: middle;
    color: $greyish-brown;
  }

  &__status {
    line-height: 12px;
    color: $tomato-red;
  }

  &.dropleft {
    .dropdown-menu {
      margin-top: 0;
      left: -5px !important;

      &:before,
      &:after {
        display: block;
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        bottom: -15px;
        z-index: 9999;
      }

      &:before {
        border-left: 9px solid rgba(0,0,0,.09);
        border-bottom: 8px solid transparent;
        border-top: 9px solid transparent;
        border-right: none;
        top: 10px;
        right: -10px;
      }

      &:after {
        border-left: 9px solid #fff;
        border-bottom: 8px solid transparent;
        border-top: 9px solid transparent;
        border-right: none;
        top: 10px;
        right: -9px;
      }
    }
  }

  &.show {
    .btn-default {
      background-color: $graphite-dark;
      border-color: $graphite-dark;
      color: #fff;
    }
  }
}

.dropdown-menu {
  position: absolute;
  padding: 0;
  min-width: 170px;
  margin-top: 15px;
  z-index: 1050;
  border: none;
  border-radius: $borderRadius;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);

  &[placement="bottom-end"],
  &[placement="top-end"] {
    margin-right: -10px;
  }

  &[placement="top-end"] {
    margin-top: 0;
    margin-bottom: 10px;
  }

  &:before,
  &:after {
    display: block;
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    bottom: -15px;
    z-index: 9999;
    right: 10px;
  }

  &:before {
    border-bottom: 9px solid rgba(0, 0, 0, .09);
    border-left: 8px solid transparent;
    border-right: 9px solid transparent;
    border-top: none;
    top: -10px;
    right: 9px;
  }

  &:after {
    border-bottom: 8px solid #fff;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: none;
    top: -8px;
  }

  &[placement="top-end"]:before,
  &[placement="top-end"]:after {
    top: initial;
    right: initial;
  }

  &[placement="top-end"]:before {
    border-bottom: none;
    border-left: 8px solid transparent;
    border-right: 9px solid transparent;
    border-top: 9px solid rgba(0, 0, 0, .09);
    bottom: -10px;
    right: 8px;
  }

  &[placement="top-end"]:after {
    border-bottom: none;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #fff;
    bottom: -8px;
    right: 9px;
  }



  &.dropleft {
    .dropdown-toggle {
      &:before,
      &:after {
        display: none;
      }
    }
  }

  .dropdown_info_item {
    border-top: 1px solid rgba(48,48,48,.1);
    font-size: 13px;
    font-weight: $font-weight-regular;
    display: flex;
    padding: 12px 1.5rem 12px;
    margin-bottom: 0;
  }

  a {
    // height: 50px;
    font-size: 13px;
    color: $black;
    font-weight: $font-weight-regular;
    display: flex;
    padding-top: 12px;
    padding-bottom: 12px;

    > span {
      line-height: 19px;
      align-self: center;

      .fal {
        vertical-align: middle;
      }
    }

    &:hover,
    &:active,
    &:active:hover {
      background-color: $pale-gray;
      text-decoration: none;
      color: $black;
    }
  }

  .profile-pic {
    margin-left: -5px;
  }

  .read-only {
    pointer-events: none;
    background-color: #f9fafa;

    &:active,
    &:focus,
    &:hover {
      cursor: default;
    }
  }

  &.invitation-template-select {
    max-width: 300px;

    .scrollable-list {
      max-height: 350px;
      overflow: auto;
    }
  }
}


.td-dropdown__user-meta.td-dropdown__user-meta--has-sub-title {
  .td-dropdown__user-name {
    line-height: 1;
  }
}

.td-drop-button__toggle {
  cursor: pointer;

  &.small {
    font-size: 13px;
  }

  img {
    opacity: 0.5;
  }

  &:hover {
    img {
      opacity: 0.8;
    }
  }
}
