.organization-list {
  .td-progress-bar {
    bottom: 0;
  }
}

.organization-logo {
  display: inline-block;
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  vertical-align: middle;

  &--abbreviation {
    color: $black;
    background: $pale-gray;
    text-align: center;
    font-size: 13px;
    text-transform: uppercase;
  }
}

.organization-list-item {
  &__title {
    display: inline-block;
    color: $black;
    font-size: 16px;
    font-weight: $font-weight-bolder;
    line-height: 1.4em;
    max-width: 70%;
    margin: 7px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    a {
      color: $td-blue;
      display: block;
      line-height: 1;
    }
  }

  &__logo {
    float: left;
    width: 60px;
    height: auto;
    margin-right: 15px;
  }

  &__meta {
    > span + span{
      margin-left: 20px;
    }

    i{
      color: $warm-gray;
    }
  }

  &__status {
    display: inline-block;
    font-size: 13px;
    font-weight: $font-weight-semibold;
    color: $greyish-brown;
    margin-right: 10px;
  }

  &__status-message {
    font-size: 13px;
    font-weight: $font-weight-semibold;

    i {
      font-size: 16px;
      margin-right: 2px;
    }

    &--rejected.with-reason {
      i:hover {
        cursor: pointer;
      }
    }
  }

  &__vat {
    padding-top: 9px;
  }

  &__get-started-cta {
    @media (max-width: map-get($grid-breakpoints, sm)) {
      max-width: 100px;
    }
  }

  &__default-status {
    display: inline-block;
  }

  .td-progress-bar .indicator {
    display: none;
  }
}

.vat-settings-item {
  &__preview {
    .organization-list-item__title,
    .organization-list-item__vat {
      display: inline;
      font-size: 14px;
    }
    .organization-list-item__title {
      font-weight: $font-weight-semibold;
      padding-right: 10px;
    }
    .organization-list-item__vat {
      padding-top: 0;
    }
    .organization-list-item__reg-number {
      i {
        color: $pending;
      }
    }
  }
  &:first-child {
    box-shadow: none;
    padding-top: 5px;
  }

  .organization-list-item__title {
    font-size: 16px;
    font-weight: $font-weight-regular;
    margin-top: 9px;
  }

  .invoicing-mode--via {
    color: $warm-gray;
    font-size: 13px;
    font-weight: 200;
    margin-left: 5px;
  }

  &.org-details {
    display: flex;
    align-items: start;
    padding: 10px 0;
    border-top: 1px solid $pale-gray;
    border-bottom: 1px solid $pale-gray;

    .organization-list-item__logo{
      margin-right: 10px;
    }

    .org-details-info-section {
      display: flex;
      flex-direction: column;
      margin-right: 75px;

      .organization-list-item__title {
        margin: 0;
      }
    }
  }
}

.org-invite-form .form-group.has-error .help-block {
  white-space: pre-line;
}

.create-org-form {
  .wizard-horizontal-steps {
    border-bottom: 0;

    .wizard-horizontal-step {
      &:not(.active):not(.completed) {
        color: $graphite-dark;

        .wizard-horizontal-step__num {
          background-color: transparent;
          border-color: $graphite-dark;
          color: $graphite-dark;
        }
      }

      &.active, &.completed {
        color: $athens-gray;

        .wizard-horizontal-step__num {
          border: 0;
        }
      }

      &.active .wizard-horizontal-step__num {
        background-color: $graphite-dark;
      }
    }

    .wizard-horizontal-separator {
      background-color: $graphite-dark;
    }
  }
}

.page--org-onboarding {
  padding-bottom: 80px !important;
}
