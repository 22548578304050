.project-opportunities-widget-card-item {
  border-bottom: 1px solid $pale-gray;
  padding-top: 16px;
  margin-bottom: 0;

  .action-buttons {
    button {
      transform: translate(0, 100%);
    }
  }

  .skill-list {
    .tag {
      margin-top: 1px;
    }
  }

  &:first-child {
    padding-top: 0;
    padding-bottom: 0;
  }

  &:last-child {
    border-bottom: 0;
    margin-bottom: 0;
  }
}
