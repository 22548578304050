.selectable-item {
  @extend .noselect;
  pointer-events: none;
  cursor: pointer;
  box-shadow: $shadow-sm;
  border-radius: $borderRadius;
  overflow: hidden;

  &__card-wrapper {
    max-width: calc(100% - 48px);
  }

  .card {
    box-shadow: none;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  > * {
    pointer-events: all;
  }

  .user-item__basic-info > a,
  .user-item__title {
    width: min-content;
    pointer-events: all;
  }

  + .selectable-item {
    margin-top: 1rem;
  }

  a {
    pointer-events: none;
  }

  .td-drop-button__toggle {
    display: none;
  }

  &:hover {
    box-shadow: 0 0 0 1px $warm-gray-three;
  }

  &--selected {
    box-shadow: 0 0 0 2px $td-blue !important;
  }

  .checkbox-area {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 48px;
    padding: 20px 0 0;
    margin: 0;
    text-align: center;
    background-color: $pale-gray-two;

    .custom-checkbox {
      margin-right: 0;
    }
  }
}

@media (max-width: map-get($grid-breakpoints, md)) {
  //.selectable-item {
  //  .user-item__basic-info {
  //    padding-right: 20px !important;
  //  }
  //
  //  .checkbox-area {
  //    position: absolute;
  //    right: 0;
  //    top: 0;
  //    padding: 8px 13px 10px !important;
  //    border-radius: 0 0 0 3px;
  //  }
  //}
}
