.form-group--slider{
  position: relative;
}

.range-values{
  position: absolute;
  top: 0;
  right: 5px;
}

.rc-slider{
  margin: 10px 7px 5px;
  width: auto;
}

.rc-slider-track{
  background-color: $td-blue;
}

.rc-slider-handle{
  border-color: $td-blue;

  &:active,
  &:hover{
    border-color: $td-blue;
  }
  &:active{
    box-shadow: 0 0 5px $td-blue;
  }
}