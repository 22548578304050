.integrations {
  &__integration-features {
    display: flex;
    flex-wrap: wrap;

    > li {
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 50%;

      margin-bottom: 10px;

      span {
        color: $medium-green;
      }
    }

    @media (max-width: map-get($grid-breakpoints, sm)) {
      max-height: none;
    }
  }
}

.integration-fieldname-disabled {
  border-color: $graphite-light;

  .form-control,
  .input-group-prepend .input-group-text {
    background-color: $pale-gray-two;
    border-color: $pale-gray-two;
    color: $warm-gray;
  }
}

.integration-connect-modal {
  padding: 65px 35px;

  img {
    margin-bottom: 20px;
  }

  h1 {
    width: 55%;

    @media (max-width: map-get($grid-breakpoints, sm)) {
      width: 100%;
    }
  }

  p {
    width: 85%;
    margin-bottom: 40px;

    @media (max-width: map-get($grid-breakpoints, sm)) {
      width: 100%;
    }
  }
}

.integration-card-img {
  height: 125px;
  width: 100%;

  &__container {
    background: #eee;
  }


  img {
    max-width: 85%;
    max-height: 85%;
    width: auto;
    height: auto;
  }
}

.integration-actions {
  .td-drop-button__toggle {
    .btn {
      padding-left: 9px;
      padding-right: 9px;
    }

    &--active {
      i.fa-chevron-down {
        transform: rotate(180deg);
      }
    }
  }
}

.integration-cards-container {
  & > div {
    margin-bottom: 30px;
  }

  &.available .card {
    cursor: pointer;
  }

  .card {
    height: 100%;

    &.disabled {
      cursor: not-allowed;

      .card-title, .card-text {
        color: $warm-gray;
      }
    }

    .card-body a {
      color: $black;

      &:hover {
        text-decoration: none;
      }
    }

    .card-body,
    .card-footer {
      background-color: $white;
    }

    &:hover {
      .card-body,
      .card-footer {
        background-color: $pale-gray-two;
      }
    }
  }
}

.integration {
  &__activity--configuration-pending,
  &--linking {
    display: flex;
    width: 100%;
    padding: 75px;
    flex: 1;
    justify-content: center;
    align-items: center;

    &__details {
      max-width: 45%;
      text-align: center;

      i {
        color: $pending;
      }
    }
  }
}

.integration-settings {
  &__sync-type {
    position: relative;

    // This is to overload the default 10px !important setting
    .popover.bs-popover-bottom,
    .popover.bs-popover-auto[x-placement^="bottom"] {
      top: 42px !important;
      right: 0;
      left: auto;

      .arrow {
        right: 5px;
      }
    }
  }
}

.integration-progress-bar {
  &__wrapper {
    position: relative;
    height: 4px !important;

    .td-progress-bar {
      height: 4px;
      top: 0;
      bottom: 0;
    }
  }
}

.integration__invoice-mapping-fields {
  thead {
    font-size: small;
    font-weight: bold;
    font-style: italic;
  }
}

.integration-details-header-last-sync {
 color: $warm-gray;
}
