// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

.top-header {
  display: flex;
  height: 60px;
  min-height: 60px;
  flex-basis: 60px;
  margin-bottom: 10px;
  align-items: center;
  position: relative;
}

.header-container {
  background-color: $white;
  padding: 26px 30px;

  &__title {
    color: $black;
    display: inherit;
    line-height: 1;
    font-size: 26px;
    font-weight: $font-weight-normal;

    &:before {
      background: $graphite-light;
      content: "";
      display: inline-block;
      margin: 0 17px;
      width: 1px;
    }
  }
}


.td-dropdown .dropdown-toggle .fal {
  margin-right: 0;
}

.top-header {
  height: 65px;
  margin-bottom: 0;


  .header__logo-container {
    // display: none;
    box-shadow: none;
    width: auto;

    &--mobile {
      display: flex;;
    }
  }

  .td-dropdown__account-menu {
    display: none;
  }

  .header__logo {
    max-width: 140px;
    max-height: 40px;
    height: 26px;
  }
}
