.invitation-list-item {
  .card-header {
    margin: 0 15px;
    border-bottom: none;

    @media (max-width: map-get($grid-breakpoints, sm)) {
      > :nth-child(1) {
        order: 2;
      }

      > :nth-child(2) {
        border-bottom: 1px solid $pale-gray;
        order: 1;
        margin-left: -15px;
        margin-right: -15px;
      }
    }
  }

  .card-body {
    border-top: 1px solid $pale-gray;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .card-collapse .card-body {
    padding: 0;
  }

  &__project-title a {
    line-height: 22px;
    font-size: 18px;
    color: $black;
  }

  &__project-title {
    font-size: 14px;
    line-height: 20px;
  }

  &__icon-container {
    width: 36px;
    height: 36px;
    flex-basis: 36px;
    min-width: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    align-self: start;
    justify-content: center;
    color: #fff;
  }

  &__icon-container--project {
    background-color: $tomato-red;
  }

  &__icon-container--task {
    background-color: $pending;
  }

  &__icon-container--opportunity {
    background-color: $td-blue;
  }

  &__warning {
    max-width: 350px;
    background-color: $white !important;
  }

  h3 {
    flex: 1;
    margin: 0;
    font-size: 18px;
    line-height: 22px;

    aside {
      font-size: 13px;
      line-height: 17px;
      margin-top: 5px;
      color: $warm-gray;
    }
  }

  h4 {
    font-size: 14px;
    margin: 0 0 10px;
    font-weight: $font-weight-semibold;
  }

  * + h4 {
    margin-top: 20px;
  }

  .skill-list__trimmed-list {
    max-width: 100%;

    .area-collapsible__toggle {
      line-height: 24px;
      margin: 0 0 0 25px;
    }
  }

  .answer-value {
    .h3 {
      .markdown-content  {
        font-weight: 600;
      }
    }
    .markdown-content {
      font-size: 14px;
      line-height: 1.4em;
      font-weight: 400;
      color: $greyish-brown
    }
  }

  .warning {
    background-color: $warning-bg;
    font-size: 13px;
  }
}

.invitation-card{
  &__org-info{
    padding: 15px;
    border-bottom: 1px solid $pale-gray;
    margin-bottom: 30px;
    text-align: center;

    img{
      width: 60px;
      height: auto;
      margin-bottom: 15px;
    }

    h3{
      line-height: 1.4;
      font-size: 16px;
      font-weight: $font-weight-semibold;
      color: $black;
      margin: 0;
      padding: 0;
    }

    &--inline {
      padding-left: 0;
      padding-right: 0;
      text-align: left;
      display: flex;
      align-items: center;

      h3{
        flex: 1;
        margin-left: 10px;
      }
    }

    &--compact {
      border-bottom: none;
      padding: 0;
      margin-bottom: 15px;
    }
  }
}

.invitee-card{
  .user-item__basic-info {
    flex: 1;
  }

  .user-item__title {
    width: 100%;

    a {
      color: $td-blue;
      font-weight: $font-weight-regular;
    }
  }

  .user-item__identifier {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .user-item__name{
    line-height: 25px;
  }

  .talent-market-indicator {
    left: auto;
    right: -8px;
    top: -8px;
  }

  .invitee-card__meta{
    font-size: 14px;

    [class^="icon-"], [class*=" icon-"]{
      color: $warm-gray;
    }

    a {
      color: $greyish-brown;
    }
  }
}

.invitation-sent{
  h2{
    font-weight: 300;
    line-height: 1em;
    margin-top: 50px;
    margin-bottom: 10px;
  }

  img{
    margin-top: 70px;
  }
}