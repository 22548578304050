.page--time-tracker {
  .nav-tabs {
    box-shadow: 0 -1px 0 0 $graphite-light;
  }

  .no-results {
    padding: 8rem 0;

    i {
      --fa-primary-color: #{$graphite-dark};
      --fa-secondary-color: #{$graphite-dark};
    }
  }

  .add-work-time-block {
    display: flex;
    color: $td-blue;
    background-color: #FFFFFF;
    border-radius: $border-radius;
    border: 1px solid $graphite-light;
    height: 72px;
    align-items: center;
    justify-content: center;
    padding: 26px 32px;

    &:hover,
    &:active {
      border-color: $graphite-dark;
    }

    &:active {
      background-color: $pale-gray-eight;
    }
  }

  .time-tracker-container {
    background-color: $pale-gray;
  }
}

.stop-watch-timer {
  &__duration {
    font-size: 26px;
    margin: 0 32px;

    @media (max-width: map-get($grid-breakpoints, xl)) {
      margin-left: auto;
    }
  }

  &__toggle-button {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin: 0;
    padding: 0 0 0 3px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;

    > i {
      font-size: 22px !important;
    }
  }
}

.time-tracker-total {
  background-color: $pale-gray;
  font-weight: $font-weight-semibold;

  .label {
    margin-right: 10rem;
  }
}
