.page--documents {
  .document-form-type-option  .fa-pen-line {
    --fa-secondary-color: #{$tomato-red};
  }

  .document-item {
    i {
      --fa-primary-color: #{$black};
      --fa-primary-opacity: 1;
      --fa-secondary-opacity: 1;
    }

    .fa-file-pdf, .fa-file-signature {
      --fa-secondary-color: #{$pending};
    }
  }

  .hide-label label {
    opacity: 0;
    white-space: nowrap;
  }

  .document-form-file-uploader .fileuploader {
    background-color: $white;
  }

  .document-form-content .rich-text-area {
    height: calc(100vh - 440px) !important;
    overflow: hidden;

    > div {
      height: 100%;
    }
  }

  .document-item {
    &__title {
      color: $black;
      font-size: 22px;
    }
  }
}

.signable-iframe-container {
  min-height: 850px;
  height: 100%;
}

.document-terms-content-blocker {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(43, 48, 79, 0.8);
  z-index: 10;

  &__content {
    background-color: $pale-gray;
  }
}

.document-viewer {
  .file-preview {
    width: 100%;
    height: 65vh;
  }

  .html-content {
    figure,
    img {
      max-width: 100%;
      height: auto;
    }
  }
}

.aor-contract-confirmation-message {
  margin: auto;
  height: 100%;
  display: grid;

  > div {
    display: grid;
    text-align: center;
    margin: auto;
  }
}

.document-assignment-esign-iframe {
  height: calc(100vh - 200px);
  width: 100%;
}

.document-type-label-warning {
  margin-left: 16px;
  font-weight: 600;
  color: $medium-green;
}

.document-type-points-item {
  list-style: disc;
  margin: 6px 0 0 15px !important;
}

.create-document-form.esign-edit {
  background-color: $pale-gray-two;
  bottom: 0;
  height: 100%;
  left: 0;
  padding: 0 2rem;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 13; // On top of the main menu

  .document-form-esign-iframe {
    border: none;
    height: calc(100vh - 130px);
    width: 100%;
  }

  .wizard-buttons {
    opacity: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }
}

.invite-esign-document-title, .page--documents .document-form-type-option {
  i {
    --fa-primary-color: #{$black};
    --fa-primary-opacity: 1;
    --fa-secondary-opacity: 1;
  }

  .fa-file-pdf, .fa-file-signature {
    --fa-secondary-color: #{$pending};
  }
}

.document-esign-external-container {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.document-esign-external-iframe {
  border: none;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.invite-people-inviting-manager-esign .area-collapsible {
  &__header > div {
    max-width: 100%;
  }

  iframe {
    border: 0;
    height: 50vh;
    width: 100%;
  }
}
