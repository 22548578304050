.db-cta-row-dropdown {
  margin-top: 20px;

  &.dropdown-menu {
    z-index: 9993; // 1 more than .modal
  }
}

.db-form-toggle-input {
  width: 100%;
  background-color: $pale-gray-two;
}

.db-attachments-toggle {
  font-size: 14px;
  font-weight: normal;
  line-height: 38px;

  &:hover {
    color: $black !important;
  }

  .fal {
    margin-right: 5px;
    color: $td-blue;
    font-size: 12px;
  }
}

.discussion-board {
  display: flex;

  &__header {
    font-size: 18px;
    padding: 15px 0;
    margin: 0 15px;
    border-bottom: 1px solid $graphite-light;

    .discreet {
      font-size: 14px;
      color: $greyish-brown;
    }

    &__progress-bar {
      max-width: 100px;
    }

    &__progress-label {
      font-size: 12px;
    }
  }

  &__participants-list {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex: 76px 0 1;
    padding: 15px;
    background-color: $pale-gray-two;
    transition: flex-basis 1s;
    overflow-y: auto;
    overflow-x: hidden;

    .text-truncate {
      display: inline-block;
      width: 135px;
    }

    .hide-on-collapsed {
      line-height: 1;
      display: none;
      font-size: 13px;

      strong {
        font-weight: 600;
        font-size: 14px;
      }
    }

    li {
      & + li {
        margin-top: 15px;
      }
    }

    a {
      cursor: pointer;

      &:hover {
        text-decoration: none;

        .profile-pic  {
          opacity: 1;
        }
      }
    }

    &--expanded {
      flex-basis: 210px;
      overflow-x: hidden;

      .hide-on-collapsed {
        display: block;
      }
    }
  }

  &__chat-container {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__close-chat {
    height: auto;

    span {
      font-size: 20px !important;
    }
  }

  &__icon {
    color: #8E8F91;
    font-size: 16px;
    width: 36px;
    height: 36px;
    background-color: $graphite-light;
    border-radius: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__participants-list-item {
    opacity: 0.6;
    position: relative;

    a {
      display: flex;
      justify-content: center;
      align-items: center;

      span,
      strong {
        color: $warm-gray;
      }

      &:hover,
      &:active {
        strong {
          color: $black;
        }
      }

      &:hover {
        .discussion-board__icon {
          color: $td-blue;
        }
      }

      &:active {
        .discussion-board__icon {
          color: $td-blue;
          background-color: $pale-gray-three;
        }
      }

      .user-type-indicator {
        z-index: 2;
      }
    }

    &--active {
      opacity: 1;

      .discussion-board__icon {
        color: $td-blue;
        border: 1px solid $td-blue;
      }

      a strong {
        color: $black;
      }
    }
  }

  &__participants-list-toggle {
    text-align: center;
    margin-top: auto !important;

    a {
      width: auto;
      height: 26px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      font-size: 16px;
      padding: 0 5px;

      &:hover,
      &:active {
        background-color: $graphite-light;

        span {
          color: $td-blue;
        }
      }
    }
  }

  &__deliverables-list-label {
    font-size: 12px;
    line-height: 20px;
    font-weight: $font-weight-semibold;
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-basis: 100%;
    flex-grow: 0;
    overflow-y: auto;
    padding: 15px;
    height: 100%;
  }

  &__messages {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0%;
    margin-bottom: 15px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }

  &__messages-wrapper {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 0;
    overflow-y: auto;
    max-height: 100%;
  }

  &__row{
    + .discussion-board__row {
      margin-top: 15px;
    }
  }

  &__timestamp {
    font-size: 12px;
    white-space: pre;
    font-weight: $font-weight-regular;
    color: $warm-gray;
    margin: 0 5px;
  }

  &__form {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    margin-top: auto;
    background-color: $pale-gray-two;
    padding: 15px;
    border-radius: $borderRadius;

    .fileuploader,
    .fileuploader-item {
      background-color: $white;
    }

    textarea {
      height: 80px;
    }
  }
}

.db-assign-task-form-wrapper {
  border-radius: $borderRadius;
  background-color: $lightblue;
  padding: 15px;
  width: 100%;
  text-align: left;

  &--full-width {
    width: 100%;
    flex-basis: 100%;

    .system-message {
      flex: 1;
    }
  }
}

.db-cta-row {
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .td-drop-button__toggle img {
    opacity: 1;
  }

  &:before {
    content: '';
    z-index: 0;
    top: 50%;
    left: 0;
    height: 1px;
    width: 100%;
    background-color: $graphite-light;
    position: absolute;
  }

  &__buttons-wrapper {
    display: flex;
    position: relative;
    z-index: 1;
    background-color: $white;
  }

  .btn + .td-drop-button__toggle {
    margin-left: 15px;
  }
}

.db-system-message {
  position: relative;
  p {
    font-weight: $font-weight-semibold;
    background-color: $white;
    position: relative;
    z-index: 1;
    display: inline-block;
    padding: 0 5px;
    margin: 0 35px;

    span, time {
      display: inline-block;
    }
  }

  &:before {
    content: '';
    z-index: 0;
    top: 50%;
    left: 0;
    height: 1px;
    width: 100%;
    background-color: $graphite-light;
    position: absolute;
  }

  .btn {
    position: relative;
  }

  &--completed {
    justify-content: space-between;
  }
}

.db-user-message {
  display: flex;

  &__meta {
    display: flex;
    flex-direction: column;
    margin: 0 10px;
    flex-basis: 80%;
    flex-grow: 0;

    > div {
      word-break: break-word;
      display: flex;
      align-items: baseline;
    }
  }

  &__name {
    color: $black-two;
    font-weight: $font-weight-semibold;
    font-size: 14px;
  }

  &__attachments {
    width: 100%;
  }

  &__text {
    flex-direction: column;
    color: $gray;
    font-size: 14px;
    line-height: 1.3;
    padding: 10px;
    margin: 5px 0 0;
    flex-shrink: 0;
    flex-grow: 0;
    background-color: $graphite-light;
    border-radius: $borderRadius;
    align-self: flex-start;

    &--with-attachments {
      width: 80%;
    }

    p:last-child {
      margin-bottom: 0;
    }

    &--success {
      background-color: $success-bg-transparent;
    }

    &--reject {
      background-color: $error-bg-transparent;
    }
  }

  &--sender {
    flex-direction: row-reverse;

    .db-user-message {
      &__meta {
        align-items: flex-end;
      }

      &__name {
        float: right;
      }

      &__text {
        align-self: flex-end;
      }
    }
  }
}

.db-review-form {
  position: relative;

  label {
    margin: 0 115px 10px 0;
    font-weight: $font-weight-semibold;
    font-size: 14px;
  }

  .form-group--rating {
    margin-bottom: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.db-review {
  display: flex;
  background-color: $pale-gray-two;
  padding: 15px;
  border-radius: $borderRadius;

  .rating {
    margin: 0;
  }

  &__meta {
    display: flex;
    flex-direction: column;
    margin: 0 0 0 10px;

    > div {
      width: 100%;
      display: flex;
      align-items: baseline;
    }
  }

  &__name {
    color: $black-two;
    font-weight: $font-weight-semibold;
    font-size: 14px;
  }

  p {
    margin: 10px 0 0;
  }

  &--reviewer {
    flex-direction: row-reverse;

    .db-review {
      .rating {
        margin: 0 5px 0 0;
        float: left;
      }

      &__meta {
        align-items: flex-end;

        > div {
          flex-direction: row-reverse;
        }
      }

      &__name {
        float: right;
        margin: 0 5px 0 0;
      }

      &__text {
        align-self: flex-end;
      }
    }
  }
}


@media (max-width: map-get($grid-breakpoints, sm)) {
  .discussion-board {
    flex-direction: column;

    &__participants-list-toggle {
      display: none;
    }

    &__content {
      height: auto;
      min-height: unset;
      max-height: unset;
      display: flex;
      flex-direction: column;
    }

    &__messages {
      overflow: visible;
      max-height: unset;
    }

    &__participants-list {
      display: block;
      align-items: flex-start;
      white-space: nowrap;
      overflow-x: auto;
      flex: none;
    }

    &__participants-list-item {
      display: inline-block;
      vertical-align: middle;

      & + .discussion-board__participants-list-item {
        margin-top: 0;
        margin-left: 15px;
      }
    }
  }

  .db-user-message {
    &__text {
      max-width: 85%;
    }

    &__meta {
      > div {
        display: block;
      }
    }
  }

  .db-cta-row {
    &__buttons-wrapper {
      text-align: center;

      .td-drop-button__toggle {
        padding: 0;
      }

      .btn,
      .td-drop-button__toggle {
        margin: 5px;
        display: inline-block;

        + .btn,
        + .td-drop-button__toggle {
          margin-left: 5px;
          margin-right: 5px;
        }
      }
    }
  }

  .discussion-board {
    &__timestamp {
      display: block;
      clear: both;
      margin: 0;
    }
  }
}
