.update-task-assignments-rates-table tbody tr {
  td.checkbox {
    background-color: $pale-gray;
  }

  &.checked {
    background-color: $pale-gray;

    td.checkbox {
      background-color: $graphite-dark;
    }
  }
}
