.off-canvas-menu-container {
  display: flex;
  overflow: visible!important;
  position: fixed;
  top: 0;
  left: -1px !important;
  max-width: 70%;
  height: 100%;
  z-index: 999;
  background-color: $pale-gray-two;
  transition: transform .25s;

  .off-canvas__close-button {
    width: 30px;
    height: 30px;
    position: absolute;
    z-index: 5;
    top: calc(50vh - 15px);
    left: calc(100% + ((100vw - 100% - 20px) / 2 ));
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: $graphite-light;
    font-size: 32px;
    opacity: 0;

    &--open {
      transition: opacity 0.25s;
      opacity: 1;
    }

    &:hover,
    &:active {
      color: $graphite-light;
    }
  }

  .off-canvas-toggle {
    color: $greyish-brown;
  }

  .navbar__submenu li a {
    padding-left: 44px;
  }

  .dropdown-menu,
  .navbar-col {
    box-shadow: none;
  }

  .dropdown {
    width: 100%;

    .dropdown-toggle {
      display: none;
    }

    .dropdown-menu {
      padding-bottom: 10px;

      a {
        padding: 10px 60px 10px 27px;
        color: $greyish-brown;
        font-weight: $font-weight-semibold;
      }

      [class*=" icon-"],
      [class^=icon-] {
        top: -1px;
      }

      .read-only a {
        padding-top: 15px;
        height: 60px;
        background-color: $pale-gray-five;

        .organization-logo {
          width: 40px !important;
          height: 40px !important;
          line-height: 40px !important;
          border-radius: 40px !important;
          top: 10px;
        }

        .td-dropdown__org-meta {
          padding-left: 45px;
        }
      }

      display: block;
      width: 100%;
      left: auto;
      right: auto;
      top: auto;
      bottom: auto;
      position: relative;
      border-radius: 0;
      box-shadow: none;
    }
  }

  @media (min-width: map-get($grid-breakpoints, lg)) {
    width: 250px !important;
  }
}

.off-canvas {
  transform: none !important;
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;

  &--on {
    position: absolute;
    max-height: 100%;
    overflow: hidden;
  }
}

.off-canvas-toggle {
  color: $black;
  padding: 10px 13px;
  font-size: 21px;
  margin: 0 5px;
  z-index: 1;

  &:hover,
  &:active,
  &:focus {
    cursor: pointer;
    text-decoration: none;
  }
}

.off-canvas-backdrop {
  display: block;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(43, 48, 79, 0.8);
  transition: opacity .35s;
  z-index: 201; // this will overlay the get started side panel

  &--on {
    pointer-events: all;
    opacity: 1;
  }
}

.mobile-account-menu {
  .dropdown-menu .fal {
    width: 16px;
    text-align: center;
    margin-right: 10px;
  }

  .dropdown-menu {
    &:before,
    &:after {
      display: none;
    }
  }
}

@media (min-width: map-get($grid-breakpoints, lg)) {
  .off-canvas-toggle{
    color: $td-blue;
    display: none;
  }
}

@media (max-width: 991px) {
  .has-off-canvas-menu  {
    &.content-col.off-canvas--on {
      max-height: 100%;
      width: 100%;
      position: absolute;
      overflow-y: hidden;
    }
  }

  .root-container > .navbar-col {
    display: none;
  }

  .navbar__menuItem--state-active {
    box-shadow: none;
  }

  .navbar-col {
    max-width: 100%;
    display: flex;
    flex: 1;

    .navbar-col__logo-link {
      display: none;
    }
  }
}
