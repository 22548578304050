.skill-select-panel {
  .kw-field {
    width: 100% !important;
    display: block!important;
    top: auto !important;
    position: relative !important;
    max-width: none;
    margin-top: 0 !important;
  }

  .kw-field-toggle {
    display:  none !important;
  }

  .heading-block {
    &:hover {
      box-shadow: 0 0 0 1px $pale-gray-three inset;
    }
  }
}

.skill-tag {
  display: inline-flex;
  align-items: center;
  margin: 0 8px 6px 0;
  border-radius: 4px;
  padding: 4px 8px;
  line-height: 14px;
  font-size: 12px;
  color: $black;
  background-color: transparent;
  border: 1px solid $lightblue;

  &--selectable {
    &:hover {
      cursor: pointer;
      background-color: rgba(21,132,198,0.05);
      box-shadow: 0 0 0 1px $td-blue inset;
      border: 1px solid rgba(21,132,198,0.2);
    }

    &:active {
      background-color: $pale-gray-two;
    }

    i {
      color: $greyish-brown;
      font-size: 12px;
    }
  }

  .badge-pill { 
    height: 16px;
    min-width: 16px;
    line-height: 17px;
  }

  &--org-specific {
    background-color: $lightblue; // rgba(21,132,198,0.1);
  }

  &--selected {
    color: #fff;
    border-color: $td-blue;
    background-color: $td-blue;

    i {
      color: #fff;
    }

    &:hover {
      border-color: $graphite-dark;
      background-color: $td-blue;
    }
  }

  .fa.fa-lock {
    color: $warm-gray;
    font-size: 10;
  }
}

.skeleton--skill-select-item {
  background-color: $pale-gray-two;
}
