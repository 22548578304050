@import "core/assets/sass/base/static-footer";

@keyframes floating-bubbles {
  0%   { transform: translate(-7px) }
  50%   { transform: translate(7px) }
  100%   { transform: translate(-7px) }
}

.static-page {

  &--new {
    padding-top: 0 !important;
  }

  padding: 0;
  font-family: omnes-pro, sans-serif;
  font-smoothing: antialiased;
  background-color: $white;
  line-height: 1.5;

  .sticky {
    max-height: 100%;
    overflow-y: auto;
  }

  &__content {
    [data-attr-section] {
      font-size: 14px;
      padding-left: 40px;
      position: relative;
      margin-bottom: 15px;
    }
    [data-attr-section]::before {
      position: absolute;
      left: 0;
      display: inline-block;
      padding: 0 10px 0 0;
      content: attr(data-attr-section)
    }
  }

  &__content p {
    font-size: 1.5rem;
  }

  &__content-menu {
    margin-top: 15px;

    > li > a {
      color: $black-two;

      &:hover {
        text-decoration: none;
      }

      &.active {
        font-weight: $font-weight-semibold;
      }
    }

    > li {
      margin-bottom: 8px;
    }

    ul li {
      padding-left: 15px;

      a {
        color: $black-two;

        &:hover {
          color: $black;
        }

        &.active {
          color: $td-blue;
        }
      }

    }
  }

  h1 {
    display: block;
    text-align: center;
    margin: 100px 0;
    height: 350px;
    background: $pale-gray-two;
    padding-top: 100px;
    color: $black;
    font-weight: 600;
    font-size: 32px;
    line-height: 37px;

    aside {
      font-weight: 400;
      font-size: 18px;
      margin-top: 20px;
      line-height: 24px;
      margin-left: 150px;
      margin-right: 150px;

      @media (max-width: map-get($grid-breakpoints, md)) {
        text-align: left;
      }
    }
  }

  .container-fluid {
    padding-left: 100px;
    padding-right: 100px;
  }

  header {
    top: 0;
    height: 100px;
    width: 100%;
    background: #fff;
    position: relative;
    z-index: 5
  }

  header > .container-fluid > .row {
    height: 100%;
  }
  header > .container {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  header .logo {
    text-align: center;
    display: inline-block;
    position: relative;
    z-index: 30;
  }
  header .logo img {
    display: inline-block;
  }

  header nav {
    float: right;
    z-index: 20;
  }

  header nav ul {
    li.request-demo {
      margin-left: 20px;
    }

    li.request-demo a {
      background-color: #1584c6;
      border: 2px solid #1584c6;
      border-radius: 18px;
      color: #ffffff;
      padding: 9px 30px;
      line-height: 13px;
      margin: 10px 0;
    }

    li.request-demo a:active,
    li.request-demo a:focus,
    li.request-demo a:hover {
      color: #ffffff;
      background-color: #1b8acc;
      border: 2px solid #1b8acc;
      box-shadow: none;
      text-decoration: none;
    }

    li.login {
      margin-left: 20px;
    }

    li.login a {
      background-color: #F9F9FA;
      border: 2px solid #F9F9FA;
      border-radius: 18px;
      padding: 9px 30px;
      line-height: 13px;
      margin: 10px 0;
    }

    li.login a:active,
    li.login a:focus,
    li.login a:hover {
      background-color: #efefef;
      border: 2px solid #efefef;
      box-shadow: none;
      color: #202020;
      text-decoration: none;
    }
  }

  .gradient-background-effect {
    background: #ffffff;
    background: linear-gradient(to bottom, #ffffff 100px, #eef0f3 50%, #ffffff 100%);
    min-height: 400px;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eceff1', endColorstr='#fff',GradientType=0 );
  }

  .btn.cta-large {
    background: #1584c6;
    border: none;
    border-radius: 28px;
    text-transform: uppercase;
    line-height: 55px;
    height: auto;
    padding: 0 30px;
    margin-top: 20px;
    font-size: 13px;
    font-weight: 500;
    color: #fff;
    letter-spacing: 0.5px;
  }

  .how-it-works-illustrations {
    h3 {
      color: $black;
      font-size: 20px;
      font-weight: 600;
      line-height: 23px;
      position: relative;
      margin-bottom: 30px;

      &:before {
        content: "";
        display: inline-block;
        border-radius: 2px;
        width: 50px;
        height: 4px;
        position: absolute;
        bottom: -15px;
        left: 0;
      }
    }

    p {
      margin: 0;
      font-size: 18px;
      line-height: 24px;
    }

    &.how-it-works-illustrations--people {
      h3:before {
        background-color: $medium-green;
      }
    }

    &.how-it-works-illustrations--projects {
      h3:before {
        background-color: $td-blue;
      }
    }

    &.how-it-works-illustrations--payments {
      h3:before {
        background-color: $tomato-red;
      }
    }

    img {
      max-width: 100%;
    }

    > div {
      margin-bottom: 150px;
    }
  }


  /* HubSpot form Styles */
  .hbspt-form .field {
    margin-bottom: 10px;
  }

  .hbspt-form .inputs-list {
    list-style: none;
    padding-left: 0;
  }

  .hbspt-form .inputs-list span {
    vertical-align: middle;
  }

  .hbspt-form .inputs-list input[type=checkbox] {
    margin: 2px 5px 4px 5px;
    vertical-align: middle;
  }

  .hbspt-form label {
    color: #333;
  }

  .hbspt-form textarea,
  .hbspt-form select,
  .hbspt-form input[type="text"],
  .hbspt-form input[type="password"],
  .hbspt-form input[type="tel"],
  .hbspt-form input[type="email"] {
    display: block;
    width: 100%;
    height: 37px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  }

  .hbspt-form textarea {
    resize: vertical;
    height: 49px;
  }

  .hbspt-form textarea:focus,
  .hbspt-form select:focus,
  .hbspt-form input[type="text"]:focus,
  .hbspt-form input[type="password"]:focus,
  .hbspt-form input[type="tel"]:focus,
  .hbspt-form input[type="email"]:focus {
    border-color: #1584c6;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
  }


  .hbspt-form input[type="submit"] {
    display: block;
    width: 100%;
    line-height: 18px;
    padding: 13px 15px;
    background-color: #1584c6;
    text-transform: uppercase;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 500;
    color: #fff;
  }

  .hbspt-form input[type="submit"]:focus,
  .hbspt-form input[type="submit"]:active:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }

  .hbspt-form .hs_error_rollup {
    text-align: center;
  }

  .hbspt-form .hs-error-msgs {
    list-style: none;
    padding: 0;
  }

  .hbspt-form .hs-error-msgs li {
    list-style: none;
    margin: 3px 0 0;
  }

  .hbspt-form .hs-error-msgs li label {
    color: #d8221f;
    margin: 0;
  }

  .hbspt-form .field,
  .hbspt-form .hs_submit {
    margin: 0 100px 15px;
  }

  .hbspt-form .hs_submit {
    margin-bottom: 35px;
  }

  .hbspt-form .hs-richtext h1,
  .hbspt-form .hs-richtext h2,
  .hbspt-form .hs-richtext h3,
  .hbspt-form .hs-richtext h4 {
    display: inline-block;
    width: 100%;
  }

  .hs-richtext > div > img {
    max-height: 90px !important;
    width: auto !important;
  }

  .hbspt-form .hs-richtext {
    background: #fff;
    padding: 25px 30px 50px;
  }

  .hbspt-form form > div > .hs-richtext {
    box-shadow: 0px -25px 0px #edf0f2 inset;
  }

  .hbspt-form .submitted-message {
    padding: 50px 50px 25px;
    text-align: center;
    background-color: #fff;
  }

  .hbspt-form .submitted-message p {
    margin-top: 15px;
    font-size: 16px;
  }


  .email-subscription-form {
    h5 {
      text-transform: none;
      margin: 0px 0 20px;
      padding: 0;
      font-size: 15px;
      color: #303030;
      font-weight: 400;
    }

    .hbspt-form  {
      .submitted-message {
        background-color: transparent;
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        padding: 15px;

        p {
          color: #1584C6;
        }
      }

      .hs-error-msgs {
        width: 100%;
        position: absolute;
        top: 40px;

        li {
          display: none;

          &:first-child {
            display: block;
          }
        }
      }

      form {
        padding: 0;
        margin: 0;
        display: flex;
        position: relative;

        .hs-form-field,
        .hs-submit {
          margin:  0;
        }

        .field > label {
          display: none;
        }

        .hs-form-field {
          width: 70%;

          input,
          .error {
            position: relative;
            background: #fff;
            border-radius: 5px 0 0 5px;
            height: 40px;
            padding: 0 15px;
            box-shadow: none;
            border: none;
          }
        }

        .hs-submit {
          width: 30%;

          input {
            background: $td-blue;
            height: 40px;
            padding: 0;
            border-radius: 0 5px 5px 0;
            box-shadow: none;
            border: none;
          }
        }

        .input {
          display: flex;
        }

        .hs_error_rollup {
          display: none;
        }
      }
    }
  }


  a.mobile-menu-toggle {
    margin: 0;
    width: 1.65em;
    position: relative;
    display: block;
    transition-duration: 0.5s;
    z-index: 30;


    &:hover {
      cursor: pointer;
      opacity: 0.6;
    }

    &.toggled,
    &:focus,
    &:active {
      opacity: 1;
    }

    &.toggled {
      ul.buns {
        li.bun {
          width: 28px;

          &:nth-child(1) {
            transform: rotate(45deg) translateY(6px) translateX(3px);
          }

          &:nth-child(2) {
            opacity: 0;
          }

          &:nth-child(3) {
            transform: rotate(-45deg) translateY(3px) translateX(-6px);
          }
        }
      }
    }

    ul.buns {
      width: 25px;
      height: 25px;
      list-style: none;
      margin: 0;
      padding: 0;
      position: relative;
      transform: translateZ(0);
      color: #000;
      opacity: 1;

      li.bun {
        width: 100%;
        height: 2px;
        background-color: #000;
        position: absolute;
        top: 5px;
        transition: all 0.7s cubic-bezier(.23,1,.32,1), opacity 0.35s cubic-bezier(.23,1,.32,1);

        &:nth-child(1) {
          transform: translateY(0) translateZ(0);
        }

        &:nth-child(2) {
          transform: translateY(7px) translateZ(0);
        }

        &:nth-child(3) {
          transform: translateY(14px) translateZ(0);
        }
      }
    }
  }




  @media (min-width: 768px) {
    header {
      transition: transform 0.3s;
      margin-bottom: -100px;
    }

    header.sticky {
      position: fixed;
      height: 60px;
      padding: 0;
      top: 0;
      width: 100%;
      z-index: 20;
      box-shadow: 0 1px 10px 0 #d7dee6;
      transform: translate(0, -90px);
      opacity: 0;
      margin-bottom: 0;

      .row > div {
        height: 60px;
      }

      > .container {
        padding-top: 10px;
        padding-bottom: 10px;
      }

      &.in {
        opacity: 1;
        transform: translate(0, -0);
      }
    }

    header nav > ul {
      display: block;
      list-style: none;
      margin: 0;
      padding: 0;

      > li {
        float: left;
        margin: 0 5px;
        position: relative;

        a {
          display: block;
          padding: 15px 20px;
          line-height: 25px;
          font-size: 13px;
          font-weight: 500;
          color: #202020;
          text-transform: uppercase;

          &:hover {
            text-decoration: none;
          }
        }

        > a {
          &:hover,
          &:focus,
          &:active {
            color: #1584c6;
            text-decoration: none;
          }
        }

        &:hover {
          ul {
            visibility: visible;
            opacity: 1;
            pointer-events: all;
          }
        }

        ul {
          visibility: hidden;
          opacity: 0;
          position: absolute;
          padding: 0;
          width: 200px;
          left: calc(50% - 100px);
          background: #fff;
          border-radius: 4px;
          box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .3);
          margin: -2px 0 0;
          z-index: 10;
          transition: opacity .35s;
          pointer-events: none;

          &:before,
          &:after {
            display: block;
            content: "";
            width: 0;
            height: 0;
            position: absolute;
            z-index: 15;
            right: 10px;
          }

          &:before {
            border-color: currentcolor transparent rgba(0, 0, 0, .09);
            border-style: none solid solid;
            border-width: medium 7px 7px 6px;
            top: -8px;
            left: calc(50% - 7px);
          }

          &:after {
            border: 6px solid transparent;
            border-top: none;
            border-bottom: 6px solid #fff;
            top: -6px;
            left: calc(50% - 7px);
          }

          li {
            display: block;
            padding: 5px;

            a {
              display: block;
              color: #202020;
              padding: 10px;
              border-radius: 3px;
              font-size: 13px;
              line-height: 15px;
              font-weight: 500;
              text-transform: uppercase;
              transition: background-color linear 0.15s;

              &.menu-link--people:hover {
                background-color: rgba(58, 188, 78, 0.05);
              }

              &.menu-link--projects:hover {
                background-color: rgba(21, 132, 198, 0.05);
              }

              &.menu-link--payments:hover {
                background-color: rgba(203, 0, 61, 0.05);
              }

              &:hover {
                color: #202020;
                background-color: rgba(0, 0, 0, 0.03);
              }
            }
          }
        }
      }
    }
  }
  @media (min-width: 992px) {
    header.sticky + .gradient-background-effect {
      margin-top: 100px;
    }
  }

  @media (max-width: 991px) {
    header nav ul li {
      a {
        padding-left: 8px;
        padding-right: 8px;
      }

      &.login {
        margin: 0 8px;
      }

      &.request-demo {
        margin-left: 8px;
      }
    }

    .container-fluid {
      padding: 0 15px;
    }

    .how-it-works-illustrations {
      padding-left: 20px;
      padding-right: 20px;

      > div {
        margin-bottom: 120px;
      }
    }
  }

  @media (max-width: 767px) {
    .sticky {
      top: auto !important;
      width: auto !important;
      position: relative !important;
      tranform: translate(0px) !important;
    }

    h1 {
      height: auto;
      padding-top: 40px;
      padding-bottom: 40px;
      margin-top: 90px;
      margin-bottom: 40px;

      aside {
        margin-left: 30px;
        margin-right: 30px;
      }
    }

    .static-page__content p {
      font-size: 1.6rem;
    }

    .how-it-works-illustrations {
      > div {
        margin-bottom: 100px;
      }

      img {
        margin-bottom: 15px;
      }

      h3:before {
        content: "";
        display: inline-block;
        border-radius: 2px;
        width: 50px;
        height: 4px;
        background-color: #eee;
        position: absolute;
        bottom: -15px;
        left: calc(50% - 25px);
      }
    }

    header {
      height: 90px;
      padding: 15px 0;
      margin-bottom: -90px;

      &.sticky {
        height: auto;
        padding: 10px 0 5px;
      }
    }

    header nav {
      float: none;
      opacity: 0;
      display: flex !important;
      visibility: hidden;
      pointer-events: none;
      position: fixed !important;
      left: 0;
      top: 0;
      padding: 100px 0;
      margin: 0 !important;
      background: #fff;
      width: 100%;
      height: 100%;
      overflow-x: auto;
      z-index: 25;
      transition: all linear 0.3s;
      justify-content: flex-start;

      &.open {
        opacity: 1;
        visibility: visible;
        pointer-events: all;

        > ul {
          transform: scale(1, 1);
          opacity: 1;
        }

        &:before {
          content: "";
          background: #fff;
          width: 100%;
          height: 80px;
          z-index: 5;
          position: fixed;
          top: 0;
        }
      }

      ul {
        display: block;
        margin: 0;
        padding: 0;

        li {
          display: block;
          padding: 0;
          margin: 0;

          a {
            display: block;
            color: #202020;
            font-size: 15px;
            padding: 5px 0;
            margin: 5px 0;
            text-transform: uppercase;

            &:active,
            &:hover {
              text-decoration: none;
              color: #1584c6;
            }
          }

          &.login {
            margin-left: 0;

            a {
              background-color: #eaeaea;
              border: 2px solid #eaeaea;
              border-radius: 18px;
              padding: 9px 30px;
              line-height: 13px;
              text-align: center;
              color: #333;
              margin: 10px 0;

              &:active,
              &:focus,
              &:hover {
                background-color: #efefef;
                border: 2px solid #efefef;
                box-shadow: none;
                color: #202020;
                text-decoration: none;
              }
            }
          }

          &.request-demo {
            margin-left: 0;
          }
        }
      }

      > ul {
        opacity: 0;
        transform: scale(0.75, 0.85);
        transition: all linear 0.25s;

        > li {
          margin-bottom: 20px !important;

          > a {
            font-weight: 600;
            margin: 0 0 -5px;
          }
        }
      }
    }
  }


  a[target="_blank"] {
    position: relative;
    &::after {
      @extend .fa;
      text-decoration: inherit;
      font-weight: $font-weight-regular !important;
      content: fa-content($fa-var-external-link-alt);
      position: absolute;
      top: -15px;
      background-color: $athens-gray;
      color: $dark-gray;
      text-align: center;
      width: 22px;
      height: 22px;
      line-height: 22px;
      font-size: 10px;
      padding: 0 3px;
      border-radius: 11px;
      text-decoration: none;
      opacity: 0;
      transition-delay: 1s;
      transition: opacity ease-in-out 0.3s ;
    }
    &:hover::after {
      opacity: 1;
    }
  }

  @media (max-width: 576px) {
    .btn.cta-large {
      line-height: 42px;
      margin-top: 15px;
    }
  }

  &--new {
    h1 {
      text-align: left;
      font-weight: $font-weight-bold;
      font-size: 50px;
      line-height: 1.2;
      margin-bottom: 40px;

      > div > span,
      > div > aside {
        max-width: 50%;

        @media (max-width: map-get($grid-breakpoints, md)) {
          width: 100%;
          position: relative;
          clear: both;
          display: block;
          max-width: none;
        }
      }

      aside {
        margin-left: 0;
        margin-right: 0;
      }

      @media (max-width: map-get($grid-breakpoints, md)) {
        text-align: center;
        background-color: transparent;

        span {
          font-size: 32px;

          &:after {
            content: "";
            border-radius: 2px;
            width: 100px;
            height: 4px;
            background-color: #ff595a;
            clear: both;
            display: block;
            margin: 20px auto;
          }
        }
      }
    }

    h2 {
      position: relative;
      column-rule: #303030;
      font-weight: $font-weight-bold;
      font-size: 32px;
      line-height: 1.2;
      margin: 150px auto 20px;
      z-index: 5;

      &:after {
        content: "";
        display: block;
        border-radius: 2px;
        width: 100px;
        height: 4px;
        background-color: #ff595a;
        margin-top: 20px;
      }

      @media (max-width: map-get($grid-breakpoints, md)) {
        text-align: center;
        font-size: 32px;
        margin-top: 50px;

        &:after {
          position: relative;
          display: block;
          margin: 20px auto;
        }
      }
    }

    h2.text-center::after {
      left: calc(50% - 50px);
    }

    .static-page__content {
      p {
        font-size: 18px;

        + p {
          margin-top: 20px;
        }
      }
    }
  }
}

.static-page {
  img {
    max-width: 100%;
  }
  .about-us-grid {
    @media (max-width: map-get($grid-breakpoints, md)) {
      margin-top: -25px;
    }

    > div > div.about-us-grid__column:nth-child(2) {
      margin-top: -20px;
    }

    > div > div.about-us-grid__column:nth-child(3) {
      margin-top: -40px;
    }

    @media (min-width: map-get($grid-breakpoints, md)) {
      > div > div.about-us-grid__column:nth-child(2) {
        margin-top: -90px;
      }

      > div > div.about-us-grid__column:nth-child(3) {
        margin-top: -190px;
      }
    }

    &__item {
      justify-content: center;
      align-items: center;
      font-size: 18px;
      line-height: 1.2;
      border-radius: 10px;

      @media (min-width: map-get($grid-breakpoints, xl)) {
        height: 280px;
      }

      strong {
        font-size: 32px;
        line-height: 1.4;
        margin-top: 10px;
        font-weight: $font-weight-bold;

        @media (max-width: map-get($grid-breakpoints, md)) {

          font-size: 24px;
        }
      }

      + .about-us-grid__item {
        margin-top: 30px;

        @media (max-width: map-get($grid-breakpoints, md)) {
          margin-top: 10px;
        }
      }

      &--businesses{
        background-color: $people-bg-color;
        padding: 5% 0;

        @media (max-width: map-get($grid-breakpoints, md)) {
          padding: 18px 15px;
        }
      }

      &--freelancers {
        background-color: $projects-bg-color;
        padding: 5% 0;

        @media (max-width: map-get($grid-breakpoints, md)) {
          padding: 18px 15px;
        }
      }

      &--payments {
        background-color: $payments-bg-color;
        padding: 5% 0;

        @media (max-width: map-get($grid-breakpoints, md)) {
          padding: 18px 15px;
        }
      }

      &--photo {
        img {
          max-width: 100%;
        }
      }
    }
  }

  .about-us-item {
    text-align: center;

    &:hover {
      text-decoration: none;

      .about-us-item__image-container--businesses {
        background-color: rgba(58,188,78,0.09);
      }
      .about-us-item__image-container--manage-project {
        background-color: rgba(21,132,198,0.09);
      }
      .about-us-item__image-container--make-payments {
        background-color: rgba(203,0,61,0.09);
      }
    }
  }

  .about-us-item__image-container {
    display: inline-flex;
    position: relative;
    text-align: center;
    width: 100px;
    height: 100px;
    border-radius: 40px;
    align-items: center;
    justify-content: center;
  }

  .about-us-item__image-container::before,
  .about-us-item__image-container::after {
    animation: floating-bubbles 3s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }
  .about-us-item__image-container::before {
    animation-delay: 2s;
    animation-duration: 3.2s;
  }


  .about-us-item__image-container--businesses {
    background-color: $people-bg-color;
  }
  .about-us-item__image-container--businesses::before {
    content: "";
    display: inline-block;
    background-color: $people-bg-color;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    position: absolute;
    left: -15px;
    top: 42px;
  }
  .about-us-item__image-container--businesses::after {
    content: "";
    display: inline-block;
    background-color: $people-bg-color;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    position: absolute;
    left: -18px;
    top: 61px;
  }

  .about-us-item__image-container--freelancers {
    background-color: $projects-bg-color;
  }
  .about-us-item__image-container--freelancers::before {
    content: "";
    display: inline-block;
    background-color: $projects-bg-color;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    position: absolute;
    right: -2px;
    bottom: -5px;
  }
  .about-us-item__image-container--freelancers::after {
    content: "";
    display: inline-block;
    background-color: $projects-bg-color;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    position: absolute;
    right: -16px;
    bottom: 10px;
  }

  .about-us-item__image-container--payments {
    background-color: $payments-bg-color;
  }
  .about-us-item__image-container--payments::before {
    content: "";
    display: inline-block;
    background-color: $payments-bg-color;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    position: absolute;
    left: -14px;
    bottom: 18px;
  }
  .about-us-item__image-container--payments::after {
    content: "";
    display: inline-block;
    background-color: $payments-bg-color;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    position: absolute;
    right: -13px;
    top: 11px;
  }

  .our-credo {
    padding: 0 20px;
    text-align: center;
    margin: 150px 0;

    @media (max-width: map-get($grid-breakpoints, md)) {
      margin: 90px 0;
    }

    .carousel-inner {
      transition: all 0.6s;
      background-image: url('https://talentdesk.io/img/background-blue-connected.png');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: auto 100%;
      padding: 30px 0;
      margin: -30px 0;
    }

    h4 {
      font-weight: $font-weight-bold;
      font-size: 20px;
      line-height: 1.3;
      color: $black;
    }

    p {
      color: $black;
      padding: 30px 15px;
      font-weight: $font-weight-bold;
      font-size: 32px;
      line-height: 1.3;
      display: block;
      max-width: 730px;
      margin: 0 auto;
      height: 100%;
    }

    .carousel-control-prev,
    .carousel-control-next {
      color: $gray;
      display: inline-block;
      width: auto;
      height: auto;
      bottom: 0;
      top: unset;
      position: relative;
      padding: 0 10px;
      font-size: 35px;
      margin: 0 0px;

      &:hover {
        color: $black;
      }
    }
  }

  .get-to-know-us {
    &__item {
      margin-top: 120px;

      @media (max-width: map-get($grid-breakpoints, md)) {
        margin-top: 80px;
      }

      h4 {
        font-weight: $font-weight-bold;
        font-size: 20px;
        line-height: 1.5;
        color: $black;
        padding: 0;
        margin: 0 0 20px;

        @media (max-width: map-get($grid-breakpoints, md)) {
          text-align: center;
        }
      }

      p {
        font-size: 35px;
        line-height: 1.25;
        font-weight: $font-weight-light;
        color: $black;

        @media (max-width: map-get($grid-breakpoints, md)) {
          font-size: 24px;
        }
      }
    }

    &__icon {
      text-align: center;

      img {
        max-width: 350px;
      }
    }

    &__content {
    }
  }

  .our-team {
    &__member {
      padding: 0 50px;
      margin-top: 80px;

      @media (max-width: map-get($grid-breakpoints, md)) {
        margin-top: 40px;
      }

      &--image {
        text-align: center;

        img {
          width: 250px;
          margin-bottom: 20px;
        }
      }

      &--name {
        text-align: center;
        color: $black;
        font-size: 20px;
        line-height: 25px;
        font-weight: $font-weight-bold;
      }

      &--position {
        text-align: center;
        font-size: 18px;
        line-height: 1.5;
        font-weight: $font-weight-regular;
        color: $black;
      }

      &--moto {
        text-align: center;
        font-size: 16px;
        line-height: 1.2;
        color: $black;
        font-weight: $font-weight-light;
      }

      p {
        font-size: 18px;
        line-height: 1.5;
        font-weight: $font-weight-light;
        color: $black;
        margin-top: 20px;
      }
    }
  }
}





