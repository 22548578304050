.user-item {
  color: $greyish-brown;
  position: relative;

  &.card {
    .card-footer {
      padding: 10px 15px;
      border-top: 1px solid $graphite-light;

      &.warning {
        background-color: $warning-bg;
      }
    }
  }

  .employment-status-indicator {
    margin-left: 10px;
  }

  .profile-pic {
    flex-basis: 36px;
  }

  .discreet {
    font-size: 13px;
  }

  .card-body {
    background-color: transparent !important;

    i {
      color: $warm-gray-two;
    }
  }

  &.card.card-default:hover {
    box-shadow: 0 2px 7px 0 rgba(0,0,0,.15);
  }

  .pph-badge {
    position: absolute;
    top: 19px;
    left: 19px;
    font-size: 14px;
    border-radius: 20px;
    border: 2px solid #fff;
    display: block;
    height: 20px;
    width: 20px;
    background-size: 100%;
  }

  &__content {

    > div {
      min-width: 0; /* Fixes content truncating issue. */
    }
  }

  &__basic-info {
    color: $black;
    line-height: 14px;
    position: relative;

    > span,
    > a {
      position: relative;
      color: $warm-gray;
    }

    .status {
      font-size: 13px;
    }
  }

  &__title,
  &__title a {
    color: $greyish-brown !important;
    display: inline-block;
    font-size: 14px;
    line-height: 1.2;
    font-weight: 600;

    .status-dot {
      vertical-align: unset;
    }

    &a:hover,
    a:hover {
      text-decoration: underline;
    }
  }

  &__extra {
    color: $warm-gray;
    font-size: 14px;
    font-weight: $font-weight-regular;

    .truncate {
      max-width: 180px;
      display: inline-block;
    }

    span {
      vertical-align: middle;
      margin-right: 20px;
      height: 16px;
    }

    span.truncate {
      max-width: 180px;
      display: block;
      float: left;
    }

    i {
      font-size: 12px;
      margin-right: 5px;
    }
  }

  &__details {
    > * + * {
      margin-left: 40px;
    }
  }

  &__meta {
    font-size: 13px;

    > span + span {
      margin-left: 15px;
    }
  }

  &__rate {
    .status--tag {
      text-transform: lowercase;
      cursor: pointer;
    }
  }

  &__budget i {
    margin-right: 5px;
  }

  &__actions {
    max-width: 30px;
  }

  &__action-buttons {
    .btn + .btn{
      margin-left: 5px;
    }

    .btn .fal,
    .btn .fas {
      font-size: 13px;
    }
  }
}

.user-item,
.discussion-board__participants-list,
.project-worksheets-list-item,
.selected-payments-reviewers {
  .user-type-indicator {
    display: inline-block;
    position: absolute;
    width: 16px;
    height: 16px;
    left: -8px;
    top: calc(50% - 8px);
    background: $white;
    font-size: 9px;
    line-height: 12px;
    border-radius: 12px;
    padding: 2px;
    color: $pending;
    text-align: center;
  }
}


// List of managers inside the change project ownership modal
.user-list-item {
  time {
    color: $greyish-brown;
  }

  .user-item__extra {
    span.truncate {
      max-width: 100%;
    }
  }
}


@media (max-width: map-get($grid-breakpoints, md)) {
  .user-item {
    &__details {
      > * + * {
        margin-left: 10px;
      }
    }

  }
}
