/**
 * Root SASS file
 *
 * NOTICE: All known paths are declared in
 * `sass-loader::includePaths option@/webpack/webpack.config.js`
 */

@charset "UTF-8";

// 0. Configuration and helpers
@import 'abstracts/variables',
'abstracts/functions',
'abstracts/mixins';

// 1. Import default bootstrap source
@import 'vendor-extensions/bootstrap';

// 2. Vendors
@import '~react-tagsinput/react-tagsinput.css';
@import '~react-datetime/css/react-datetime.css';
@import '~rc-slider/assets/index.css';
@import '~react-image-gallery/styles/scss/image-gallery.scss';
@import '~react-phone-number-input/style.css';

// 3. Base stuff
@import
'fa-pro/fontawesome',
'base/base',
'fa-pro/light',
'fa-pro/solid',
'fa-pro/brands',
'fa-pro/duotone',
'base/forms',
'base/typography',
'base/helpers',
'base/print';

// 4. Layout-related sections
@import 'layout/base',
'layout/breadcrumbs',
'layout/content',
'layout/errorPage',
'layout/footer',
'layout/header',
'layout/navbar',
'layout/offCanvas.scss',
'layout/staticPage',
'layout/soloContainer';

// 5. Components
@import 'components/accountDropDown';
@import 'components/areaCollapsible';
@import 'components/autoSuggest';
@import 'components/badge';
@import 'components/bankAccount';
@import 'components/button';
@import 'components/buttonGroup';
@import 'components/card';
@import 'components/checkableList';
@import 'components/circleArea';
@import 'components/cookiesNotification';
@import 'components/countdownTimer';
@import 'components/countryFlags';
@import 'components/customCheckbox';
@import 'components/customRadio';
@import 'components/customSelect';
@import 'components/customSwitch';
@import 'components/dashboardBannerWidget';
@import 'components/dashboardWidget';
@import 'components/dateRangePicker';
@import 'components/discussionBoard';
@import 'components/dragHandle';
@import 'components/editManagerBudget';
@import 'components/elementWithPopOver';
@import 'components/exchangeRateInfoPopOver';
@import 'components/exportInvoicesModal';
@import 'components/filePreviewModal';
@import 'components/fileuploader';
@import 'components/fileUploaderDirectField';
@import 'components/geosuggest';
@import 'components/getPaidModal';
@import 'components/helpDropDown';
@import 'components/hubspot';
@import 'components/invoiceNumberForm';
@import 'components/invoicesBreakdown';
@import 'components/listGroup';
@import 'components/loadingComponent';
@import 'components/markdown';
@import 'components/markdownContent';
@import 'components/maskedValue';
@import 'components/membersPreview';
@import 'components/modal';
@import 'components/noteReviewCardItem';
@import 'components/opportunitiesWidget';
@import 'components/organisationSwitcher';
@import 'components/pagination';
@import 'components/passwordStrengthMeter';
@import 'components/paymentStatusWidget';
@import 'components/phoneNumberField';
@import 'components/popover';
@import 'components/profilePic';
@import 'components/projectsWidget';
@import 'components/purchaseOrder';
@import 'components/rates';
@import 'components/rating';
@import 'components/reactBSTable';
@import 'components/richTextArea';
@import 'components/search';
@import 'components/select';
@import 'components/selectableItems';
@import 'components/selectableList';
@import 'components/serviceOrderCutoffReminder';
@import 'components/signupForm';
@import 'components/skeleton';
@import 'components/skillList';
@import 'components/skillsPanel';
@import 'components/slider';
@import 'components/splitButton';
@import 'components/status';
@import 'components/systemMessage';
@import 'components/tabs';
@import 'components/tag';
@import 'components/tasksWidget';
@import 'components/tdCopyToClipboard';
@import 'components/tdDropDown';
@import 'components/tdDropChevronButton';
@import 'components/tdGeoSuggest';
@import 'components/tdList';
@import 'components/tdProgressBar';
@import 'components/tdTable';
@import 'components/timeline';
@import 'components/toastr';
@import 'components/tooltip';
@import 'components/transferOwnershipModal';
@import 'components/upgradeTrialBanner.scss';
@import 'components/userListItem';
@import 'components/valueAdjustmentControl';
@import 'components/widgetEmptyPlaceholder';
@import 'components/wizard';
@import 'components/worksheetBreakdown';
@import 'components/worksheetForm';
@import 'components/worksheetListItem';
@import 'components/widgets';
@import 'organizations/assets/sass/components/_siteWideBanner';

// 7. App-specific styles
@import 'accounts/assets/sass/classificationQuestionnaire';
@import 'accounts/assets/sass/getStarted';
@import 'accounts/assets/sass/guestChat';
@import 'accounts/assets/sass/profiles';
@import 'accounts/assets/sass/publicPages';
@import 'accounts/assets/sass/signupVerifyView';
@import 'accounts/assets/sass/timeOff';
@import 'contacts/assets/sass/contacts';
@import 'documents/assets/sass/documents';
@import 'finance/assets/sass/_billing';
@import 'finance/assets/sass/_finance';
@import 'importer/assets/sass/importer';
@import 'integrations/assets/sass/_integrations';
@import 'interviews/assets/sass/interviews';
@import 'invitations/assets/sass/invitations';
@import 'notifier/assets/sass/notifications';
@import 'organizations/assets/sass/organizations';
@import 'people/assets/sass/people';
@import 'projects/assets/sass/_project-import-worksheets';
@import 'projects/assets/sass/_project-opportunities';
@import 'projects/assets/sass/_project-payments-reviewer-modal';
@import 'projects/assets/sass/_project-tasks';
@import 'projects/assets/sass/_project-worksheets';
@import 'projects/assets/sass/_project-discussions';
@import 'projects/assets/sass/_projects';
@import 'projects/assets/sass/_timeTracker';
@import 'projects/assets/sass/_worksheetItemCard';
@import 'roles/assets/sass/roles';
@import 'settings/assets/sass/settings';
@import 'analytics/assets/sass/components/_reportList';
@import 'rates/assets/sass/_rates';

// 8. Themes
@import 'themes/default';
