$activeDayColor: $graphite-dark;
$selectedDayBorderColor: $graphite-dark;
$selectedDayColor: $graphite-dark;
$hoveredDayColor: $graphite-light;
$hoveredDayTextColor: #545454;
$activeHoveredDayColor: $graphite-dark;

.PresetDateRangePicker_panel {
  padding: 0 22px 11px
}
.PresetDateRangePicker_button {
  position: relative;
  height: 100%;
  text-align: center;
  background: 0 0;
  border: 2px solid $activeDayColor;
  color: $activeDayColor;
  padding: 4px 12px;
  margin-right: 8px;
  font: inherit;
  font-weight: $font-weight-semibold;
  line-height: normal;
  overflow: visible;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer
}
.PresetDateRangePicker_button:active {
  outline: 0
}
.PresetDateRangePicker_button__selected {
  color: #fff;
  background: $activeDayColor
}
.SingleDatePicker {
  position: relative;
  display: inline-block
}
.SingleDatePicker__block {
  display: block
}
.SingleDatePicker_picker {
  z-index: 1;
  background-color: #fff;
  position: absolute
}
.SingleDatePicker_picker__rtl {
  direction: rtl
}
.SingleDatePicker_picker__directionLeft {
  left: 0
}
.SingleDatePicker_picker__directionRight {
  right: 0
}
.SingleDatePicker_picker__portal {
  background-color: rgba(0,0,0,.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%
}
.SingleDatePicker_picker__fullScreenPortal {
  background-color: #fff
}
.SingleDatePicker_closeButton {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: 2
}
.SingleDatePicker_closeButton:focus,
.SingleDatePicker_closeButton:hover {
  color: darken(#cacccd,10%);
  text-decoration: none
}
.SingleDatePicker_closeButton_svg {
  height: 15px;
  width: 15px;
  fill: #cacccd
}
.SingleDatePickerInput {
  border-radius: $borderRadius;
  overflow: hidden;
  display: inline-block;
  background-color: #fff
}
.SingleDatePickerInput__withBorder {
  border: 1px solid $silver;
}
.SingleDatePickerInput__rtl {
  direction: rtl
}
.SingleDatePickerInput__disabled {
  background-color: #f2f2f2
}
.SingleDatePickerInput__block {
  display: block
}
.SingleDatePickerInput__showClearDate {
  padding-right: 30px
}
.SingleDatePickerInput_clearDate {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  padding: 10px;
  margin: 0 10px 0 5px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%)
}
.SingleDatePickerInput_clearDate__default:focus,
.SingleDatePickerInput_clearDate__default:hover {
  background: #dbdbdb;
  border-radius: 50%
}
.SingleDatePickerInput_clearDate__small {
  padding: 6px
}
.SingleDatePickerInput_clearDate__hide {
  visibility: hidden
}
.SingleDatePickerInput_clearDate_svg {
  fill: #82888a;
  height: 12px;
  width: 15px;
  vertical-align: middle
}
.SingleDatePickerInput_clearDate_svg__small {
  height: 9px
}
.SingleDatePickerInput_calendarIcon {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  margin: 0 5px 0 10px
}
.SingleDatePickerInput_calendarIcon_svg {
  fill: #82888a;
  height: 15px;
  width: 14px;
  vertical-align: middle
}
.DateRangePicker {
  position: relative;
  display: inline-block
}
.DateRangePicker__block {
  display: block
}
.DateRangePicker_picker {
  z-index: 1;
  background-color: #fff;
  position: absolute
}
.DateRangePicker_picker__rtl {
  direction: rtl
}
.DateRangePicker_picker__directionLeft {
  left: 0
}
.DateRangePicker_picker__directionRight {
  right: 0
}
.DateRangePicker_picker__portal {
  background-color: rgba(0,0,0,.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%
}
.DateRangePicker_picker__fullScreenPortal {
  background-color: #fff
}
.DateRangePicker_closeButton {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: 2
}
.DateRangePicker_closeButton:focus,
.DateRangePicker_closeButton:hover {
  color: darken(#cacccd,10%);
  text-decoration: none
}
.DateRangePicker_closeButton_svg {
  height: 15px;
  width: 15px;
  fill: #cacccd
}
.DayPicker {
  background: #fff;
  position: relative;
  text-align: left
}
.DayPicker__horizontal {
  background: #fff
}
.DayPicker__verticalScrollable {
  height: 100%
}
.DayPicker__hidden {
  visibility: hidden
}
.DayPicker__withBorder {
  box-shadow: 0 2px 6px rgba(0,0,0,.05),0 0 0 1px rgba(0,0,0,.07);
  border-radius: 3px
}
.DayPicker_portal__horizontal {
  box-shadow: none;
  position: absolute;
  left: 50%;
  top: 50%
}
.DayPicker_portal__vertical {
  position: initial
}
.DayPicker_focusRegion {
  outline: 0
}
.DayPicker_calendarInfo__horizontal,
.DayPicker_wrapper__horizontal {
  display: inline-block;
  vertical-align: top
}
.DayPicker_weekHeaders {
  position: relative
}
.DayPicker_weekHeaders__horizontal {
  margin-left: 9px
}
.DayPicker_weekHeader {
  color: #757575;
  border-top: 1px solid $graphite-light;
  position: absolute;
  top: 50px;
  z-index: 2;
  padding: 7px 13px 0;
  text-align: left
}
.DayPicker_weekHeader__vertical {
  left: 50%
}
.DayPicker_weekHeader__verticalScrollable {
  top: 0;
  display: table-row;
  border-bottom: 1px solid #dbdbdb;
  background: #fff;
  margin-left: 0;
  left: 0;
  width: 100%;
  text-align: center
}
.DayPicker_weekHeader_ul {
  list-style: none;
  margin: 1px 0;
  padding-left: 0;
  padding-right: 0;
  font-size: 14px
}
.DayPicker_weekHeader_li {
  display: inline-block;
  text-align: center
}
.DayPicker_transitionContainer {
  position: relative;
  overflow: hidden;
  border-radius: 3px
}
.DayPicker_transitionContainer__horizontal {
  -webkit-transition: height .2s ease-in-out;
  -moz-transition: height .2s ease-in-out;
  transition: height .2s ease-in-out
}
.DayPicker_transitionContainer__vertical {
  width: 100%
}
.DayPicker_transitionContainer__verticalScrollable {
  padding-top: 20px;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow-y: scroll
}
.DayPickerKeyboardShortcuts_buttonReset {
  background: 0 0;
  border: 0;
  border-radius: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  cursor: pointer;
  font-size: 14px
}
.DayPickerKeyboardShortcuts_buttonReset:active {
  outline: 0
}
.DayPickerKeyboardShortcuts_show {
  width: 22px;
  position: absolute;
  z-index: 2
}
.DayPickerKeyboardShortcuts_show__bottomRight {
  border-top: 26px solid transparent;
  border-right: 33px solid $activeDayColor;
  bottom: 0;
  right: 0
}
.DayPickerKeyboardShortcuts_show__bottomRight:hover {
  border-right: 33px solid #008489
}
.DayPickerKeyboardShortcuts_show__topRight {
  border-bottom: 26px solid transparent;
  border-right: 33px solid $activeDayColor;
  top: 0;
  right: 0
}
.DayPickerKeyboardShortcuts_show__topRight:hover {
  border-right: 33px solid #008489
}
.DayPickerKeyboardShortcuts_show__topLeft {
  border-bottom: 26px solid transparent;
  border-left: 33px solid $activeDayColor;
  top: 0;
  left: 0
}
.DayPickerKeyboardShortcuts_show__topLeft:hover {
  border-left: 33px solid #008489
}
.DayPickerKeyboardShortcuts_showSpan {
  color: #fff;
  position: absolute
}
.DayPickerKeyboardShortcuts_showSpan__bottomRight {
  bottom: 0;
  right: -28px
}
.DayPickerKeyboardShortcuts_showSpan__topRight {
  top: 1px;
  right: -28px
}
.DayPickerKeyboardShortcuts_showSpan__topLeft {
  top: 1px;
  left: -28px
}
.DayPickerKeyboardShortcuts_panel {
  overflow: auto;
  background: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
  padding: 22px;
  margin: 33px
}
.DayPickerKeyboardShortcuts_title {
  font-size: 16px;
  font-weight: $font-weight-semibold;
  margin: 0
}
.DayPickerKeyboardShortcuts_list {
  list-style: none;
  padding: 0;
  font-size: 14px
}
.DayPickerKeyboardShortcuts_close {
  position: absolute;
  right: 22px;
  top: 22px;
  z-index: 2
}
.DayPickerKeyboardShortcuts_close:active {
  outline: 0
}
.DayPickerKeyboardShortcuts_closeSvg {
  height: 15px;
  width: 15px;
  fill: #cacccd
}
.DayPickerKeyboardShortcuts_closeSvg:focus,
.DayPickerKeyboardShortcuts_closeSvg:hover {
  fill: #82888a
}
.KeyboardShortcutRow {
  list-style: none;
  margin: 6px 0
}
.KeyboardShortcutRow__block {
  margin-bottom: 16px
}
.KeyboardShortcutRow_keyContainer {
  display: inline-block;
  white-space: nowrap;
  text-align: right;
  margin-right: 6px
}
.KeyboardShortcutRow_keyContainer__block {
  text-align: left;
  display: inline
}
.KeyboardShortcutRow_key {
  font-family: monospace;
  font-size: 12px;
  text-transform: uppercase;
  background: #f2f2f2;
  padding: 2px 6px
}
.KeyboardShortcutRow_action {
  display: inline;
  word-break: break-word;
  margin-left: 8px
}
.DayPickerNavigation_container {
  position: relative;
  z-index: 2
}
.DayPickerNavigation_container__vertical {
  background: #fff;
  box-shadow: 0 0 5px 2px rgba(0,0,0,.1);
  position: absolute;
  bottom: 0;
  left: 0;
  height: 52px;
  width: 100%
}
.DayPickerNavigation_container__verticalScrollable {
  position: relative
}
.DayPickerNavigation_button {
  cursor: pointer;
  line-height: .78;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}
.DayPickerNavigation_button__default {
  border: 1px solid #e4e7e7;
  background-color: #fff;
  color: #757575
}

.DayPickerNavigation_button__horizontal {
  border-radius: 3px;
  padding: 0;
  border: none;
  background-color: transparent;
  top: 20px;
  position: absolute
}
.DayPickerNavigation_leftButton__horizontalDefault {
  left: 20px;
  z-index: 2;
}
.DayPickerNavigation_rightButton__horizontalDefault {
  right: 20px;
  z-index: 2;
}
.DayPickerNavigation_button__vertical {
  display: inline-block;
  position: relative;
  height: 100%;
  width: 50%
}
.DayPickerNavigation_button__vertical__default {
  padding: 5px
}
.DayPickerNavigation_nextButton__vertical__default {
  border-left: 0
}
.DayPickerNavigation_nextButton__verticalScrollable {
  width: 100%
}
.DayPickerNavigation_svg__horizontal {
  height: 19px;
  width: 19px;
  fill: #82888a
}
.DayPickerNavigation_svg__vertical {
  height: 42px;
  width: 42px;
  fill: #565a5c
}
.CalendarMonthGrid {
  background: #fff;
  text-align: left;
  z-index: 0
}
.CalendarMonthGrid__animating {
  z-index: 1
}
.CalendarMonthGrid__horizontal {
  position: absolute;
  left: 9px
}
.CalendarMonthGrid__vertical {
  margin: 0 auto
}
.CalendarMonthGrid__vertical_scrollable {
  margin: 0 auto;
  overflow-y: scroll
}
.CalendarMonthGrid_month__horizontal {
  display: inline-block;
  vertical-align: top;
  min-height: 100%
}
.CalendarMonthGrid_month__hideForAnimation {
  position: absolute;
  z-index: -1;
  opacity: 0;
  pointer-events: none
}
.CalendarMonthGrid_month__hidden {
  visibility: hidden
}
.CalendarMonth {
  background: #fff;
  text-align: center;
  padding: 0 13px;
  vertical-align: top;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}
.CalendarMonth_table {
  border-collapse: collapse;
  border-spacing: 0
}
.CalendarMonth_caption {
  color: $heading-color;
  font-size: 13px;
  line-height: 13px;
  text-transform: uppercase;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 50px;
  caption-side: initial;

  strong{
    font-weight: $font-weight-semibold;
    color: $heading-color;
  }
}
.CalendarMonth_caption__verticalScrollable {
  padding-top: 12px;
  padding-bottom: 7px
}
.CalendarDay {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  text-align: center
}
.CalendarDay:active {
  outline: 0
}
.CalendarDay__defaultCursor {
  cursor: default
}
.CalendarDay__default {
  color: #565a5c;
  background: #fff
}
.CalendarDay__default:hover {
  background: #e4e7e7;
  color: inherit
}
.CalendarDay__hovered_offset {
  background: #f4f5f5;
  color: inherit
}
.CalendarDay__outside {
  border: 0;
  background: #fff;
  color: #565a5c
}
.CalendarDay__blocked_minimum_nights {
  background: #fff;
  color: #cacccd
}
.CalendarDay__blocked_minimum_nights:active,
.CalendarDay__blocked_minimum_nights:hover {
  background: #fff;
  color: #cacccd
}
.CalendarDay__highlighted_calendar {
  background: $medium-green;
  color: #fff
}
.CalendarDay__highlighted_calendar:active,
.CalendarDay__highlighted_calendar:hover {
  background: $medium-green;
  color: #fff
}

.CalendarDay__selected_span {
  background: $selectedDayColor;
  color: #fff
}
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: $selectedDayBorderColor;
  color: #fff
}
.CalendarDay__last_in_range {
}
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: $activeDayColor;
  color: #fff
}
.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background: $hoveredDayColor;
  color: $hoveredDayTextColor;
}
.CalendarDay__hovered_span:active {
  background: $activeHoveredDayColor;
  color: $hoveredDayTextColor;
}
.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
  background: #cacccd;
  color: #82888a
}
.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
  background: #fff;
  color: #cacccd
}
.DateRangePickerInput {
  background-color: #fff;
  display: inline-block;
  border-radius: $form-control-radius;
  overflow: hidden;

  &:hover{
    border-color: $graphite-dark;
  }
}
.DateRangePickerInput__disabled {
  background: #f2f2f2
}
.DateRangePickerInput__withBorder {
  border: 1px solid $silver;
}
.DateRangePickerInput__rtl {
  direction: rtl
}
.DateRangePickerInput__block {
  display: block
}
.DateRangePickerInput__showClearDates {
  padding-right: 30px
}
.DateRangePickerInput_arrow {
    pointer-events: none;
    vertical-align: middle;
    display: block;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    padding-top: 6px;
}
.DateRangePickerInput_arrow_svg {
  vertical-align: middle;
  fill: #565a5c;
  height: 24px;
  width: 24px
}
.DateRangePickerInput_arrow_svg__small {
  height: 19px;
  width: 19px
}
.DateRangePickerInput_clearDates {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  padding: 10px;
  margin: 0 10px 0 5px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%)
}
.DateRangePickerInput_clearDates__small {
  padding: 6px
}
.DateRangePickerInput_clearDates_default:focus,
.DateRangePickerInput_clearDates_default:hover {
  background: #dbdbdb;
  border-radius: 50%
}
.DateRangePickerInput_clearDates__hide {
  visibility: hidden
}
.DateRangePickerInput_clearDates_svg {
  fill: #82888a;
  height: 12px;
  width: 15px;
  vertical-align: middle
}
.DateRangePickerInput_clearDates_svg__small {
  height: 9px
}
.DateRangePickerInput_calendarIcon {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  margin: 0 5px 0 10px
}
.DateRangePickerInput_calendarIcon_svg {
  fill: #82888a;
  height: 15px;
  width: 14px;
  vertical-align: middle
}
.DateInput {
  margin: 0;
  padding: 0;
  background: #fff;
  position: relative;
  display: inline-block;
  width: 130px;
  vertical-align: middle
}
.DateInput__small {
  width: 45%;

  & + div + .DateInput__small {
    float: right;

    .DateInput_input {
      text-align: right;
    }
  }
}
.DateInput__block {
  width: 100%
}
.DateInput__disabled {
  background: #f2f2f2;
  color: #dbdbdb
}
.DateInput_input {
  font-weight: $font-weight-semibold;
  font-size: 16px;
  line-height: 24px;
  color: #565a5c;
  background-color: #fff;
  width: 100%;
  padding: 13px 12px 11px;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid transparent;
  border-left: 0
}
.DateInput_input__small {
  font-size: 14px;
  line-height: 18px;
  padding: 8px 8px 6px
}
.DateInput_input__regular {
  font-weight: auto
}
.DateInput_input__readOnly {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}
.DateInput_input__focused {
  outline: 0;
  background: #fff;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid #008489;
  border-left: 0
}
.DateInput_input__disabled {
  background: #f2f2f2;
  font-style: italic
}
.DateInput_screenReaderMessage {
  border: 0;
  clip: rect(0,0,0,0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px
}
.DateInput_fang {
  position: absolute;
  width: 20px;
  height: 10px;
  left: 22px;
  z-index: 2
}
.DateInput_fangShape {
  fill: #fff
}
.DateInput_fangStroke {
  stroke: #dbdbdb;
  fill: transparent
}

.popover.popover--date-picker {
  max-width: calc(100% - 60px);
  padding: 0;
  margin-bottom: 100px;

  .popover-body {
    font-size: 14px;
    line-height: 1.2;
  }

  .quick-selection-list {
    padding: 5px 0;

    li {
      a {
        padding: 6px 17px;
        border-radius: 4px;
        font-size: 12px;
        line-height: 20px;

        &.active,
        &:hover {
          background-color: $pale-gray;
        }
      }
    }

    @media (max-width: map-get($grid-breakpoints, md)) {
      border-top: 1px solid $pale-gray;
      padding: 10px;

      li {
        margin: 0 5px 5px 0;
        a {
          padding: 7px;
        }
      }
    }
  }
}

.form-control {
  &--date-picker {
    position: relative;

    > span {
      flex: 1 0 auto;

      &:last-child {
        text-align: right;
      }
    }

    &.active,
    &:hover {
      cursor: text;
      border: 1px solid $graphite-dark;
    }
  }

  &--start-date-focused::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1px;
    width: 50%;
    border-bottom: 2px solid $graphite-dark;
  }

  &--end-date-focused::before {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    height: 1px;
    width: 50%;
    border-bottom: 2px solid $graphite-dark;
  }
}

.calendar-wrapper {
  &--unavailable {
    .DayPicker_transitionContainer,
    .CalendarDay__default,
    .CalendarMonthGrid,
    .DayPicker,
    .CalendarMonth,
    .CalendarDay {
      background-color: $pale-gray-two;
    }

    .CalendarDay {
      cursor: default !important;

      &__highlighted_calendar {
        background-color: $error-bg-transparent !important;
        color: $tomato-red !important;
      }
    }
  }

  &--date-select {
    min-height: 335px;

    @media (max-width: map-get($grid-breakpoints, md)) {
      align-items: center;
    }
  }

  &--read-only {
    .DayPicker {
      margin: 0 auto;
    }

    .CalendarMonthGrid,
    .DayPicker,
    .CalendarMonth,
    .CalendarDay {
      background-color: $pale-gray-two;
    }

    .CalendarDay {
      cursor: default !important;

      &__highlighted_calendar {
        background-color: $graphite-light;
        color: $black;
      }
    }
  }
}

.date-range-picker {
  padding: 1rem 2rem;

  &.with-border {
    background-color: $white;
    box-shadow: $shadow-sm;
  }

  .header {
    border-bottom: 1px solid $pale-gray;
    position: relative;

    .prev-month, .next-month {
      position: absolute;
      top: 50%;
      left: 0%;
      transform: translateY(-50%);
      cursor: pointer;
    }

    .next-month {
      left: auto;
      right: 0;
    }
  }

  .month {
    width: 300px;

    .week-days .day {
      color: $warm-gray;
    }

    .week-days .day, .days .week .day {
      width: calc(100% / 7);
    }

    .days {
      .week {
        .day {
          aspect-ratio: 1/1;
          cursor: pointer;
          position: relative;

          &.disabled {
            cursor: not-allowed;
          }

          &:hover {
            background-color: $pale-gray;
          }

          span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          &.in-future {
            color: $warm-gray-two;
          }

          &.start-date, &.end-date, &.selected {
            background-color: $graphite-dark;
            color: $white;
          }

          &.hover-selected {
            background-color: $graphite-light;
          }
        }
      }
    }
  }
}

[class^=calendar-wrapper] .date-range-picker {
  background-color: $pale-gray-two;
}

.calendar-wrapper--unavailable .day {
  cursor: default !important;

  &.highlighted, &.highlighted:active, &.highlighted:hover {
    background-color: $error-bg-transparent !important;
    color: $tomato-red !important;
  }
}
