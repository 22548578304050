.custom-switch {
  padding: 0;
  color: #fff;
  display: inline-block;
  position: relative;
  width: 30px;
  height: 16px;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  border-radius: 15px;
  border: 1px solid $graphite-light;
  background-color: $graphite-light;
  vertical-align: middle;
  transition: background 0.3s;

  &:after {
    display: inline-block;
    content: "";
    background-color: $white;
    width: 12px;
    height: 12px;
    border-radius: 12px;
    position: absolute;
    left: 1px;
    right: 0;
    margin: 1px 1px 1px 0;
    transition: transform 0.3s;
  }

  &:hover{
    border-color: $warm-gray;
    cursor: pointer;
  }

  &--selected{
    border-color: $td-blue !important;
    background-color: $td-blue !important;

    &:after {
      transform: translateX(14px);
    }
  }

  &--disabled {
    opacity: 0.6;
    pointer-events: none;
  }
}

.cursor-pointer:hover .custom-switch {
  border-color: $warm-gray;
}
