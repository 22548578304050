.export-invoices-modal {
  .csv-explanation-section {
    background-color: $pale-gray-two;

    .csv-icon {
      font-size: 24px;
    }

    .area-collapsible__header {
      font-size: 18px;
      color: $black;
    }
  }
  .xero-icon,
  .quickbooks-icon {
    width: 50px;
  }

  .talentdesk-icon {
    width: 70px;
  }

  .arrow-left-icon,
  .arrow-right-icon {
    width: 80px;
  }
  .refine-filters {
    &:hover {
      cursor: pointer;
    }
  }

  @media (max-width: map-get($grid-breakpoints, md)) {
    .xero-icon,
    .quickbooks-icon {
      width: 40px;
    }

    .talentdesk-icon {
      width: 60px;
    }

    .arrow-left-icon,
    .arrow-right-icon {
      width: 70px;
    }
  }
  @media (max-width: map-get($grid-breakpoints, sm)) {
    .xero-icon,
    .quickbooks-icon {
      width: 30px;
    }

    .talentdesk-icon {
      width: 50px;
    }

    .arrow-left-icon,
    .arrow-right-icon {
      width: 60px;
    }
  }
}