.custom-radio {
  display: inline-flex;
  margin-bottom: 0;

  &__circle {
    display: inline-block;
    width: 16px;
    flex-basis: 16px;
    flex-shrink: 0;
    flex-grow: 0;
    height: 16px;
    border-radius: 8px;
    border: 1px solid $graphite-light;
    background-color: $white;
    margin-right: 5px;
    margin-top: 2px;
    vertical-align: middle;
    position: relative;
  }

  &:hover {
    cursor: pointer;

    .custom-radio__circle{
      border-color: $td-blue;
    }
  }

  &--selected .custom-radio__circle {
    border-color: $td-blue;

    &:after {
      content: "";
      width: 8px;
      height: 8px;
      border-radius: 4px;
      position: absolute;
      top: 3px;
      left: 3px;
      background-color: $td-blue;
    }
  }
}

.custom-radio {
  margin-left: 30px;
}

.custom-radio-field {
  &.has-error {
    .custom-radio__circle {
      border-color: $tomato-red;
    }
  }

  &--inline {
    display: flex;

    > div {
      margin: 0 10px 0 0 !important;
      padding: 5px;
    }
  }
}

.radio-vertical {
  position: relative;
  display: block;
  margin: 0 20px;
  vertical-align: middle;
  cursor: pointer;

  + .radio-vertical {
    margin-top: 20px;
  }

  .custom-radio {
    margin-left: 0;
  }
}


.custom-radio-field {
  & > div + div {
    margin-top: 7px;
  }

  &--disabled {
    opacity: 0.6;
    pointer-events: none;
  }
}
