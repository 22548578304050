.popover {
  padding: 15px;
  border: none;
  border-radius: $borderRadius;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
  font-size: 1.4rem;
  max-width: 300px;
  z-index: 9993; // 1 more than .modal

  .popover-body {
    padding: 0;
    min-width: 230px;
    color: $greyish-brown;
    font-size: 12px;
  }

  &.right {
    margin-left: 20px;
  }

  &.bottom {
    margin-top: 5px;
    margin-bottom: 40px;

    >.arrow{
      border-bottom-color: rgba(0, 0, 0, 0.1);
      top: -12px;
      margin-left: -6px;
      border-width: 6px;
    }
  }

  .popover-header {
    display: flex;
    border-bottom: 1px solid #edeff2;
    background: none;
    border-radius: 0;
    font-size: 14px;
    margin: 0 -15px 13px;
    padding: 0 30px 13px 15px;
    font-weight: $font-weight-semibold;
  }

  &__close-button {
    font-size: 14px;
    cursor: pointer;
    color: $warm-gray;
    position: absolute;
    right: 11px;
    top: 15px;

    &:hover {
      color: $gray;
    }
  }

  .popover-title {
    padding: 0;
    margin-bottom: 10px;
    color: $black;
    font-size: 15px;
    font-weight: $font-weight-semibold;
    background-color: transparent;
    border-bottom: none;
  }

  .popover-content {
    padding: 0;
    font-size: 14px;
    font-weight: $font-weight-regular;
    color: $text-color;
  }

  &.bs-popover-bottom {
    top: 10px!important;
  }

  &.bs-popover-right {
    left: 10px!important;
  }

  &.bs-popover-right-start {
    left: 10px !important;

    > .arrow {
      top: 20px !important;
      left: -9px;
    }

    > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(43, 48, 79, 0.25);
    }

    > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff;
    }
  }

  &--lg {
    @media (min-width: map-get($grid-breakpoints, lg)) {
      width: 770px;
      max-width: 60vw;
    }
  }
}

.bs-popover-auto[x-placement^=bottom]>.arrow:before,
.bs-popover-bottom>.arrow:before {
  border-bottom-color: rgba(0,0,0,.1);
}
