.countdown-timer {
    $m: &; // Set the parent as a variable to use on the icon states, to avoid a selector-class-pattern issue, it enables us to use it as a grandparent selector
    background: $graphite-light;
    border-radius: 50%;
    height: 96px;
    margin: 0 auto 20px;
    width: 96px;

    &.is-small {
        display: inline-block;
        height: 24px;
        margin: 0;
        width: 24px;
    }

    &__inner {
        align-items: center;
        background-color: $white;
        border-radius: 50%;
        color: $graphite-dark;
        display: flex;
        font-size: 48px;
        font-weight: 600;
        height: 80px;
        justify-content: center;
        left: 8px;
        line-height: 50px;
        position: relative;
        top: 8px;
        width: 80px;
    }

    &.is-small {
        #{$m}__inner {
            color: $warm-gray;
            font-size: 12px;
            height: 20px;
            left: 2px;
            top: 2px;
            width: 20px;
        }
    }

    &.is-active {
        #{$m}__inner {
            background-color: $graphite-dark;
            color: $white;
        }
    }

    &__circle {
        .mask,
        .fill {
            border-radius: 50%;
            height: 96px;
            position: absolute;
            width: 96px;
        }
        .mask {
            clip: rect(0, 96px, 96px, 48px);
        }
        .mask .fill {
            background-color: $graphite-dark;
            clip: rect(0, 48px, 96px, 0px);
        }

        @for $i from 1 through 180 {
            .fill--#{$i},
            .mask.full--#{$i} {
                animation: fill-#{$i} ease-in-out 3s;
                transform: rotate($i + deg);
            }
            @keyframes fill-#{$i} {
                0% {
                    transform: rotate(0deg);
                }
                100% {
                    transform: rotate($i + deg);
                }
            }   
        }
    }

    &.is-small {
        #{$m}__circle {
            height: 24px;
            width: 24px;
            
            .mask,
            .fill {
                width: 24px;
                height: 24px;
            }
            .mask {
                clip: rect(0, 24px, 24px, 12px);
            }
            .mask .fill {
                clip: rect(0, 12px, 24px, 0);
            }
        }
    }

    &.is-active {
        #{$m}__circle {
            .mask .fill {
                background-color: $white;
            }
        }
    }
}
  