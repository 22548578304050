.value-adjustment-control {
  display: flex;
  align-items: center;
  align-content: center;

  &__knob {
    padding: 0;
    width: 14px;
    height: 14px;

    &.btn {
      .fas, .fal {
        font-size: 14px;
        color: $warm-gray;
      }
    }
  }

  &__value-wrapper {
    margin: 0 10px;
  }

  &__value,
  &__suffix {
    color: $warm-gray;
    font-size: 12px;
    line-height: 1.2;
  }
}
