.copy-to-clipboard {
  display: flex;
  justify-content: center;
  background-color: $graphite-light;
  border-radius: $borderRadius;
  padding: 10px;

  &__text {
    flex: 1;
    font-family: $code-font-stack;
    font-weight: $font-weight-semibold;
    color: $greyish-brown;
    align-self: center;
  }

  &__button {
    background: $pale-gray-two;
    border-radius: $borderRadius;
    border: 1px solid $warm-gray;
    font-weight: $font-weight-regular;
    color: $warm-gray !important;
    padding: 4px 10px;
    line-height: 1;
    font-size: 12px;
    text-transform: uppercase;

    &:hover, &:focus, &:active, &.active {
      border-color: $gray !important;
      color: $gray !important;
    }
  }
}
