.site-wide-banners {
  height: auto !important;

  .site-wide-banner {
    position: relative;

    &__content {
      max-width: calc(100% - 10rem);
    }

    .markdown-content {
      overflow: hidden;

      p:last-of-type {
        margin-bottom: 0;
      }
    }

    &__close {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      cursor: pointer;
    }

    @media (max-width: map-get($grid-breakpoints, lg)) {
      &__content {
        max-width: none;
      }

      &__close {
        position: static;
        top: auto;
        right: auto;
        transform: none;
      }
    }
  }
}
