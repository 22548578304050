.modal {
  z-index: 9992; // 1 more than the backdrop

  &-dialog {
    margin-top: 50px;
  }

  &-backdrop {
    background-color: rgba(43, 48, 79, 0.8);

    &.fade { opacity: 0; }
    &.show { opacity: 1; }
  }

  &--full-width {
    .modal-dialog {
      width: 100vw;
      max-width: 90%;
    }
  }

  @media (min-width: map-get($grid-breakpoints, sm)) {
    &-xs {
      max-width: $modal-xs;
    }
  }

  &-content {
    border-radius: $borderRadius;
    background: #fff;
    border: none;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
  }

  &-header {
    background-color: $pale-gray-two;
    min-height: 50px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 4px 4px 0 0;
    align-items: center;

    .close {
      color: $warm-gray;
      content: '';
      font-family: "Font Awesome 6 Pro";
      font-weight: 300;
      font-size: 18px;
      opacity: 1;

      &:hover{
        color: $black;
      }
    }
  }

  &-title{
    font-size: 16px;
    font-weight: $font-weight-semibold;
  }

  &-body {
    color: $black;
    padding: 20px;

    h3 {
      font-size: 14px;
      text-transform: uppercase;
      color: $black;
      font-weight: $font-weight-semibold;

      .discreet {
        text-transform: none;
      }
    }

    div + div > h3 {
      margin-top: 15px;
    }

    h4 {
      font-weight: 600;
    }

  }

  &-footer {
    padding: 20px 0;
    margin: 0 20px;

    .btn {
      min-width: 80px;
    }

    .btn + .btn{
      margin-left: 10px;
    }
  }

  &-backdrop {
    z-index: 9991; // 1 more than the hubspot chat iframe
    &.in {
      opacity: 0.4;
      filter: alpha(opacity=40);
    }
  }
}

/* Modal Panel animations */
.modal.fade .modal-panel {
  transform: translateX(50px);
}
.modal.show .modal-panel {
  transform: none;
}

.modal-dialog.modal-panel {
  height: 100%;
  margin: 0 20px auto auto;
  padding: 20px 0;
  width: calc(100% - 40px);

  .modal-header {
    border: none;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;

    .modal-title {
      color: $black;
      font-size: 18px;
      line-height: 27px;
      font-weight: $font-weight-regular;
    }

    &--small {
      .modal-title {
        font-size: 18px;
        font-weight: $font-weight-semibold;

        aside {
          font-size: 14px;
          color: $gray;
          font-weight: $font-weight-regular;
        }
      }
    }
  }

  .modal-body {
    overflow-y: auto;
  }

  .modal-content {
    height: 100%;
  }

  .modal-footer {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: $pale-gray-two;
    margin-left: 0;
    margin-right: 0;
    padding-left: 20px;
    padding-right: 20px;
  }
}
