.PhoneInput {
  .PhoneInputCountry {
    background-color: $pale-gray;
    border-radius: 3px 0 0 3px;
    margin-right: 0;
    padding: 0 1.5rem;
    border: 1px solid $silver;
    border-right: none;

    .PhoneInputCountrySelectArrow {
      height: 0.75rem;
      width: 0.75rem;
      color: $greyish-brown;
      margin: -5px 0 0 12px;
      opacity: 0.8;
    }

    &:hover .PhoneInputCountrySelectArrow {
      opacity: 1;
    }
  }

  .PhoneInputInput {
    @extend .form-control;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
}
