.dashboard-widget__header.payment-status .header-right {
  font-size: 14px;

  i {
    color: $warning;
    margin-right: 1em;
  }

  .text-danger {
    margin-left: 0.5em;
    font-weight: $font-weight-semibold;

    @media (max-width: map-get($grid-breakpoints, xl)) {
      margin-top: -20px;
    }
  }
}

.dashboard-widget__body.payment-status {
  .react-bs-container-body,
  .td-table {
    max-height: 250px;
    overflow-y: auto;
  }

  .react-bs-table .table > thead > tr > th,
  .td-table thead > tr > th {
    font-size: 12px;
    padding-left: 14px;
    padding-right: 14px;
    text-align: center !important;
    text-transform: uppercase;

    &:first-of-type, &:nth-of-type(2) {
      text-align: left !important;
    }

    &:last-of-type {
      text-align: right !important;
    }
  }

  td {
    border: 0;
  }

  .dot {
    background-color: $graphite-light;
    border-radius: 8px;
    display: inline-block;
    margin: 0 4px;
    height: 16px;
    width: 16px;

    &.complete {
      background-color: $medium-green;
    }
  }

  button {
    z-index: 2;
  }

  .bar {
    background-color: $graphite-light;
    height: 4px;
    width: calc((100% - 24px) / 2);
    z-index: 1;

    &.complete {
      background-color: $medium-green;
    }

    &.transparent {
      opacity: 0;
    }
  }

  .payment-status__mobile {
    .header {
      border-bottom: 1px solid $pale-gray;

      .btn {
        font-size: 14px;
        font-weight: normal;
      }
    }

    h5 {
      color: $black;
      font-size: 12px;
    }
  
    .status-label {
      color: $black;
      font-size: 12px;
      font-weight: $font-weight-semibold;
      margin-left: 16px;
      margin-right: 16px;
      text-transform: uppercase;
    }

    .dot {
      margin: 0;
    }
  
    .bar {
      height: 30px;
      margin: 4px 6px;
      width: 4px;
    }

    .status-label, .amend-button {
      margin-bottom: -12px;
      margin-top: -12px;
    }

    .amend-button {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
}
