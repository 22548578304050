.markdown-content {
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;

  p, ul, ol {
    margin-bottom: 15px;
  }

  li p {
    margin: 0;
  }

  ol,
  ul {
    padding-left: 25px;
  }

  ul {
    list-style: disc;
    padding-left: 25px;
    white-space: normal;
  }

  h1, h2, h3, h4 {
    margin: 0 0 5px;
    padding: 0;
  }

  h1 {
    font-size: 20px;
  }

  h2 {
    font-size: 18px;
  }

  h3 {
    font-size: 16px;
  }

  h4 {
    font-size: 14px;
  }

  img {
    max-width: 45%;
  }
}
