.rate-card {
  border-radius: $borderRadius;

  .card-body {
    padding: 23px 15px;
    border-radius: $borderRadius;
  }

  &__title {
    font-weight: $font-weight-semibold;

  }

  &__amount {
    font-weight: $font-weight-semibold;
  }

  &__meta {
    color: $warm-gray;
    font-size: 13px;
  }

  .status {
    font-weight: $font-weight-regular;
    line-height: 1;
  }


  @media (min-width: map-get($grid-breakpoints, lg)) and (max-width: map-get($grid-breakpoints, xl)) {
    &__infos {
      display: flex;
      flex-direction: column;
    }

    &__title a{
      display: inline-flex;
      flex-direction: column;

      .status-tag {
        margin: 5px 0 0 0 !important;
      }
    }

    .status-pending__container, .status--posted {
      margin: 5px 0 0 0 !important;
    }
  }
}
