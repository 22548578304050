.exchange-rate-info-popover {
  line-height: 16px;
  max-width: 220px;

  .popover-body {
    min-width: 220px!important;
    max-width: 100%;
    padding: 0 15px!important;
    margin: 0 -15px;
  }

  .discreet {
    font-size: 16px;
  }

  &__toggle:not([href]) {
    color: $warm-gray;
    font-size: 16px;

    &:hover,
    &:active {
      color: black;
    }
  }
}
