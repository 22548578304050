.get-paid-modal {
  .get-paid-item__icon {
    font-size: 48px;

    .fa-duotone:before {
      color: $black;
    }

    .fa-duotone:after {
      opacity: 1;
    }

    .fa-clipboard-list-check{
      &:after {
        color: $tomato-red;
      }
    }

    .fa-file-invoice {
      &:after {
        color: $pending;
      }
    }

    .fa-money-check {
      &:after {
        color: $td-blue;
      }
    }
  }

  .checkable-list {
    &__container {
      @media (min-width: map-get($grid-breakpoints, lg)) {
        display: flex;
      }
    }

    &__indicator {
      display: none;
      position: absolute;
      right: 2em;
      top: 1em;
      padding: 0;

      @media (min-width: map-get($grid-breakpoints, lg)) {
        top: 18px;
      }
    }

    &__option--checked {
      background: none;

      > a,
      > a:hover {
        background-color: $medium-green-pastel;
        border-color: $medium-green-pastel;  
        position: relative;
      }

      .checkable-list__indicator {
        right: 1em;
        top: calc(1em - 1px);
        @media (min-width: map-get($grid-breakpoints, lg)) {
          top: 17px;
        }
      }  
    }
  }

  &__item {
    border: 0;

    > a {
      border: 1px solid $graphite-light;
      height: 100%;

      .checkable-list__indicator {
        display: block;
      }

      &:hover {
        border-color: transparentize($gray, 0.8);
  
        .checkable-list__indicator {
          display: block;
        }
      }
    }
  }

  &__footer {
    background-color: $white;
    margin-top: -21px;
    padding-top: 20px;
  }  
}

.get-paid-button-wrap {
  box-sizing: border-box;

  &__button {
    box-sizing: border-box;
    width: 100%;
  }
}
