.purchase-order-item {
  font-size: 14px;
  color: $greyish-brown;
  line-height: 30px;

  > a {
    color: $greyish-brown;

    &:hover {
      color: $black;
      text-decoration: none;
    }
  }

  &__budget {
    span {
      color: $greyish-brown;
    }
  }

  &__reason-icon {
    color: $warm-gray;
    position: relative;
    top: -1px;
    margin-left: 5px;

    &:hover,
    &:active {
      text-decoration: none;
      color: $black;
    }
  }

  &__status {
    display: inline-block;
  }

  .status {
    font-size: 13px;
    font-weight: $font-weight-semibold;
  }

  .status-dot{
    margin-left: 0;
  }
}

/*
@media (max-width: map-get($grid-breakpoints, lg)) {
  .purchase-order-item {
    line-height: 18px;
    
    .purchase-order-item__budget {
      margin-left: 15px;
    }
  }
}
*/