.loading-component{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;

  .loader {
    height: 2px;
    width: 100%;
    position: absolute;
    overflow: hidden;
  }

  .loader:before{
    display: block;
    position: absolute;
    content: "";
    left: -200px;
    width: 200px;
    height: 2px;
    background-color: $td-blue;
    animation: loading 2s linear infinite;
  }
}

@keyframes loading {
    from {left: -200px; width: 30%;}
    50% {width: 30%;}
    70% {width: 70%;}
    80% { left: 50%;}
    95% {left: 120%;}
    to {left: 100%;}
}