.breadcrumbs {
  color: $black;
  width: 100%;
  min-height: 63px;

  .td-drop-button__toggle img {
    opacity: .55;
  }

  &__heading {
    max-width: 100%;
    width: 100%;
  }

  &__list {
    display: flex;
    flex-wrap: nowrap;
    align-items: start;
    min-height: 40px;

    .breadcrumbs__title,
    li > span,
    li > a {
      font-size: 24px;
      line-height: 30px;
      font-weight: $font-weight-regular;
    }

    li:last-child > * {
      text-transform: none;
    }

    @media (max-width: map-get($grid-breakpoints, md)) {
      li > a {
        color: $greyish-brown;
      }
    }

    @media (min-width: map-get($grid-breakpoints, md)) {
      li > a {
        color: $warm-gray;
      }
    }
    .breadcrumbs__parent-path {
      white-space: nowrap;
    }

    li.breadcrumbs__back-button-container {
      margin-right: 12px;

      @media (min-width: map-get($grid-breakpoints, md)) {
        padding-right: 15px;
        margin-right: 15px;
        border-right: 1px solid $graphite-light;
        height: 16px;

        > a {
          font-size: 14px;
          color: $warm-gray;
          line-height: 16px;
        }
      }

      > a {
        font-size: 14px;
        color: $greyish-brown;
        display: flex;
        flex-wrap: nowrap;

        i {
          font-size: 16px;
        }

        span {
          margin-left: 10px;
        }
      }
    }
  }

  .separator span {
    color: $warm-gray;
    font-size: 16px !important;
  }

  .breadcrumbs-cta-wrapper {
    flex-shrink: 0;
  }

  .breadcrumbs-dropdown-toggle {
    cursor: pointer;
  }

  .breadcrumbs-dropdown-icon {
    display: inline-block;
  }

  .breadcrumbs-dropdown-icon-active {
    display: none;
  }

  .breadcrumbs-dropdown-toggle {
    &:hover,
    &[aria-describedby="breadcrumbs-popover"] {
      .breadcrumbs-dropdown-icon {
        display: none;
      }

      .breadcrumbs-dropdown-icon-active {
        display: inline-block;
      }
    }
  }
}

#breadcrumbs-popover {
  padding: 0;

  .popover-body{
    width: 150px;
    min-width: 150px;
  }

  ul {
    padding: 5px 0;
    display: block;
    list-style: none;
  }

  li {
    display: block;
    list-style: none;
  }

  a {
    color: $greyish-brown;
    font-size: 13px;
    font-weight: $font-weight-regular;
    line-height: 16px;
    display: block;
    max-width: 220px;
    padding: 8px 15px;

    &:hover {
      background-color: $pale-gray-four;
      text-decoration: none;
    }
  }
}

.dropdown-menu.mobile-breadcrumbs-cta-dropdown[placement=bottom-end] {
  margin-right: -3px;
  &:before {
    right: 4px;
  }
  &:after {
    right: 5px;
  }
}


@media (max-width: map-get($grid-breakpoints, sm)) {
  .breadcrumbs {
    .breadcrumbs__list {
      display: flex;
      flex-wrap: nowrap;
      position: relative;
      width: 100%;

      .breadcrumbs__current-path {
        display: inline-block;
      }
    }
  }
}

@media (max-width: map-get($grid-breakpoints, md)) {
  .breadcrumbs {

    .breadcrumbs__list {
      display: flex;
      position: relative;

      .separator span {
        margin: 0;
        font-size: 12px !important;
      }

      .breadcrumbs__current-path {
        > div {
          padding-right: 25px;
          white-space: nowrap;
        }
      }

      .breadcrumbs__title,
      li > a,
      li > span {
        font-size: 19px;
        line-height: 19px;
      }

      .separator {
        font-size: 16px;
      }
    }
  }
}

