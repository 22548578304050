.react-select {
  &__control--is-disabled {
    background-color: $gray-200 !important;
  }

  &__value-container--is-multi {
    padding: 2px 4px !important;


    .react-select__input {
      margin-left: 5px;
    }
  }

  &__single-value {
    width: 100%;
    justify-content: space-between;
    padding-right: 10px;
    margin: 0 !important;
  }

  &__option {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &--is-disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &__multi-value {
    border: 1px solid $pale-gray;
    border-radius: $borderRadius !important;
    background-color: #fff !important;
  }

  &__multi-value__label {
    font-size: 12px !important;
  }

  &__multi-value__remove {
    &:hover {
      background-color: #fff !important;
    }
  }

  &__option {
    //background: $white !important;

    * {
      background: transparent !important;
    }

    &:hover {
      cursor: pointer;
    }
    &:active,
    &:active:hover {
      backgroud: red !important;
    }
  }

  &__group {
    padding: 0 ;
  }

  &__group-heading {
    color: $black !important;
    font-size: 12px;
    font-weight: $font-weight-bolder !important;
    padding: 8px 16px;
    margin: 0 0 8px !important;
    background-color: $pale-gray;
  }

  &__group-item {
    &:hover {
      background-color: $pale-gray-five;
      cursor: pointer;

      .react-select__group-icon {
        background-color: #fff;
      }
    }
  }

  &__group-icon {
    background-color: $pale-gray;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: $graphite-dark;
    width: 20px;
    height: 20px;

    i {
      font-size: 10px;
    }
  }

  .user-select-item {
    &__title {
      margin: 0 0 5px;
    }
  }
}

.has-error .react-select__control,
.has-error .react-select__control:hover {
  border-color: $danger;
  box-shadow: none;
}
