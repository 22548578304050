//General

$mde-border-radius:                 2px !default;
$mde-border-color:                  #c8ccd0 !default;

.markdown-example {
  pre {
    color: $gray;
    font-size: 14px;
    line-height: 19px;
    background: $pale-gray-two;
    border: 1px solid $graphite-light;
    padding: 5px 10px;
  }
}

.react-mde {
  border-radius: 4px;
  display: flex;
  flex-direction: column-reverse;
  border-color: $pale-gray;
  border: 1px solid $silver;
  padding: 1px;

  .grip {
    display: none;
  }

  .invisible {
    display:  none;
  }

  + .markdown-help {
    position: absolute;
    right: 17px;
    margin-top: -33px;

    svg {
      opacity: 0.85;
      width: 15px;
    }

    &:hover {
      svg {
        cursor: pointer;
        opacity: 1;
      }
    }
  }

  button {
    padding: 0;
    font-weight: $font-weight-regular;
    outline: none;
    border: none;
    background: transparent;
    margin: 0;
    line-height: 21px;
    height: 24px;
    width: auto;
    text-align: center;
    border-radius: 2px;

    .fal,
    .fas {
      font-size: 12px;
      color: $greyish-brown;
      padding: 4px 6px;
    }

    &:hover {
      box-shadow: 0 0 0 1px $warm-gray-three inset;
    }

    &:active {
      background: #fff;
    }

    &:hover,
    &:active {
      .fal,
      .fas {
        color: $black;
      }
    }

  }
}

.has-error .react-mde {
  border-color: $tomato-red;

  textarea:focus {
    box-shadow: none;
  }
}

.mde-preview {
  padding: .375rem .75rem;
  height: 146px;
  overflow-y: auto;
}

.mde-header {
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: stretch;
  margin: 1px;
  background-color: $graphite-light;
  border: none;
  padding: 0 4px;
  display: flex;
  flex-direction: row;
  height: 40px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;

  .mde-tabs {
    display: flex;
    flex-direction: row;
    order: 2;
    align-items: center;

    .fal {
      color: $greyish-brown;

      &:hover {
        color: $black;
      }

      &.active {
        color: $td-blue;
      }
    }

    button {
      border-radius: $mde-border-radius;
      background-color: transparent;

      &.selected {
        display: none;
      }
      &:first-child {
        margin-left: 6px;
      }
      &.selected {
        border: 1px solid $mde-border-color
      }
    }
  }

  .svg-icon {
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: inherit;
    overflow: visible;
    vertical-align: -.125em;
  }

  ul.mde-header-group {
    list-style: none;
    display: flex;
    flex-wrap: nowrap;
    border-right: 1px solid $graphite-light;
    padding: 0 !important;
    align-self: center;
    margin: 0 4px 0 0;

    &.hidden {
      display: none;
    }

    li.mde-header-item {
      display: inline-block;
      position: relative;
      margin: 0 4px;

      button {
        margin: 0;
        border: none;
        background: none;
        text-align: center;

        @keyframes tooltip-appear {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
        @mixin tooltip-animation {
          animation-name: tooltip-appear;
          animation-duration: 0.2s;
          animation-delay: 0.5s;
          animation-fill-mode: forwards;
        }


        &.tooltipped {
          &:hover::before {
            @include tooltip-animation();
            opacity: 0;
            position: absolute;
            z-index: 1000001;
            width: 0;
            height: 0;
            color: rgba(0, 0, 0, 0.8);
            pointer-events: none;
            content: "";
            border: 5px solid transparent;
            top: -5px;
            right: 50%;
            bottom: auto;
            margin-right: -5px;
            border-top-color: rgba(0, 0, 0, 0.8);
          }
          &:hover::after {
            @include tooltip-animation();
            font-size: 11px;
            opacity: 0;
            position: absolute;
            z-index: 1000000;
            padding: 5px 8px;
            color: #fff;
            pointer-events: none;
            content: attr(aria-label);
            background: rgba(0, 0, 0, 0.8);
            border-radius: 3px;
            right: 50%;
            bottom: 100%;
            transform: translateX(50%);
            margin-bottom: 5px;
            white-space: nowrap;
          }

          &:hover,
          &:focus,
          &:active {
            .fas,
            .fal {
              color: $black;
            }
          }
        }

      }
      ul.react-mde-dropdown {
        position: absolute;
        left: 0;
        bottom: 30px;
        background-color: white;
        border: 1px solid $mde-border-color;
        padding: 5px;
        z-index: 2;
        transform: translateX(-9px);
        li {
          margin: 0;
          white-space: nowrap;
          list-style: none;
          display: block;
          width: 110px;

          button {
            display: block;
            height: auto;
            width: 100%;
            text-align: left;

            p {
              display: block;
              margin: 0;
              padding: 0;
              font-weight: bold;
              background: none;
              border: 0;

              &:hover {
                color: #4078c0;
              }
              &.header-1 {
                font-size: 20px;
              }
              &.header-2 {
                font-size: 18px;
              }
              &.header-3 {
                font-size: 14px;
              }
              &.header-4 {
                font-size: 12px;
              }
            }
          }

        }
        &::before {
          position: absolute;
          content: "";
          width: 0;
          height: 0;
          border: 8px solid transparent;
          bottom: -16px;
          bottom: -16px;
          border-top-color: rgba(0,0,0,.15);
          left: 3px;
          transform: translateX(50%);
        }

        &::after {
          position: absolute;
          content: "";
          width: 0;
          height: 0;
          border: 7px solid transparent;
          border-top-color: #fff;
          bottom: -13px;
          left: 5px;
          transform: translateX(50%);
        }
      }
    }
  }
}

textarea.mde-text {
  padding: .375rem .75rem;
  width: 100%;
  color: $greyish-brown;
  border: 0;
  vertical-align: top;
  overflow-y: auto;
  border-radius: 2px 2px 0 0;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px $td-blue;
  }
}

@media (max-width: map-get($grid-breakpoints, sm)) {
  .mde-header ul.mde-header-group li.mde-header-item {
    margin: 0 1px;
  }
}
