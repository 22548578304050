.card {
    &--email-verify {
        padding: 50px 0;

        &__icon {
            color: $state-success-text;
            font-size: 72px;
        }
    }

    &--plan-status {
        padding: 50px 0;
    }
    &__icon {
        color: $graphite-dark;
        font-size: 72px;
    }
}
