.circle-area {
  &--container {
    display: inline-block;
    background-color: $white;
    width: 18px;
    height: 18px;
    border-radius: 180px;
    border: 1px solid $td-blue;
    position: relative;
  }

  &--circle-one {
    display: inline-block;
    position: absolute;
    width: 0;
    height: 0;
    border-radius: 6px;
    border: 7px solid $white;
    top: 1px;
    left: 1px;
    z-index: 2;
  }

  &--circle-two {
    display: inline-block;
    position: absolute;
    width: 0;
    height: 0;
    border-radius: 6px;
    border: 6px solid $td-blue;
    z-index: 1;
    top: 2px;
    left: 2px;
  }

  &--fill-0 {
    .circle-area--circle-one {
      border-color: transparent;
    }

    .circle-area--circle-two {
      border-color: transparent;
    }
  }

  &--fill-1 {
    .circle-area--circle-one {
      border-top-color: transparent;
      border-right-color: transparent;
      transform: rotate(45deg);
    }

    .circle-area--circle-two {
      border-top-color: transparent;
      border-right-color: transparent;
      transform: rotate(117deg);
    }
  }

  &--fill-2 {
    .circle-area--circle-one {
      border-top-color: transparent;
      border-right-color: transparent;
      transform: rotate(45deg);
    }

    .circle-area--circle-two {
      border-top-color: transparent;
      border-right-color: transparent;
      transform: rotate(189deg);
    }
  }

  &--fill-3 {
    .circle-area--circle-one {
      border-top-color: transparent;
      border-right-color: transparent;
      border-bottom-color: transparent;
      transform: rotate(45deg);
    }

    .circle-area--circle-two {
      border-top-color: transparent;
      transform: rotate(261deg);
    }
  }

  &--fill-4 {
    .circle-area--circle-one {
      border-color: $td-blue;
      top: 2px !important;
      left: 2px !important;;
      border-left-color: transparent;
      border-right-color: transparent;
      border-bottom-color: transparent;
      border-width: 6px !important;
      transform: rotate(45deg);
    }

    .circle-area--circle-two {
      border-top-color: transparent;
      transform: rotate(333deg);
    }
  }
  &--fill-5 {
    .circle-area--circle-one {
      display: none;
    }
  }
}
