.list-group.simple{
  border-radius: $borderRadius;
  background-color: $white;
  border: 2px solid $pale-gray;
  padding: 5px 20px;

  .list-group-item{
    font-size: 13px;
    line-height: 16px;
    padding: 12px 0;
    background-color: transparent;
    border: none;
    border-top: 3px solid $pale-gray-two;
    color: $warm-gray;

    > .float-right{
      font-weight: $font-weight-semibold;
    }

    &.emphasize{
      color: $black;
      font-weight: $font-weight-semibold;
      background-color: $pale-gray-two;
      padding: 10px;
      margin: 0 -10px 5px;
      border-radius: $borderRadius;
    }
  }

  .list-group-item:first-child{
    border-top: none;
  }
}
