.file-uploader-direct-field input[type=file] {
  bottom: 0;
  height: 1px;
  opacity: 0;
  right: 0;
  position: fixed;
  width: 1px;
}

.file-uploader-direct-crop-wrapper {
  position: relative;
  aspect-ratio: 1/1;
}

.file-uploader-direct-crop-zoom {
  input {
    width: calc(100% - 50px);
  }
}
