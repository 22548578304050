.td-geo-suggest {
  position: relative;

  .options-container {
    background-color: $white;
    border: 1px solid $td-blue;
    border-top: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    top: 100%;
    z-index: 1;
  }
}
