html, body,
.mount-container,
.routes-container,
#auth-wrapper, #auth-wrapper > div,
.off-canvas,
.root-container {
  height: 100%;
}

html {
  font-size: 10px;
}

body {
  overflow: hidden;
}

.root-container {
  overflow: auto !important;

  &.dark-background {
    background-image: $splash-bg;
  }
}

.align-items-center {
  align-items: center;
}

// Position hubspot button underneath modal windows / panels.
body #hubspot-messages-iframe-container {
  z-index: 999;
}

.page:not(.page--no-shadow-effect) {
  flex: 1;
  padding: 30px 0 80px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;

  > .container:before {
    content: "";
    display: block;
    width: 105%;
    height: 25px;
    position: absolute;
    z-index: 9;
    left: -10px;
    top: 0;
    pointer-events: none;
    background: -moz-linear-gradient(top, rgba(247,248,249,1) 0%, rgba(247,248,249,0) 100%);
    background: -webkit-linear-gradient(top, rgba(247,248,249,1) 0%,rgba(247,248,249,0) 100%);
    background: linear-gradient(to bottom, rgba(247,248,249,1) 0%,rgba(247,248,249,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7f8f9', endColorstr='#00f7f8f9',GradientType=0 );
  }
}

.page--naked{
  background: white;
  height: 100%;
}

.alert.alert-danger {
  z-index: 100;
}

.separator {
  display: inline-block;
  margin: 0 10px;
}

@media (max-width: map-get($grid-breakpoints, sm)) {
  body,
  .page {
    overflow: visible;
  }
}

@media (max-width: map-get($grid-breakpoints, xs)) {
  .separator {
    margin: 0;
  }
}

.no-live-chat #hubspot-messages-iframe-container { display: none !important }
