.worksheet-day-box {
  &__day {
    font-size: 34px;
    color: $black;
    line-height: 1;
  }

  &__month-year {
    color: $greyish-brown;
    line-height: 1.3;
  }

  &__day-name {
    font-size: 12px;
    color: $warm-gray;
    line-height: 1.3;
  }
}

.worksheet-form, .pro-forma-invoice-form {
  &__empty-text{
    min-height: 320px;
    color: $warm-gray;

    i {
      font-size: 64px;
      color: $graphite-light;
    }
  }

  &__calendar-toggle  {
    color: $greyish-brown;
    height: 70px;
    width: 70px;

    i {
      font-size: 18px;
    }

    &:hover {
      cursor: pointer;
      background-color: $pale-gray-two;
    }

    &:active {
      background-color: $athens-gray;
    }
  }

  &__mobile-label {
    display: inline-block;
    color: $warm-gray;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: $font-weight-semibold;
  }

  .worksheet-items-total {
    span {
      float: right;
    }
  }
}


.worksheet-add-item-button {
  height: 70px;
  background: #52C2CC;
  color: #fff;
  width: 70px;
  border-radius: 0 4px 0 0;
  flex-basis: 70px;
  flex-shrink: 0;

  i {
    font-size: 22px;
    line-height: 1;
  }
}
