.upgrade-trial-banner {
  width: 100%;
  background: linear-gradient(110.69deg, $black 0%, #434C84 100%);
  padding: 12px 15px;

  @media (max-width: map-get($grid-breakpoints, md)) {
    font-size: 13px;

    .btn {
      font-size: 13px;
      padding: 10px 12px;
    }
  }
}
