$skeleton-border-radius: 2px;

.skeleton {
  &--circle {
    vertical-align: middle;
    margin-right: 15px;
    display: inline-block;
    background-color: $skeleton-fill-color;
    border-radius: $skeleton-border-radius;
  }
  &--quarter {
    border-color: transparent;
    border-top-color: darken($skeleton-fill-color, 20%);
  }
  &--rectangle {
    vertical-align: middle;
    display: inline-block;
    background-color: $skeleton-fill-color;
    border-radius: $skeleton-border-radius;
    max-width: 100%;

    & + .skeleton--rectangle {
      margin-left: 10px;
    }
  }

  &--settings-page {
    margin-top: 0;

    &-item {
      padding: 15px 0 20px;
      position: relative;
    }
  }

  &--people-list {
    margin-top: 0;

    &-item {
      padding: 15px 0 20px;
      margin-top: 15px;
      border-bottom: 1px solid $skeleton-fill-color;
      position: relative;

    }
  }

  &--project-list {
    margin-top: 0;

    &-item {
      padding: 5px 0 10px;
      margin-top: 15px;
      border-bottom: 1px solid $skeleton-fill-color;
      position: relative;
    }
  }
  &__pie-chart {

    &--main-circle {
      position: absolute;
      z-index: 2;
    }
    &--outer-circle {
      position: relative;
      background-color: darken($skeleton-fill-color, 10%);
      z-index: 1;
    }
    &--quarter {
      position: absolute;
      z-index: 3;
    }
  }
  &__chart-stacked,
  &__chart-basic-line {
    box-shadow: none;
    position: relative;
    .graph-container {
      height: 400px;
      width: 100%;
      padding-bottom: 50px;
    }
    .y-axis {
      height: 100%;
      width: 50px;
      span {
        margin-left: 0;
      }
    }
    .data-area {
      height: 100%;
      width: 100%;
      border-bottom: 1px solid $light-gray;
      position: relative;
      z-index: 2;
      &--column {
        position: relative;
        .skeleton--rectangle:not(:first-child) {
          background-color: $warm-gray-three;
          margin-left: 0;
          margin-top: 1px;
        }
        .profile-pic {
          position: absolute;
          bottom: -40px;
        }
      }
    }
    &--with-grid::after {
      position: absolute;
      width: 94%;
      height: 350px;
      top: 15px;
      left: 45px;
      background-size: 100px 100px;
      background-image:
        linear-gradient(to right, $pale-gray 1px, transparent 1px),
        linear-gradient(to bottom, $pale-gray 1px, transparent 1px);
      content: '';
    }
  }
  &__chart-basic-line {
    overflow: hidden;
    .chart-line {
      z-index: 2;
      position: absolute;
      width: 100px;
      height: 1px;
      border: 1px solid $warm-gray-three;
      &--first {
        top: 140px;
        left: 10px;
      }
      &--second {
        top: 179px;
        left: 99px;
        transform: rotate(50deg);
      }
      &--third {
        top: 205px;
        left: 178px;
        width: 150px;
        transform: rotate(-10deg);
      }
      &--fourth {
        top: 162px;
        left: 320px;
        width: 164px;
        transform: rotate(-22deg);
      }
      &--fifth {
        top: 131px;
        left: 480px;
        width: 130px;
        transform: rotate(0deg);
      }
    }
    .chart-line-connector {
      z-index: 2;
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 10px;
      border: 1px solid $white;
      background-color: $warm-gray-three;
      &--first {
        top: 136px;
        left: 0;
      }
      &--second {
        top: 137px;
        left: 110px;
      }
      &--third {
        top: 213px;
        left: 175px;
      }
      &--fourth {
        top: 188px;
        left: 321px;
      }
      &--fifth {
        top: 127px;
        left: 475px;
      }
    }
  }
  &__dashboard-widget {
    margin-top: 0;

    &__header {
      height: 71px;
      padding: 5px 0 10px;
      margin-bottom: 15px;
      border-bottom: 1px solid $skeleton-fill-color;
      position: relative;
    }

    &__footer {
      padding: 5px 0 10px;
      margin-bottom: 15px;
      position: relative;
    }
  }

  &--task-list {
    margin-top: 0;

    &-item {
      padding: 5px 0 10px;
      margin-top: 15px;
      border-bottom: 1px solid $skeleton-fill-color;
      position: relative;
    }
  }

  &--opportunity-list {
    margin-top: 0;

    &-item {
      padding: 5px 0 10px;
      margin-top: 15px;
      border-bottom: 1px solid $skeleton-fill-color;
      position: relative;
    }
  }
}

.skeleton--finance-table {
  .react-bs-table {
    background-color: transparent;
  }

  .react-bs-container-body,
  tr,
  td {
    overflow: hidden;
  }
}

@media (max-width: map-get($grid-breakpoints, xl)) {
  .skeleton--finance-table .react-bs-container-body,
  .td-table {
    overflow: visible;
  }
}
