.tag > span,
.react-tagsinput-tag {
  background: transparent;
  padding: 4px 8px;
  font-size: 13px;
  font-weight: $font-weight-regular;
  color: $warm-gray;
  line-height: 24px;
  border-radius: 3px;
  margin: 3px 10px 3px 0;
  border: 1px solid rgba(21,132,198,0.1)
}

.skill-tags-container {
  display: flex;
}

// Tag input
.react-tagsinput {
  padding-top: 2px;
  border-radius: 3px;
  border-color: $silver;
}

.react-tagsinput-input {
  width: 0;
  padding: 4px 0;
  line-height: 24px;
  margin: 3px;
}
.react-tagsinput-input input {
  outline: 0;
  border: 0;
}

.react-tagsinput-remove {
  color: $warm-gray;

  &:hover {
    color: $black;
    text-decoration: none;
  }
}

.react-tagsinput--focused {
  border-color: $td-blue;
}
