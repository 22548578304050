.page--imports-view {
  .bulk-import-checkable-list {
    column-gap: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 1rem;

    li + li {
      margin-top: 0;
    }

    .checkable-list__option {
      > a {
        display: block;
        position: relative;

        .checkable-list__indicator {
          padding: 0;
          position: absolute;
          right: 1.5rem;
          top: 1.5rem;
        }
      }

      &--disabled {
        opacity: 0.5;

        > a {
          cursor: not-allowed;
        }
      }
    }

    &.type {
      @media (min-width: map-get($grid-breakpoints, lg)) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }

      .fa-users {
        --fa-secondary-color: #{$tomato-red};
        --fa-secondary-opacity: 1.0;
      }
  
      .fa-clipboard-list-check {
        --fa-secondary-color: #{$td-blue};
        --fa-secondary-opacity: 1.0;
      }
  
      .fa-bars-progress {
        --fa-secondary-color: #{$tomato-red};
        --fa-secondary-opacity: 1.0;
      }
  
      .fa-list-check {
        --fa-secondary-color: #{$pending};
        --fa-secondary-opacity: 1.0;
      }

      .fa-pen-ruler {
        --fa-secondary-color: #{$pending};
        --fa-secondary-opacity: 1.0;
      }
    }

    &.user-type {
      .fa-user {
        --fa-secondary-color: #{$pending};
        --fa-secondary-opacity: 1.0;
      }
  
      .fa-user-crown {
        --fa-secondary-color: #{$tomato-red};
        --fa-secondary-opacity: 1.0;
      }
    }

    &.employment-type {
      .fa-briefcase {
        --fa-secondary-color: #{$pending};
        --fa-secondary-opacity: 1.0;
      }
  
      .fa-house-laptop {
        --fa-secondary-color: #{$tomato-red};
        --fa-secondary-opacity: 1.0;
      }
    }
  }

  .import-worksheets-page--download--info i {
    color: $medium-green;
  }
}

.import-field-mapping {
  width: 100%;

  th {
    padding: 16px 0;
    font-weight: $font-weight-semibold;
    color: $warm-gray;
    vertical-align: middle;
  }
  th:nth-child(2) {
    padding-left: 25px;
  }


  tr {
    td:first-child {
      background-color: $pale-gray-two;
      width: 200px;
      max-width: 200px;
    }

    td {
      padding: 0 16px 8px 16px;
    }

    td:nth-child(2) {
      padding-left: 25px;
    }
  }
}

.import-field-requirement-example {
  background-color: $pale-gray;
  font-size: 12px;
}
