.discussion-filter__container {
  .input--text {
    width: 200px;
  }
}

.discussion-card {
  &__container {
    list-style: none;

    a {
      text-decoration: none;
    }

    i {
      background-color: $pending;
      height: 48px;
      min-width: 48px;

      &::before {
        align-items: center;
        color: $white;
        display: flex;
        font-size: 20px;
        height: 100%;
        justify-content: center;
        width: 100%;
      }
    }
  }

  &__name {
    color: $black;
    font-size: 18px;
    line-height: 27px;
    font-weight: $font-weight-regular;
  }

  &__info {
    color: $warm-gray;
    font-size: 14px;
    line-height: 20px;
    font-weight: $font-weight-regular;
  }

  &__new-messages {
    color: $black;
    font-size: 14px;
    line-height: 20px;
    font-weight: $font-weight-regular;

    span {
      background-color: $pale-gray;
      color: $graphite-light;
      font-size: 14px;
      line-height: 20px;
      font-weight: $font-weight-semibold;

      &.active {
        background-color: $td-blue;
        color: $white;
      }
    }
  }
}
