.td-dropdown.drop-chevron {
  .dropdown-toggle button {
    height: 40px;
    width: 40px;
  }

  &.show .dropdown-toggle button i {
    transform: rotate(180deg);
  }
}
