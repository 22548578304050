/**
 * Hubspot overrides
 */

// Position the the hubspot cookies confirmation dialog to the bottom
html div#hs-eu-cookie-confirmation {
  bottom: 0;
  top: inherit !important;
  div#hs-eu-cookie-confirmation-inner {
    div#hs-en-cookie-confirmation-buttons-area {
      text-align: left !important;
    }
  }
}

html div#hubspot-messages-iframe-container {
  // display: none!important;
  &.hide {
    display: none!important;
  }

  &.show {
    display: block!important;
  }

  > .shadow.active {
    box-shadow: none !important;
  }
}