.wizard-page .main-form-content {
  padding: 50px 0;
}

.wizard-page .form-footer-nav {
  width: 100%;
  background-color: $pale-gray-two;
  text-align: right;
  padding-top: 5px;

  button {
    color: $td-blue;
  }

  a {
    text-transform: capitalize;
  }

  button, a{
    background: none;
    border: none;
    font-weight: $font-weight-semibold;

    &:hover,
    &:focus,
    &:active{
      outline: none;
      color: lighten($td-blue, 20%);
    }

    &[disabled] {
      color: $warm-gray;
    }

    i{
      position: relative;
      top: -1px;
    }

  }

  .btn-primary:disabled,
  .btn-primary:disabled:hover,
  .btn-primary:active,
  .btn-primary:active:focus,
  .btn-primary:active:hover{
    background-color: transparent;
    border: none;
  }

  .btn:last-child {
    margin-left: 0;
    //margin-right: -px;
  }
}

.wizard-steps {
  border-bottom: 1px solid $pale-gray-two;
  cursor: default;
}

.wizard-step {
  font-size: 14px;
  font-weight: $font-weight-semibold;
  display: inline-block;
  color: $warm-gray;
  width: 20%;
  padding: 14px;

  &.completed, &.active {
    border-bottom: 4px solid $td-blue;
  }

  &.completed {
    color: $greyish-brown;
  }

  &.active {
    color: $td-blue;
  }
}

.wizard-step__num {
  border: 1px solid $pale-gray-two;
  border-radius: 3px;
  height: 24px;
  width: 24px;
  line-height: 24px;
  display: inline-block;
  text-align: center;
  margin-right:5px;

  .active + & {
    border-color: $td-blue;
  }
}

.wizard-steps + .wizard-form {
  padding-top: 24px;
}
/*
@media (max-width: map-get($grid-breakpoints, lg)) {
  .wizard-step {
    padding: 14px 10px;
  }
}
@media (max-width: map-get($grid-breakpoints, lg)) {
  .wizard-step {
    font-size: 12px;
    padding: 14px 8px;
  }
}
*/

.wizard-horizontal-step__num, .wizard-vertical-step__num {
  background-color: $white;
  color: $warm-gray;
  font-weight: $font-weight-semibold;
  display: inline-block;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  font-size: 14px;
  text-align: center;
  line-height: 26px;
  margin-top: -1px;
  border: none;
  margin-right: 8px;
  border: 1px solid #CFD7E1;

  &.active {
    background-color: $td-blue;
    color: $white;
  }

  &.completed {
    background-color: $medium-green;
    color: $white;
  }
}

.wizard-horizontal-steps {
  border-bottom: 1px solid $pale-gray-two;
  display: flex;
  align-items: center;
  justify-content: center;

  .wizard-horizontal-step {
    font-size: 14px;
    font-weight: $font-weight-semibold;
    color: $warm-gray;
    width: 20%;
    padding: 14px;
    line-height: 20px;
    position: relative;
    width: auto;

    &.active {
      color: $black;

      .wizard-horizontal-step__num {
        background-color: $td-blue;
        color: $white;
      }
    }

    &.completed {
      color: $black;

      .wizard-horizontal-step__num {
        background-color: $medium-green;
        color: $white;
      }
    }
    &.rejected {
      color: $black;

      .wizard-horizontal-step__num {
        background-color: $tomato-red;
        color: $white;
      }
    }
    &.pending {
      color: $black;

      .wizard-horizontal-step__num {
        background-color: $graphite-dark;
        color: $white;
      }
    }

  }

  .wizard-horizontal-separator {
    margin: 0 8px;
    width: 32px;
    height: 1px;
    background-color: $graphite-light;
  }
}

.wizard-vertical-step__disabled {
  opacity: 0.7;
}

.wizard-vertical__buttons {
  background-color: $white;
  bottom: 0;
  position: fixed;
  right: 0;
  width: calc(100% - #{$nav-bar-width});

  @media (max-width: map-get($grid-breakpoints, lg)) {
    width: 100%;
  }
}