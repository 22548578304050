.cookies-notification {
  width: 100%;
  background-color: #555;
  color: #fff;
  padding: 35px;
  text-align: center;
  font-size: 16px;
  margin: 0 !important;

  p {
    margin: 0 65px 0 0;
    float: none;
    display: inline-block;
    color: #fff !important;
  }

  a.more {
    color: #bdbdbd;

    &:hover {
      text-decoration: underline;
    }
  }

  &_confirm {
    color: #555 !important;
    background-color: #fff;
    text-transform: uppercase;
    border-radius: 4px;
    padding: 12px 30px;
    font-size: 13px;
    font-weight: $font-weight-semibold;
    margin-top: -10px;

    &:hover {
      box-shadow: 2px 2px 3px -2px #2e495c;
    }

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      background-color: #eff7fb;
    }
  }

  &_confirm,
  &_confirm:active {
    box-shadow: 2px 2px 0 -2px #2e495c;
  }
}

@media (max-width: 1120px) {
  .cookies-notification p {
    margin: 0 0 30px;
  }
}
