.report-list__container {
  li {
    border: 1px solid $graphite-light;
    border-radius: 4px;
    font-size: 13px;
    transition: border 0.2s, background 0.2s;
    background-color: $white;
    overflow: hidden;
  }
}
