.custom-checkbox {
  color: #fff;
  display: inline-block;
  font-size: 10px;
  width: 14px;
  flex-basis: 14px;
  flex-shrink: 0;
  height: 14px;
  line-height: 14px;
  text-align: center;
  border-radius: 2px;
  border: 1px solid $graphite-light;
  background-color: $white;
  margin-right: 5px;
  vertical-align: middle;

  &:hover{
    border-color: $td-blue;
    cursor: pointer;
  }

  &--checked{
    border-color: $td-blue;
    color: $td-blue;
  }

  &--disabled {
    opacity: 0.5;
  }
}

.custom-checkbox--large {
  background: transparent;
  border-radius: 10px;
  border: 1px solid $medium-green;
  color: $white;
  cursor: pointer;
  flex-basis: 20px;
  font-size: 12px;
  height: 20px;
  line-height: 18px;
  text-align: center;
  vertical-align: middle;
  width: 20px;

  &:hover {
    border-color: $medium-green;
  }

  &.custom-checkbox--checked {
    background: $medium-green;
    border-color: $medium-green;
    cursor: default;
  }
  &.custom-checkbox--updated {
    background: $pending;
    border-color: $pending;
    cursor: default;
  }
}

.custom-checkbox--larger {
  flex-basis: 16px;
  font-size: 10px;
  height: 16px;
  line-height: 16px;
  width: 16px;
}

.custom-checkbox--green {
  border: 1px solid $medium-green;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  flex-basis: 16px;
  line-height: 16px;

  &:hover {
    background: $success-bg-transparent;
  }

  &.custom-checkbox--checked {
    border-color: $medium-green;
    background: $medium-green;
  }

  &:hover {
    border-color: $medium-green;
  }
}
