.pagination-container {
  .pagination-container__page-info {
    margin-left: 5px;
  }

  .pagination-container__pages {
    display: block;

    .previous,
    .next {
      display: none !important;
    }

    > li {
      margin-right: 10px;
      // since previous, next buttons are not displayed we use need this to target the last child -1
      &:nth-last-child(2) {
        margin-right: 5px;
      }
    }

    > li,
    > li.disabled {
      display: inline-block;

      a {
        display: inline-block;
        font-size: 12px;
        line-height: 24px;
        font-weight: $font-weight-semibold;
        color: $warm-gray;
        width: auto;
        min-width: 28px;
        height: 26px;
        margin: 0;
        padding: 0 5px;
        text-align: center;
        background-color: $pale-gray-two;
        border-radius: $borderRadius !important;
        border: solid 1px $graphite-light;
      }

      > a:hover,
      > a:focus,
      > span:hover,
      > span:focus {
        color: $warm-gray;
        background-color: $pale-gray-two;
        border-color: $td-blue;
        text-decoration: none;
      }
    }

    > li.disabled {
      opacity: 0.7;
    }

    > .active {
      > a,
      > a:hover,
      > a:focus,
      > span,
      > span:hover,
      > span:focus {
        color: $white;
        background-color: $td-blue;
        border-color: $td-blue;
      }
    }
  }
  @media (max-width: map-get($grid-breakpoints, sm)) {
    flex-direction: column;

    .pagination-container__page-info {
      margin-bottom: 15px;
    }

  }
}

