.dashboard-banner-widget-section {
  background-image: url('~core/assets/img/dashboard/banner-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: 0;
  background-position-y: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 220px;
  padding: 77px 48px;
  border-radius: 4px;
  margin-bottom: 24px;
  position: relative;

  &__close {
    position: absolute;
    right: 1.5rem;
    top: 1rem;

    i {
      color: $white;
      font-size: 16px;
    }

  }

  &__title {
    color: white;
    margin-bottom: 7px;
    margin-top: 0px;
  }

  &__subtitle {
    color: white;
  }
}
