.td-progress-bar{

  &__wrapper{
    width: 100%;
    height: 10px;
    overflow: hidden;
    border-radius: 5px;
    background-color: $white;
  }

  height: 2px;
  position: absolute;
  bottom: -2px;
  padding-left: 6px;
  left: 0;

  .indicator{
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 6px;
    position: absolute;
    right: 0;
    bottom: -2px;
  }

  &--wrapped{
    .indicator{
      right: 1px;
      bottom: 1px;
      width: 4px;
      height: 4px;
      background-color: rgba(255, 255, 255, 0.65) !important
    }
  }

  &--posted {
    background-color: $lightblue-two;

    .indicator{
      background-color: $lightblue-two;
    }
  }

  &--danger,
  &--stopped,
  &--rejected,
  &--cancelled {
    background-color: $tomato-red;

    .indicator{
      background-color: $tomato-red;
    }
  }

  &--draft {
    background-color: $warm-gray;
  }

  &--warning,
  &--pending,
  &--pending-rate-agreement,
  &--in-progress {
    background-color: $pending;

    .indicator{
      background-color: $pending;
    }
  }

  &--success,
  &--completed {
    background-color: $medium-green;

    .indicator{
      background-color: $medium-green;
    }
  }
}
