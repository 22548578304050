/**
 * The geosuggest module
 * NOTE: duplicated font-sizes' are for browsers which don't support rem (only IE 8)
 */

.geosuggest__input {
  width: 100%;
  padding: .5em 1em;
  -webkit-transition: border 0.2s, box-shadow 0.2s;
          transition: border 0.2s, box-shadow 0.2s;
}
.geosuggest__suggests-wrapper{
  position: relative;
  border-radius: 3px
}
.geosuggest__input:focus {
  border-color: $td-blue;
  box-shadow: 0 0 0 transparent;
}
.geosuggest__suggests {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 25em;
  padding: 0;
  margin-top: -2px;
  background: #fff;
  border: 1px solid $td-blue;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  z-index: 5;
  -webkit-transition: max-height 0.2s, border 0.2s;
          transition: max-height 0.2s, border 0.2s;
}
.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}

/**
 * A geosuggest item
 */
.geosuggest__item {
  font-size: 13px;
  padding: .5em .65em;
  cursor: pointer;
}
.geosuggest__item:hover,
.geosuggest__item:focus {
  background: #f5f5f5;
}
.geosuggest__item--active {
  background: #267dc0;
  color: #fff;
}
.geosuggest__item--active:hover,
.geosuggest__item--active:focus {
  background: #ccc;
}
.geosuggest__item__matched-text {
  font-weight: $font-weight-semibold;
}

