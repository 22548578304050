.project-widget-list {
  margin-left: 0;
  padding-left: 0;
  list-style: none;

  hr {
    margin-top: 10px;
    margin-bottom: 15px;
  }

  &__item {
    padding: 15px;
    border-bottom: 1px solid $pale-gray;

    &:last-child {
      border-bottom: 0px;
    }

    &--title {
      margin: 0 0 15px 0;
      display: inline-block;
      font-weight: $font-weight-regular;

      a {
        line-height: 22px;
        font-size: 18px;
        color: $black;
      }
    }

    &--label {
      margin-right: 5px;


      & + span {
        color: $black;
      }
    }

    &--status {
      text-transform: capitalize;
      flex: 1;
    }

    &--progress {
      margin-bottom: 0;
      line-height: 1;
      display: flex;

      &__percentage {
        justify-content: flex-end;
      }
    }
  }

  .budget-progress-bar {
    &__wrapper {
      margin-top: 5px;
      position: relative;
      height: 4px;
      border-radius: 2px;
      width: 100%;
      overflow: hidden;
      background: $graphite-light;

      .td-progress-bar {
        width: 10%;
        height: 100%;
        top: 0;
        border-radius: 2px;

        .indicator {
          display: none;
        }
      }
    }
  }
}
