.contacts {
  &-list {
    .card:not(:first-child) {
      margin-top: 10px;
    }
  }
}

.contact-list-item {
  &--muted {
    .card {
      opacity: 1;
    }
    .card-body {
      background-color: $pale-gray-two;
      color: $warm-gray
    }
  }
  .status {
    margin-right: 50px;
  }
}

.contact {
  &-preview {
    .name {
      font-size: 18px;
    }
    .fal {
      color: $warm-gray;
    }
    .fa-times {
      font-size: 20px;
      padding: 10px 14px;
      &:hover {
        color: $black;
        cursor: pointer;
      }
    }
    .fa-times,
    .td-drop-button__toggle > span {
      position: relative;
      top: -10px;
      background-color: $pale-gray-two;
      border-radius: 3px;
      color: $warm-gray;
    }
    .information,
    .attachments,
    .skills {
      border-top: 1px solid $gray-200;
    }
  }
}

.contacts-import {
  &--init {
    .important-info {
      font-size: 13px;
      color: $warm-gray;
      li {
        list-style: initial;
      }
    }
  }
  &--mapping {
    .form-group {
      margin-bottom: 0;
    }
    .mapping-header {
      color: $warm-gray;
    }
  }
  &--preview {
    table {
      tr {

      }
      td[data-label="Status"] {
        color: $tomato-red;
      }
    }
  }
}

.contacts-skeleton {
  &--processing {
    .card-body {
      padding-top: 100px;
      padding-bottom: 100px;
    }
    .fa-file-import {
      font-size: 64px;
    }
  }
}

.contact-preview .value {
  color: $greyish-brown;
}