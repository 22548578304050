.service-order-cutoff-reminder {
  .calendar {
    position: relative;
    height: 140px;
    width: 140px;

    i {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;

      &:after {
        color: $pending;
        opacity: 1;
      }
    }

    .date {
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;

      .day {
        font-size: 3rem;
        font-weight: $font-weight-semibold;
      }
    }
  }

  .countdown {
    background-color: $graphite-light;
    border-radius: 4px;
    color: $tomato-red;
    font-size: 3rem;
    font-weight: $font-weight-semibold;
  }
}
