.rating {
  display: block;
  font-size: 14px;
  margin: 0 10px;
  vertical-align: text-bottom;

  > span {
    display: inline-block;

    + span {
      margin-left: 5px;
    }
  }

  .fal {
    color: $warm-gray-two;
  }

  .fas {
    color: $pending;
  }

  .icon-star{
    color: $graphite-light;

    &.active{
      color: $pending;
    }
  }

  &.extra-small {
    .fal,
    .fas {
      font-size: 13px;
    }

    > span {
      display: inline-block;
      i {
        font-size: 13px;
      }

      + span {
        margin-left: 3px;
      }
    }
  }

  &.large {
    font-size: 20px;
    margin: 0 8px;
  }

  &.extra-large {
    font-size: 28px;
    margin: 0 10px 5px;
  }
}

.form-group--rating {
  position: relative;
}
