// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------
// Colour pallet
$athens-gray: #f2f4f7;
$ruby: #cb003d;
$black-two: #3D3D3D;
$black: #2B304F;
$cloudy-blue-two: #d7dee6;
$cloudy-blue-three: #d3dae2;
$curious-blue: #2C90CC;
$error-block-background: #fef5f2;
$gray: #555;
$graphite-dark: #739DA3;
$graphite-light: #CFDFE1;
$greyish-brown: #464A67;
$light-gray-2: #f1f1f1;
$lightblue: #eff8fc;
$lightblue-two: #7DBDF9;
$list-item-background-color: rgba(0, 0, 0, 0.02);
$light-green: #9FE870;
$medium-green: #52CC83;
$dark-green: #1D3208;
$nice-blue: #0e6ead;
$pale-gray: #EDF2F2;
$pale-gray-two: #F7F9F9;
$pale-gray-three: #e7ecf1;
$pale-gray-four: #fafbfc;
$pale-gray-five: #f3f5f5;
$pale-gray-seven: #ebebeb;
$pale-gray-eight: #F6F7F8;
$payments-bg-color: rgba(203,0,61,0.05);
$pending: #FAB152;
$people-bg-color: rgba(58,188,78,0.05);
$pph-orange: #ff7300;
$projects-bg-color: rgba(21,132,198,0.05);
$silver: #e0e4e5;
$skeleton-fill-color: #e7ebf1;
$splash-bg: linear-gradient(110.69deg, $black 0%, #434C84 100%);
$td-blue: #52C2CC;
$tomato-red: #FF595A;
$transparent: rgba(0, 0, 0, 0);
$warm-gray: #868998;
$warm-gray-two: #9b9b9b;
$warm-gray-three: #cccccc;
$white: #fff;
$material-primary-blue: #3f51b5;
$classification-risk-low: fade-out($medium-green, 0.8);
$classification-risk-moderate: fade-out($pending, 0.8);
$classification-risk-high: fade-out($tomato-red, 0.8);
$pending-orange: #F4A742;
$greyish-green-transparent: transparentize(#52CC83, 0.9);
$dark-purple: #434C84;

// Background colours
$success-bg: #EBF8ED;
$success-bg-transparent: rgba($medium-green, 0.1);

$info-bg: #F3F9FC;
$info-bg-transparent: rgba(99,190,234,0.1);

$error-bg: $error-block-background;
$error-bg-transparent: rgba(255, 89, 90, 0.1);

$warning-bg: #FDFAF6;

$pending-bg: #FBF5ED;
$pending-bg-transparent: rgba($pending, 0.1);

// Pastel background colors
$bg-opacity: 0.9;
$medium-green-pastel: transparentize($medium-green, $bg-opacity);
$pending-pastel: transparentize($pending, $bg-opacity);

/// Regular font family
/// @type List
$text-font-stack: 'AvertaPE', 'Open Sans', 'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;

/// Code (monospace) font family
/// @type List
$code-font-stack: 'Courier New', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Monaco', monospace;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$body-bg: $pale-gray-two;
$input-color: $greyish-brown;

// White transparent
$white-75: rgba(255, 255, 255, 0.75);
$white-40: rgba(255, 255, 255, 0.4);
$white-10: rgba(255, 255, 255, 0.1);

/* Modals */
$modal-sm: 390px;
$modal-xs: 350px;

/// Copy text color
/// @type Color
$text-color: $greyish-brown;
$state-warning-text: $pending;
$state-success-text: $medium-green;
$text-muted: $warm-gray-two;

/// Main brand color
/// @type Color
$brand-color: rgb(229, 0, 80);

/// Light grey
/// @type Color
$light-gray: rgb(237, 237, 237);

/// Medium grey
/// @type Color
$mid-gray: rgb(153, 153, 153);

/// Dark grey
/// @type Color
$dark-gray: rgb(68, 68, 68);


/// Container's maximum width
/// @type Length
$max-width: 1180px;


/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
  'small': 320px,
  'medium': 768px,
  'large': 1024px,
);

// General variables
// $font-size-base: 0.8rem;
$max-content-width: 1190px;
$heading-color: $black;
$borderRadius: 4px;
$shadow-sm: 0 1px 5px 0 rgba(0,0,0,.15);
$shadow-md: 0 1px 5px 0 rgba(0,0,0,.15), 0 0 4px -1px rgba(0,0,0,0.3);

/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: '/assets/';

$state-success-text: $medium-green;
$form-control-radius: 3px;

//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

$btn-font-weight: $font-weight-semibold;
$padding-large-horizontal: 40px;

$btn-default-color: $nice-blue;
$btn-default-bg: #fff;
$btn-default-border: $graphite-light;
$btn-default-disabled-color: $warm-gray;

$btn-primary-color: #fff;
$btn-primary-bg: $td-blue;
$btn-primary-border: $td-blue;
$btn-primary-disabled-color: #00507F;;

$btn-success-color: #fff;
$btn-success-bg: $medium-green;
$btn-success-border: $medium-green;
$btn-success-disabled-color: #09771B;

$btn-danger-color: #fff;
$btn-danger-bg: $tomato-red;
$btn-danger-border: $tomato-red;
$btn-danger-disabled-color: #960001;


//== Form states and alerts
$state-success-bg: #f2f7ef;

