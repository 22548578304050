.edit-manager-budget{
  .profile-pic{
    vertical-align: top;
  }

  .modal-body{
    padding-bottom: 0;
  }

  .modal-footer{
    padding-top: 0;
  }

  .alert-danger {
    background-color: transparent;
  }

  &__totals{
    font-size: 13px;
    background-color: $pale-gray;
    border-radius: $borderRadius;
    padding: 5px 20px 0;
    margin: 20px 0;

    > div{
      padding: 10px 0;
      border-top: 3px solid $pale-gray-two;

      &:first-child{
        border-top: none;
      }
    }

    span{
      color: $warm-gray;
    }
    .value{
      float: right;
      font-weight: $font-weight-semibold;
      color: $warm-gray;

      &.total span{
        color: $black;
      }
    }
  }

  &__meta{
    display: inline-block;
  }

  &__name{
    @extend .truncate;
    display: block;
    width: 100%;
    font-weight: $font-weight-semibold;
    font-size: 16px;
    padding-bottom: 5px;
  }

  &__job-title{
    @extend .truncate;
    display: block;
    width: 100%;
  }

  .modal-footer {
    border-top: none;

    > .row { 
      > div:first-child {
        padding-right: 5px;
      }

      > div + div {
        padding-left: 5px;
      }
    }
  }
}