.nav-tabs {
  border-bottom: none;
  display: flex;
  padding: 0;
  border-bottom: 1px solid $pale-gray;

  .nav-item {
    padding: 0;
    margin-left: 30px;

    &:first-child {
      margin-left: 0;
    }

    a {
      color: $warm-gray;
      font-size: 12px;
      letter-spacing: 0.5px;
      line-height: 15px;
      font-weight: $font-weight-semibold;
      border-radius: 0;
      border: none;
      box-shadow: 0 -2px transparent inset;
      text-transform: uppercase;
      padding: 18px 0 15px;
      margin: 0 0 0 1px;

      &:hover,
      &:focus,
      &:active {
        color: $black;
        background-image: none;
        border: none;
      }
    }

    a.active {
      box-shadow: inset 0 -3px 0 0 $graphite-dark;

      &:focus,
      &:hover {
        color: $black;
        border: none;
      }
    }
  }

  &.nav-tabs--simple {
    display: inline-flex;
    border-bottom: none;
    padding: 0;
    margin: 0;

    a {
      padding: 0;

      &.active {
        box-shadow: none;
      }
    }

    .nav-item + .nav-item {
      margin-left: 30px;
    }
  }

  &.nav-tabs--pills {
    display: inline-flex;
    border-bottom: none;
    padding: 0;
    margin: 0;


    a {
      padding: 8px 15px;
      border-radius: 16px;
      text-transform: uppercase;
      line-height: 16px;
      font-weight: $font-weight-semibold;

      &:hover {
        color: $warm-gray;
        background-color: $white;
      }

      &.active {
        box-shadow: none;
        background-color: $white;
      }
    }

    .nav-item + .nav-item {
      margin-left: 10px;
    }
  }
}


@media (max-width: map-get($grid-breakpoints, lg)) {
  .nav-tabs {
    padding: 0;

    > .nav-item {
      > a {
        text-align: center;
        display: flex;
        height: 100%;
        align-items: center;
      }

      + .nav-item {
        margin-left: 15px !important;
      }
    }
  }
}

@media (max-width: map-get($grid-breakpoints, sm)) {
  .nav-tabs  > .nav-item {
    > a {
      padding-left: 10px;
      padding-right: 10px;
    }

    + .nav-item {
      margin-left: 5px !important;
    }
  }
}
