// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.form-group {
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-style: normal !important;
    color: $warm-gray-two;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-style: normal !important;
    color: $warm-gray-two;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    font-style: normal !important;
    color: $warm-gray-two;
  }
}

*:focus {
  outline: none;
}

label .markdown-content,
.custom-checkbox + .markdown-content {
  display: inline;
}

.no-select {
  user-select: none;
}

.bg-light-grey {
  background-color: $pale-gray-eight;
}

//::-webkit-scrollbar {
//  width: 14px;
//  height: 18px;
//}
//::-webkit-scrollbar-thumb {
//  height: 6px;
//  border: 4px solid rgba(0, 0, 0, 0);
//  background-clip: padding-box;
//  -webkit-border-radius: 7px;
//  background-color: rgba(0, 0, 0, 0.15);
//  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
//}
//::-webkit-scrollbar-button {
//  width: 0;
//  height: 0;
//  display: none;
//}
//::-webkit-scrollbar-corner {
//  background-color: transparent;
//}
/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*, *::before, *::after {
  box-sizing: inherit;
}

a:focus {
  outline: none;
}

/**
 * Basic styles for links
 */
a {
  cursor: pointer;

  [href] {
    color: $td-blue;
    text-decoration: none;

    @include on-event {
      color: $td-blue;;
      text-decoration: underline;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &:not([href]) {
    color: inherit;

    @include on-event {
      text-decoration: none;
      color: inherit;
    }
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.cursor-pointer {
  &:hover {
    cursor: pointer;
  }
}

.cursor-default {
  &:hover {
    cursor: default;
  }
}

.btn {
  &.btn-sm, &.btn-lg, &.btn-md {
    &.imitate-link {
      padding: 0;
      height: auto;
    }
  }
}


.imitate-link,
.btn.imitate-link {
  color: $td-blue !important;
  font-weight: $font-weight-regular;

  &:hover {
    cursor: pointer;
    text-decoration: underline !important;
  }
}

.horizontal-free-scroll {
  max-width: 100%;
  position: relative;

  &__chevron {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: start;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 52px;

    &.left {
      background: linear-gradient(90deg, $pale-gray-two 0%, transparent 100%);
    }

    &.right {
      background: linear-gradient(90deg, transparent 0%, $pale-gray-two 100%);
      justify-content: end;
      left: auto;
      right: 0;
    }
  }

  &__content {
    overflow: hidden;
    overflow-x: auto;
  }

  &.hide-left .horizontal-free-scroll__chevron.left,
  &.hide-right .horizontal-free-scroll__chevron.right {
    display: none;
  }
}

.horizontal-scroll {
  overflow: hidden;
  overflow-x: auto;
  max-width: 100%;
  background-image:
    /* Shadows */
    linear-gradient(to right, $pale-gray-two, $pale-gray-two),
    linear-gradient(to right, $pale-gray-two, $pale-gray-two),

      /* Shadow covers */
    linear-gradient(to right, rgba(0,0,0,.1), rgba(0,0,0,0)),
    linear-gradient(to left, rgba(0,0,0,.1), rgba(0,0,0,0));

  background-position: left center, right center, left center, right center;
  background-repeat: no-repeat;
  background-color: $pale-gray-two;
  background-size: 15px 100%, 15px 100%, 7px 100%, 7px 100%;

  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;
}

.no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.no-padding-left {
  padding-left: 0 !important;
}

.no-padding-right {
  padding-right: 0 !important;
}

.icon-info {
  cursor: help;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &--100 {
    max-width: 100% !important;
  }
}

.img-radius {
  border-radius: 7px;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;

  &.bullet-list {
    list-style: disc;
    list-style-position: inside;

    li {
      padding-left: 10px;
    }

    &--outside {
      list-style-position: outside;
      padding-left: 20px;

      li {
        padding-left: 0;
      }
    }
  }
}

pre {
  white-space: pre-wrap;
  word-break: break-word;
}

.no-text-select {
  @include no-text-select;
}

.bg-gray {
  background-color: $pale-gray-two;
}

.bg-pale-gray {
  background-color: $graphite-light;
}

.height-auto {
  min-height: auto !important;
}

.info-pill {
  border-radius: 4px;
  padding: 10px 15px;
  color: $greyish-brown;

  &--value {
    float: right;
  }
}

.spreadsheet-like {
  // width should be handled from our js files since it is dynamic
  $title-colors: $pale-gray-three;
  td {
    text-align: left;
    white-space: nowrap;
  }
  thead {
    background-color: $title-colors;
    text-align: center;
    th {
      border: 1px solid lightgray;
      padding: 5px 0;
    }
  }
  tbody {
    tr {
      td {
        border: 1px solid lightgray;
        &:not(:first-child) {
          padding: 10px 8px;
          min-width: 80px;
        }
        &:first-child {
          background-color: $title-colors;
          text-align: center;
          min-width: 30px;
        }
      }
    }
  }
}

.opacity-0 {
  opacity: 0;
}
