/**
 * Settings specific SASS code
 */

.page--settings {
  padding-bottom: 165px;

  hr {
    margin: 15px 0;
  }

  h5 {
    text-transform: none;
    margin-bottom: 20px;
  }

  section + section {
    margin-top: 50px;
  }

  .discreet {
    font-size: 13px;
    font-weight: $font-weight-regular;
    margin: 0 0;
  }

  &__content {
    > .card-footer {
      margin: 0 -15px;
      padding: 15px;
    }

  }

  .btn.position-tweak {
    position: absolute;
    top: 40px;
    right: -10px;
  }

  section > h2 {
    border-bottom: 1px solid #aaa;
    padding-bottom: 15px;
  }

  .icons {
    .icon {
      font-size: 20px;
    }
  }

  .profile-progress{
    padding: 20px;
  }

  .nav-tabs {
    border-bottom: 1px solid #eaeaea;
    li{
      margin-bottom: 0;
    }
  }

  h3 + div:not(.form-group),
  h3 + form,
  form:not([name="search-form"]) {
    padding: 0 10px;
  }

  .user-us-tax-filings-list {
    margin-top: 90px;
  }
}

pre.terms-preview {
  font-family: $text-font-stack;
  font-size: 14px;
  color: $warm-gray;
  background: transparent;
  border: none;
}

hr + .settings-row,
.settings-row .settings-row {
  margin-top: 1.25rem;
}

.settings-row {
  .flex-container {
    &--standalone {
      min-height: 65px;

      &+ .flex-container--standalone {
        margin-top: 10px;
      }
    }

    &--standalone-small {
      min-height: 40px;
    }
  }

  &__content {
    flex: 1;
  }

  &__cta {
    .btn-link {
      color: $td-blue;
      text-transform: none;
      font-weight: $font-weight-regular;
      padding-top: 0;
      padding-bottom: 0;

      &:hover,
      &:active {
        color: $td-blue;
      }
    }

    .form-group {
      margin-bottom: 0;
    }
  }


  .image-upload-row {
    margin-bottom: -15px;

    label {
      padding-top: 15px;
      padding-bottom: 15px;
      margin: 0;
    }

    label + div {
      float: right;
      width: 50%;
      padding: 0 0 0 15px;
    }

    .fileuploader {
      &__text {
        padding: 10px 0;
      }

      &__files {
        text-align: right;
        margin: 0;
      }
    }

    .fileuploader-item {
      width: auto;
      border: none;
      padding: 0;

      &__meta,
      &__download {
        display: none;
      }

      &__remove {
        top: 15px;
        right: 15px;
      }

      &__thumb {
        margin-right: 40px;
      }
    }
  }
}

// Organization settings
.org-summary {
  margin: -5px 0;

  &__item {
    display: flex;
    padding: 7px 0;

    + .org-summary__item {
      border-top: 1px solid $pale-gray-two;
    }

    label {
      margin-bottom: 0;
    }

    span {
      flex-grow: 1;
      text-align: right;
    }
  }
}

.org-form {
  .custom-radio-field {
    display: flex;
    :first-child {
      margin-right: 5px;
    }
    div + div {
      margin-top: 0;
    }
  }
}

.payment-settings__grace-period {
  line-height: 37px;
  margin-bottom: 25px;

  label {
    float: left;
    margin: 0 10px 0 0
  }

  .input-group {
    float: left;
    max-width: 140px;
  }
}

.payment-method-card-item {
  &__logo-container {
    width: 50px;
    text-align: center;

    img {
      max-width: 30px;
      max-height: 30px;
    }

    .fal {
      font-size: 30px;
      color: #3c5a8a;
    }
  }
}

.payoneer-redirect-modal {
  .payoneer {
    &-logo {
      width: 40px;
    }
    &-name {
      font-size: 40px;
      font-weight: 600;
      margin-left: 10px;
    }
  }
  i {
    color: $graphite-dark;
    font-size: 85px;
  }
}

@media (max-width: map-get($grid-breakpoints, sm)) {
  .org-form {
    .custom-radio-field {
      margin-bottom: 10px;
    }

    .btn {
      width: 100%;
    }
  }
}
// Account Settings
.settings-row .rate-row {
  :first-child {
    flex: 2 0 auto;
  }
}

@media (max-width: map-get($grid-breakpoints, sm)) {
  // when bootstrap4 sets responsive width we can remove it
  .settings-row__preview {
    .settings-row__content,
    .settings-row__cta {
      width: 100%;
    }
  }
}

.inline-edit-item {
  .form-group {
    margin-bottom: 0 !important;
  }
}

.template-builder {
  .template-control-selector-wrapper {
    flex-basis: 100px;
  }

  &__new-choice-line {
    display: flex;
    width: 100%;

    input {
      width: 100% !important;
      height: 40px;
      padding-right: 55px;
    }
  }

  &__choice {
    display: flex;
    line-height: 1.4;
    padding: 5px 30px 5px 0;
    background-color: $graphite-light;
  }

  &__choices-list {
    background-color: $pale-gray-two !important;
  }

  &__drag-handle {
    width: 20px;
    font-size: 20px;
    cursor: move;
    color: #999999;
    line-height: 1em;
    vertical-align: middle;
  }

  &__question-remove {
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
    color: $warm-gray-two;

    &:hover,
    &active {
      color: $gray;
    }
  }

  &__controls {
    display: flex;
    flex-basis: 100px;
    flex-grow: 0;
    flex-shrink: 0;
    flex-wrap: wrap;
    flex-direction: column;
    padding-bottom: 50px;

    > button {
      margin: 0 !important;
      background-color: #fff;
      flex: 1;
      flex-shrink: 0;
      text-align: center;
      font-size: 13px;
      color: $warm-gray;
      text-transform: none;
      border-radius: 0;
      box-shadow: inset 0 -1px 0 0 #f0f3f6;
      border: none;
      padding: 10px;
      height: auto;

      &:last-child {
        box-shadow: none;
      }

      span {
        display: block;
        clear: both;
        color: $graphite-light;
        margin: 0 auto 10px !important;
        font-size: 25px !important;
        line-height: 25px !important;
      }

      &:hover,
      &:active {
        background-color: #fcfcfd;
        color: $black;
        box-shadow: inset 0 -1px 0 0 #f0f3f6;

        span {
          color: $td-blue;
        }
      }
    }
  }
}


.interview-builder__question {
  .form-group > label {
    float: none;
  }

  .custom-radio-field {
    display: flex;

    > div {
      margin: 0 !important;

      + div {
        margin-left: 15px !important;
      }
    }
  }

  label.custom-radio {
    display: inline-flex !important;
  }

  .custom-radio__circle {
    margin: 0;
  }

  .add-link {
    right: 17px;
    top: 8px;
    position: absolute;
  }
}

.payment-provder-offline-card {
  padding: 10%;
}

.payment-provider-offline-image {
  @media (max-width: map-get($grid-breakpoints, md)) {
    transform: scale(0.75);
  }

  &__left-image {
    margin-right: 20%;

    @media (max-width: map-get($grid-breakpoints, md)) {
      display: none;
    }
  }

  &__right-image {
    margin-left: 20%;
  }
}

.payment-provider-offline__md-text {
  font-size: 20px;
}

.payment-provider-offline__sm-text {
  font-size: 18px;
}

.wise-banner {
  position: relative;
  overflow: hidden;
  background-color: $dark-green;

  padding: 50px 30px 65px;
  margin-top: 30px;

  &__header {
    margin-bottom: 25px;
  }
  &__header-heading {
    font-size: 26px;
    font-weight: $font-weight-regular;
    color: $light-green;
    line-height: 39px;
  }
  &__heading-logo {
    height: 32px;
  }
  &__cta {
    margin-bottom: 40px;
  }
  &:before {
    position: absolute;
    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    width: 350px;
    left: 80px;
    top: 80px;
    padding: 5px;

    // \A is a newline character for content in CSS
    content: "Preferred \A Payments \A Partner";
    background-color: #FF595A;
    text-align: center;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
  }
  &__benefit {
    background-color: $white;
    border-radius: 5px;
    padding: 25px;

    i {
      font-size: 48px;
    }

    .fa-bolt {
      color: $light-green;
    }

    .fa-duotone.fa-money-bill-1 {
      &:before {
        color: $dark-green;
      }

      &:after {
        color: $light-green;
        opacity: 1;
      }
    }

    .fa-duotone.fa-arrow-right-arrow-left {
      &:before {
        color: $dark-green;
      }

      &:after {
        color: $light-green;
        opacity: 1;
      }
    }
  }

  &__benefit-icon-container {
    margin-top: 20px;
    margin-bottom: 12px;
    position: relative;
    .fa-solid.fa-slash-forward {
      position: absolute;
      color: $red;
      left: 50%;
      transform: translate(-50%);
      font-weight: $font-weight-bold;
    }
  }

  @media (max-width: map-get($grid-breakpoints, sm)) {
    &:before {
      top: 40px;
      left: 40px;
      height: 50px;

      line-height: 16px;
      font-size: 12px;
      white-space: pre;

      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__header-heading {
      font-size: 22px;
    }
    &__heading-logo {
      height: 24px;
    }
  }
}

.page.page--settings.email-templates {
  a {
    color: $black;
    text-decoration: none;

    .icon-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;

      .fa-clipboard-list-check {
        font-size: 2.6em;
      }

      .fa-duotone {
        --fa-secondary-color: #{$pending};
        --fa-secondary-opacity: 1;
      }

      .fa-bars-progress, .fa-list-check {
        --fa-secondary-color: #{$tomato-red};
      }
    }
  }
}

.page.page--settings.email-template {
  .info-row {
    border-bottom: 1px solid $pale-gray;
  }

  .form {
    width: calc(66.66% - 2rem);

    .toolbar {
      border-top: 1px solid $pale-gray;

      .body-type-toggle button {
        background-color: $graphite-light;
        color: $black;

        &:first-of-type {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        &:last-of-type {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }

        &.active {
          background-color: $graphite-dark;
          color: $white;
        }
      }
    }

    .body-text-area textarea.form-control {
      height: 400px;
      min-height: 400px;
    }

    @media (max-width: map-get($grid-breakpoints, sm)) {
      width: 100%;
    }
  }

  .tags {
    width: calc(33.33%);

    .btn {
      font-size: 12px;
      font-weight: normal;
    }
  }
}

.email-template-preview-iframe {
  height: calc(100vh - 300px);
}
