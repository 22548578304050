@import '~react-redux-toastr/src/styles/index';
.redux-toastr .toastr .rrt-middle-container {
  font-family: $text-font-stack;
}
.redux-toastr .top-right {
  width: 550px;
  max-width: 100%;
}


.redux-toastr {
  .toastr {
    background-color: #fff !important;
    opacity: 1 !important;
    min-height: 66px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);

    &:hover:not(.rrt-message) {
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
    }

    .rrt-left-container {
      width: 50px;
      height: 100%;
      position: absolute;
      top: 0;
      margin-top: 0;
      left: 0;
      line-height: 65px;
      text-align: center;

      .rrt-holder {
        width: 100%;
        position: relative;
        left: 0;
        line-height: 73px;

        svg {
          display: none;
        }

        &:before {
          font-family: '#{$fa-style-family}';
          speak: none;
          font-style: normal;
          font-weight: $font-weight-regular;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          vertical-align: middle;
          display: inline-block;
          font-size: 24px;
          margin-top: -3px;
        }
      }
    }

    .rrt-middle-container {
      margin-left: 50px;
      padding: 15px;
      width: 80%;

      .rrt-title {
        font-size: 14px;
        margin-bottom: 0;
        font-weight: $font-weight-semibold;
      }

      .rrt-text {
        font-size: 14px;
        color: $greyish-brown;
        font-weight: $font-weight-regular;
      }
    }

    .close-toastr {
      color: $warm-gray;
      font-size: 14px;
      font-weight: $font-weight-semibold;

      &:hover {
        color: $greyish-brown;
      }
    }
  }

  .toastr.rrt-success {
    border: 1px solid $medium-green;
    background-color: $success-bg !important;

    .rrt-left-container {
      .rrt-holder {
        color: $medium-green;
        &:before{
          content: fa-content($fa-var-check-circle);
        }
      }
    }
    .rrt-title {
      font-weight: $font-weight-semibold;
      color: $medium-green;
    }
  }

  .toastr.rrt-warning {
    border: 1px solid $pending;
    background-color: $pending-bg !important;

    .rrt-left-container {
      .rrt-holder {
        color: $pending;
        &:before {
          content: fa-content($fa-var-exclamation-triangle);
        }
      }
    }
    .rrt-title {
      color: $pending;
    }
  }

  .toastr.rrt-error {
    border: 1px solid $tomato-red;
    background-color: $error-bg !important;

    .rrt-left-container {
      .rrt-holder {
        color: $tomato-red;
        &:before {
          content: fa-content($fa-var-times-circle);
        }
      }
    }
    .rrt-title {
      color: $tomato-red;
    }
  }

  .toastr.rrt-info {
    border: 1px solid $lightblue-two;
    background-color: $info-bg !important;

    .rrt-left-container {
      .rrt-holder {
        color: $td-blue;
        &:before {
          content: fa-content($fa-var-info-circle);
        }
      }
    }
    .rrt-title {
      color: $lightblue-two;
    }
  }

  .rrt-middle-container {
    color: $greyish-brown;
  }
}
