.card {
    &--signup-verify {
        padding: 30px 0;

        &__icon {
            color: $graphite-dark;
            font-size: 72px;
        }
    }
}
