.worksheet-item-card {
  &__title {
    font-size: 18px;
    font-weight: $font-weight-regular;
  }

  &__meta {
    font-size: 13px;
    color: $greyish-brown;

    > div {
      margin-right: 20px;
    }
  }

  &__reason {
    background-color: $warning-bg;
    padding: 10px 15px;
    margin: 5px -35px -15px -15px;
  }
}
