.timeline {
  position: relative;

  &--scrolled {
    max-height: 200px;
    max-height: 35vh;
    overflow-y: auto;
  }
}

.timeline-line-wrapper {
  position: relative;
  margin-left: 3px;

  &:before {
    content: "";
    width: 3px;
    height: 100%;
    position: absolute;
    background: $graphite-light;
    left: 0;
    top: 0;
  }
}

.timeline-item {
  position: relative;
  margin-left: 15px;
  border-radius: 0 $borderRadius $borderRadius;

  &:after,
  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
  }

  &:after {
    top: 1px;
    left: -6px;
    border-color: transparent #fff transparent transparent;
    border-width: 0 8px 8px 0;
  }

  &:before {
    top: 0;
    left: -8px;
    border-color: transparent $graphite-light transparent transparent;
    border-width: 0 8px 8px 0;
  }

  & + .timeline-item {
    margin-top: 10px;
  }

  &__header {
    font-size: 14px;
    font-weight: $font-weight-semibold;
    color: $black;
    line-height: 17px;
    padding: 10px;
    border-width: 1px 1px 0 1px;
    border-style: solid;
    border-color: $graphite-light;
    border-radius: 0 $borderRadius 0 0;

    &:before {
      box-sizing: content-box;
      content: "";
      width: 7px;
      height: 7px;
      border-radius: 7px;
      border: 2px solid #fff;
      position: absolute;
      background: $graphite-light;
      left: -19px;
      top: -4px;
    }

    time {
      float: right;
      font-size: 12px;
      font-weight: $font-weight-semibold;
      line-height: 17px;
      color: $warm-gray;
    }
  }

  &__body {
    padding: 10px;
    font-size: 14px;
    border-width: 0 1px;
    border-style: solid;
    border-color: $graphite-light;


    p {
      font-weight: 300;

      strong {
        font-weight: $font-weight-semibold;
      }
    }
  }

  &__author {
    font-weight: $font-weight-semibold;
    margin-bottom: 5px;
  }

  &__footer {
    border-radius: 0 0 $borderRadius $borderRadius;
    padding: 7px 0 10px;
    line-height: 14px;
    margin: 0 10px;
    border: 1px solid $graphite-light;

    .status {
      font-size: 12px;
      font-weight: $font-weight-semibold;
      text-transform: uppercase;
    }

    &--approved {
      background-color: $medium-green;
      border: 1px solid $medium-green;
      .status {
        color: $white;
      }
    }
    &--pending {
      background-color: $pending;
      border: 1px solid $pending;
      .status {
        color: $white;
      }
    }
    &--rejected {
      background-color: $tomato-red;
      border: 1px solid $tomato-red;
      .status {
        color: $white;
      }
    }

    &--approved,
    &--pending,
    &--rejected {
      color: $white;
    }

  }

  &--default {
    background-color: $white;
  }

  &--approved {
    background-color: rgba($medium-green, 0.1);

    &:after {
      border-color: transparent rgba($medium-green, 0.1) transparent transparent;
    }
  }

  &--rejected {
    background-color: rgba($tomato-red, 0.1);

    &:after {
      border-color: transparent rgba($tomato-red, 0.1) transparent transparent;
    }
  }

  &--pending {
    background-color: rgba($pending, 0.1);

    &:after {
      border-color: transparent rgba($pending, 0.1) transparent transparent;
    }
  }

  &--emphasize {
    background-color: $pale-gray-two;

    &:after {
      border-color: transparent rgba($pale-gray-two, 0.1) transparent transparent;
    }
  }

  &--emphasize,
  &--default,
  &--approved,
  &--rejected,
  &--pending  {
    &__header {
      border-bottom: 1px solid $pale-gray-two;
    }
  }
}
