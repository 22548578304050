.dropdown-menu.notifications-drop-down {
  width: 330px;

  &:after,
  &:before {
    display: none;
  }

  .empty-list-message {
    a {
      background: transparent;

      &:hover {
        color: $td-blue;
      }
    }
  }
}

.dropdown-menu a.notifications-drop-down__see-all {
  font-weight: $font-weight-bolder;
  color: $greyish-brown;
  background-color: #fff;
  text-align: center;
  padding: 5px 10px;
  display: block;

  &:hover {
    background: #fff;
    color: $td-blue;
  }
}

.td-dropdown__notifications {
  position: relative;

  &--has-new {
    i.fa-bell {
      position: relative;

      &:after {
        content: '';
        display: block;
        position: absolute;
        background-color: $tomato-red;
        width: 8px;
        height: 8px;
        border-radius: 6px;
        top: 0;
        right: 8px;
        border: solid 1px $athens-gray;
        z-index: 5;
      }
    }
  }
}

.notifications-drop-down {
  &__list {
    display: block;
    max-height: 400px;
    overflow-y: auto;
  }

  &__list-item {
    display: flex;
    width: 100%;
    padding: 15px;
    white-space: normal;
    color: #303030;
    box-shadow: inset 0 1px $pale-gray;
    position: relative;
    background-color: #f7f8f9;

    &:hover {
      background-color: $pale-gray;
    }

    i {
      font-size: 16px;
      flex: 16px 0 0;
      margin-top: 3px;
      margin-right: 15px;
    }

    strong {
      color: $black;
    }

    .mark-as-read {
      float: right;

      .fal,
      .fas {
        font-size: 10px;
        margin-right: 2px;
      }

      &:hover,
      &:active,
      &:focus {
        text-decoration: none;

        span {
          text-decoration: underline;
        }
      }
    }

    &.seen {
      background-color: $white;
      color: $greyish-brown;

      .notification-icon {
        color: $warm-gray;
      }

      .mark-as-read {
        display: none;
      }
    }

    strong {
      font-weight: $font-weight-regular;
    }
  }

  &__meta {
    font-size: 11px;
    line-height: 1;
    margin-top: 5px;
    float: none;
    color: $warm-gray;
  }
}

.page--notifier__content {
  p {
    font-weight: $font-weight-regular;
    float: left;
    width: 265px;
    margin: 0;
    padding: 0;
    line-height: 17px;
  }
}

.notifications-list {
  &-item {
    padding: 10px 10px 11px 46px;
    position: relative;
    background: $pale-gray-two;
    box-shadow: 0 -1px 0px 0 rgba(0, 0, 0, 0.1) inset;
    color: $black;
    cursor: pointer;
    font-weight: $font-weight-regular;
    min-height: 65px;
    padding-top: 15px;
    padding-bottom: 15px;

    strong {
      color: $black;
    }

    &:first-child {
      //box-shadow: none;
    }

    .mark-as-read {
      float: right;

      .fal,
      .fas {
        font-size: 10px;
        margin-right: 2px;
      }

      &:hover,
      &:active,
      &:focus {
        text-decoration: none;

        span {
          text-decoration: underline;
        }
      }
    }

    &.seen {
      background-color: $white;
      color: $greyish-brown;

      .notification-icon {
        color: $warm-gray;
      }

      .mark-as-read {
        display: none;
      }
    }

    strong {
      font-weight: $font-weight-regular;
    }

    &__meta {
      font-size: 11px;
      line-height: 1;
      margin-top: 5px;
      float: none;
      color: $warm-gray;
    }
  }

  .notification-icon {
    color: $black;
    font-size: 16px;
    margin-right: 0;
    position: absolute;
    top: 19px;
    left: 15px;

  }
}
