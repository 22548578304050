.selectable-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: 190px;
  overflow-y: auto;

  li {
    flex-basis: 100%;
    padding: 5px 0;
    display: flex;
    align-items: center;

    .custom-checkbox {
      margin-right: 10px;
      position: relative;
    }

    .custom-switch {
      top: 0;
    }

    &:hover {
      cursor: pointer;

      .custom-checkbox {
        border-color: $td-blue;
      }
    }
  }

  &--multiple {
    li {
      flex-basis: 30%;
    }
  }
  &__additional-content + * {
    margin-left: 15px;
  }
  &__toggle-button {
    flex: 0.5;
    padding-left: 10px;
    padding-right: 10px;
    width: 40px;
    transition: 0.2s;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .fa-tasks {
      padding: 0 !important;
    }
    .fa-check {
      font-size: 10px;
      vertical-align: baseline;
    }
    &:not(.selection-enabled) {
      .custom-checkbox {
        opacity: 0;
      }
    }
    &.selection-enabled {
      flex: 0 0 48px;
      height: 42px;
      justify-content: center;
      .custom-checkbox {
        margin: 0;
      }
    }
    .custom-checkbox {
      position: relative;
    }

    &.btn--active {
      width: 100%;
      transition: 0.2s;
      .custom-checkbox {
        opacity: 1;
        transition: 0.5s;
          &:before {
            content: "";
            display: block!important;
            width: 1px;
            height: 20px;
            background-color: $warm-gray;
            opacity: 0.3;
            position: absolute;
            left: -11px;
            top: -4px;
          }
      }
    }
  }
}

@keyframes appear {
  0% {
    flex: 0;
  }
  100% {
    flex: 1;
  }
}

.search-filter-item {
  .filter-input {
    font-size: 13px;
    padding-bottom: 5px;
    background: none;
    border: none;
    border-bottom: 1px solid #aeaeae;
    margin: 5px 0 15px;

    &:focus {
      outline: none;
      border-bottom: 1px solid $td-blue;
    }
  }
}
