.worksheet-item,
.expense-item {
  color: $greyish-brown;

  .profile-pic{
    margin-right: 10px;
  }

  &__title{
    color: $black;
    font-size: 16px;
    line-height: 20px;
    margin-top: 3px;
    display: block;

    a{
      color: $black;
    }
  }

  &__meta{
    color: $greyish-brown;
    font-size: 12px;

    > i{
      display: inline-block;
      margin-left: 20px;

      &:first-child{
        margin-left: 0;
      }
    }

    .fal, .fas {
      color: $warm-gray;
    }

    > span{
      display: inline-block;
      margin-right: 10px;
    }

  }

  &__amount{
    font-size: 14px;
    color: $greyish-brown;
  }

  &__amount-description{
    font-size: 14px;
    color: $black;
  }

  .action-buttons{
    text-align: right;

    a{
      color: $warm-gray;
      cursor: pointer;
      line-height: 34px;
      font-size: 18px;
      margin-right: 7px;

      &:hover,
      &:active,
      &:focus{
        color: $black;
        text-decoration: none;
      }

      &+ a{
        margin-left: 22px;
      }
    }
  }
}

tr.worksheet-items-error-row {
  td {
    padding-top: 0;
    padding-bottom: 5px;

    .text-danger {
      position: relative;
      top: -5px;
    }
  }
}

.worksheet-item {
  & + .worksheet-items-table__header th {
    border-top: 10px solid $white !important;
  }

  @media (max-width: map-get($grid-breakpoints, md)) {
    td {
      border-top: 10px solid $white !important;
    }
  }

  .remove-icon {
    font-size: 16px;
    color: $warm-gray;

    &:hover,
    &:active {
      color: $black;
      cursor: pointer;
    }
  }

  @media (max-width: map-get($grid-breakpoints, sm)) {
    .remove-icon {
      font-size: 16px;
      color: $warm-gray;
    }
  }
}
