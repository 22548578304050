.invoice-breakdown-worksheet-card {
  &__expand-title {
    font-weight: $font-weight-semibold;
  }

  &__expandable-content {
    background-color: $lightblue;
  }

  &__label {
    color: $warm-gray;
    font-weight: $font-weight-semibold;
  }

  &__label,
  &__value {
    line-height: 1.5em;
  }

  .card-footer {
    min-height: 32px;
  }

  @media (min-width: map-get($grid-breakpoints, lg)) {
    &__label {
      flex: 110px 0 0;
    }
  }
}
