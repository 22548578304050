.page--billing {
  .card {
    .sub-heading {
      color: $warm-gray;
      font-weight: 600;
    }
  }
  .billing-widget {
    height: 100%;
  }
}
