.rich-text-area [class^=RichTextEditor__root] {
  border: 0;
  display: flex;
  font-family: $text-font-stack;
  flex-direction: column;

  [class^=EditorToolbar__root] {
    background-color: $pale-gray;
    border-bottom: 1px solid $graphite-light;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 1rem;

    [class^=ButtonGroup] {
      margin-bottom: 0;

      button {
        background: none;
        background-color: $pale-gray;
        border: 0;

        &[class*=isActive] {
          background-color: $graphite-light;
        }
      }
    }
  }

  [class^=RichTextEditor__editor] {
    background-color: $pale-gray;
    flex: 1;
    overflow: auto;
    padding: 3rem;

    .DraftEditor-root {
      background-color: $white;
      border-radius: 4px;
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
      display: flex;
      height: auto;
      min-height: 100%;
      padding: 3rem;
    }

    .DraftEditor-editorContainer,
    .DraftEditor-editorContainer div {
      height: auto;
      min-height: 100%;
      width: 100%;
    }
  }
}
