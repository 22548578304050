.checkable-list__container li + li {
  margin-top: 10px
}

.checkable-list {

  &__option {
    border: 1px solid $graphite-light;
    border-radius: $borderRadius;
    font-size: 13px;
    transition: border .2s, background .2s;
    background-color: $white;
    overflow: hidden;

    > a {
      display: flex;
      width: 100%;

      // Disable clicks inside the list item component.
      > div {
        pointer-events: none;
      }
    }


    .checkable-list__indicator {
      visibility: hidden;
      opacity: 0;
      color: $medium-green;
      transition: opacity .2s;
    }

    &:hover {
      border-color: transparentize($gray, 0.8);

      .checkable-list__indicator {
        visibility: visible;
        opacity: 1;
        color: transparentize($gray, 0.6);
      }
    }

    &--checked,
    &--checked:hover {
      background-color: $medium-green-pastel;
      border-color: $medium-green-pastel;

      .checkable-list__indicator {
        color: $medium-green;
        visibility: visible;
        opacity: 1;
      }
    }

    &--initially-selected,
    &--initially-selected:hover {
      border-color: transparentize($medium-green, 0.85);

      &.checkable-list__option--checked {
        background-color: $medium-green-pastel;
        border-color: $medium-green-pastel !important;

        .checkable-list__indicator {
          visibility: visible;
          opacity: 1;
          color: $medium-green;
        }
      }
    }


    h3 {
      line-height: 1;
      margin-bottom: 5px;
    }

    &--disabled {
      img {
        opacity: 0.6;
        filter: grayscale(100%);
      }
      &:hover {
        border-color: $graphite-light;

        a {
          cursor: default;
        }

        .checkable-list__indicator {
          display: none;
        }
      }

      .payment-method-item-card,
      .worksheet-item-card {
        &__title {
          color: $warm-gray;
        }
      }
    }
  }

  &__item {
    flex: 1;
  }

  &__indicator {
    min-width: 1em;
    display: flex;
    align-items: center;
    font-size: 16px;
    padding-right: 5px;
  }
}
