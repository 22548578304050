.page--classification-questionnaire {
  .fa-lightbulb-on {
    --fa-primary-color: #{$black};
    --fa-secondary-color: #{$pending};
    --fa-secondary-opacity: 1;
  }

  .fa-check-circle {
    color: $medium-green;
  }

  .summary {
    border-radius: 4px;

    &.low {
      background-color: fade-out($medium-green, 0.7);
    }

    &.moderate {
      background-color: fade-out($pending, 0.7);
    }

    &.high {
      background-color: fade-out($tomato-red, 0.7);
    }
  }
}

.page--classification-questionnaires, .page--classification-questionnaire {
  td.low {
    background-color: $classification-risk-low;
  }

  td.moderate {
    background-color: $classification-risk-moderate;
  }

  td.high {
    background-color: $classification-risk-high;
  }
}

.page--classification-questionnaire {
  tr:first-of-type, tr:nth-of-type(2) {
    border-bottom: 1px solid $black;
  }
}