// Project tasks
.task-checklist {
  &__item {
    &:hover {
      background: $pale-gray-four;
    }

    .input-group-prepend {
      .input-group-text {
        border: none;
        background: inherit;
        align-items: center;
        margin-right: 5px;
      }
    }

    &__description {
      flex: 1;
      flex-basis: 100%;
    }

    &--completed {
      .editable-value {
        &--viewing {
          font-style: italic;
          text-decoration: line-through !important;
          color: $warm-gray !important;
        }
      }

      .td-drop-button__toggle {
        opacity: 0.5;
      }
    }

    &__description {
      flex: 1;
      flex-basis: 100%;
    }

    .editable-value {
      &__container {
        flex: 1;
      }

      &--disabled {
        pointer-events: none;
      }

      &--viewing {
        font-size: 14px;
        text-align: left;
        border: 1px solid transparent;
        background: inherit;
        display: block;
        white-space: normal;

        &:hover {
          border: 1px dashed $graphite-light;
          text-decoration: none;
        }
      }

      &--editing {
        font-size: 14px;
      }
    }
  }
  li + &__add-items {
    margin-top: 10px;
  }
}

.task-assignment,
.task-checklist {
  .btn-link {
    &.text-primary {
      &:hover, &:focus, &:active {
        color: $nice-blue !important;
      }
    }

    &.text-secondary {
      &:hover, &:focus, &:active {
        color: $gray !important;
      }
    }
  }
}

.task-rate-suggestion {
  .form-group {
    margin-bottom: 0;
  }
}

.task-assignment {
  .user-item {
    margin: -5px -10px;

    &__content {
      flex-wrap: wrap !important;
      justify-content: space-between;
    }

    &__rate {
      flex-basis: 30%;
      max-width: 30%;
      display: flex;
      justify-content: flex-end;
    }

    &__skills {
      padding: 10px 0 0 0 !important;
      order: 99;
      flex: 1 0 100%;
      max-width: 100% !important;
    }
  }
}

.task-card {
  &__details {
    font-size: 12px;
    color: $warm-gray;
  }

  &__assignments {
    min-height: 36px;

    span + span {
      margin-left: -5px;
    }
  }

  &__status {
    flex: 1;
    font-weight: $font-weight-semibold;
  }

  &__basic-info {
    flex: 1;
    font-size: 13px;
  }

  &__title {
    flex: 1;
    font-size: 15px;
  }

  &--disabled {
    opacity: 0.5;
  }

  .profile-pic {
    border: 2px solid #fff;
  }

  .profile-pic + .profile-pic,
  .profile-pic-wrapper + .profile-pic-wrapper {
    margin-left: -14px;
  }
}

.task-rate-billing-info {
  font-size: 12px;
  color: $warm-gray;
  line-height: 15px;
  position: relative;
  top: 2px;
  left: 15px;
  padding-left: 5px;

  &::before {
    content: '';
    width: 4px;
    height: 4px;
    background-color: $warm-gray;
    border-radius: 50%;
    position: absolute;
    left: -5px;
    top: 5px;
  }
}

.task-progress-bar {
  &.td-progress-bar__wrapper {
    overflow: visible;
    position: relative;
    border-bottom: 1px solid $graphite-light;
  }

  &--thick {
    &.td-progress-bar__wrapper {
      height: 6px;
      border-radius: 6px;
      overflow: hidden;
      border-bottom: none;
      background: $graphite-light;
    }

    .td-progress-bar {
      bottom: auto;
      top: 0px;
      height: 6px;

      .indicator {
        top: 0;
        bottom: auto;
      }
    }
  }

  &__status-label {
    color: $warm-gray;
    font-size: 12px;
  }
}

.deliverable-card {
  .fileuploader-item {
    padding: 0;
    margin: 0;
  }
}

.task-progress-update {
  h3 {
    flex: 1;
  }
}

.task-deliverable-status {
  &.fas {
    color: $pending;
  }
}

.task-modal-description {
  word-break: break-word;
}

.task-modal-started,
.task-modal-completion-request {
  ul {
    list-style: initial;
  }
}

.task-assignment-card {
  list-style-type: none;

  + .task-assignment-card {
    border-top: 1px solid $graphite-light;
  }

  &__user-name,
  &__user-info {
    flex: 1;
  }

  .task-participant--with-status {
    a:before {
      top: -8px;
    }
  }
}

.task-deliverables__list {
  &.selectable-list-wrapper--with-items {
    .td-list {
      background-color: #fff;
      padding: 20px;
      border-radius: 4px;
      box-shadow: $shadow-sm;
    }
  }

  .selectable-item {
    .task-attachment-card {
      margin-bottom: 0;
    }

    .pin-status {
      pointer-events: none;
    }
  }
}

.project-task__attachments {
  .task-attachment-card {
    &:nth-child(odd) {
      margin-right: 15px;
    }
  }

  .task-attachments__container {
    flex-wrap: wrap;
  }

  @media (max-width: map-get($grid-breakpoints, lg)) {
    .task-attachment-card {
      flex-basis: 100%;

      &:nth-child(odd) {
        margin-right: 0;
      }
    }
  }
}

.task-attachment-card {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(50% - 7.5px);

  &__details {
    flex: 1;
  }

  &__meta {
    color: $warm-gray;
    font-size: 12px;
  }

  &--deliverable {
    border-left: 4px solid $medium-green;
    padding-left: 10px;
  }
}

.task-participant {
  &--with-pending-actions {
    position: relative;

    .profile-pic {
      position: relative;
      overflow: visible;

      &:before {
        content: ' ';
        width: 12px;
        height: 12px;
        display: inline-block;
        position: absolute;
        right: -2px;
        top: -2px;
        border-radius: 50%;
        border: 2px solid #fff;
        background-color: $curious-blue;
        z-index: 5;
      }
    }
  }

  &--with-status {
    a {
      position: relative;

      .profile-pic {
        width: 34px;
        height: 34px;
      }

      &:before {
        content: '';
        width: 36px;
        height: 36px;
        display: inline-block;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 50%;
      }
    }
  }

  &--pending {
    a {
      &:before {
        box-shadow: 0 0 0 2px $pale-gray-two, 0 0 0 3px $pending;
      }
    }
  }

  &--removed {
    a {
      &:before {
        box-shadow: 0 0 0 2px $pale-gray-two, 0 0 0 3px $tomato-red;
      }
    }
  }

  &--inactive {
    a {
      &:before {
        box-shadow: 0 0 0 2px $pale-gray-two, 0 0 0 3px $warm-gray;
      }
    }
  }

  &--completed {
    a {
      &:before {
        box-shadow: 0 0 0 2px $pale-gray-two, 0 0 0 3px $medium-green;
      }
    }
  }
}

#task-assignment-form {
  textarea.form-control {
    height: 7.5rem
  }
}

.task-assignees-table, .task-managerstable {
  .react-bs-container-body,
  .td-table {
    /* So split button menu is always visible */
    min-height: 300px;
  }
}

.task-assignees-grid-view, .task-managers-grid-view {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(3, minmax(0, 1fr));

  @media (max-width: map-get($grid-breakpoints, xl)) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (max-width:  map-get($grid-breakpoints, md)) {
    grid-template-columns: 100%;
  }

  .team-grid-list-item__container a {
    color: $black;
    text-decoration: none;
  }
}

.task-assignment-type {
  .members-preview {
    justify-content: flex-start !important;
    margin: 10px 0 20px;
  }

  p {
    margin: 0;
  }
}
