.status-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border: 2px solid $warm-gray;
  border-radius: 10px;
  margin: 0 7px;
  vertical-align: middle;

  &--circle {
    background-color: transparent !important;
  }

  &.status-dot--no-fill {
    width: 14px;
    height: 14px;
  }
  &--info,
  &--contractor,
  &--posted {
    background-color: $lightblue-two;
    border-color: $lightblue-two;
    &.status-dot--no-fill {
      background-color: transparent;
    }
  }

  &--danger,
  &--removed,
  &--cancelled,
  &--rejected,
  &--deleted,
  &--stopped {
    background-color: $tomato-red;
    border-color: $tomato-red;
  }

  &--warning,
  &--in-progress,
  &--budget-requested,
  &--cashout-requested,
  &--waiting-client-payment,
  &--deferred-payment,
  &--raised,
  &--pending,
  &--amendment-requested,
  &--pending-approval,
  &--pending-review,
  &--pending-signature {
    background-color: $pending;
    border-color: $pending;
    &.status-dot--no-fill {
      background-color: transparent;
    }
  }

  &--employee,
  &--success,
  &--approved,
  &--invoiced,
  &--completed,
  &--accepted,
  &--paid,
  &--executed,
  &--budget-approved,
  &--active {
    background-color: $medium-green;
    border-color: $medium-green;
    &.status-dot--no-fill {
      background-color: transparent;
    }
  }

  &--approved-auto {
    border-color: $medium-green;
    background-color: $white;
    &.status-dot--no-fill {
      background-color: transparent;
    }
  }

  &--expired,
  &--draft,
  &--preview,
  &--cancelled {
    background-color: $warm-gray;
    border-color: $warm-gray;
    &.status-dot--no-fill {
      background-color: transparent;
    }
  }

  &--permanent-employee {
    border-color: $lightblue-two;
  }

  &--pie-chart-managers {
    &-approved {
      border-color: $medium-green;
    }
    &-raised {
      border-color: #9CDDA6;
    }
  }
  &--pie-chart-providers {
    &-approved {
      border-color: $td-blue;
    }
    &-raised {
      border-color: #A8C1E2;
    }
  }
}

.status {
  font-weight: $font-weight-semibold;
  display: inline-flex;
  align-items: center;

  .status-dot {
    margin-left: 0;
    flex-grow: 0;
    flex-shrink: 0;
  }

  &--delayed {
    background-color: $success-bg-transparent;
    &::before {
      @extend .fa;
      width: 15px;
      height: 14px;
      content: '\f017';
      font-size: 12px;
      font-weight: 500;
      line-height: 14px;
      padding-right: 16px;
    }
    .status-dot {
      display: none;
    }
  }

  &--draft {
    background-color: $success-bg-transparent;
    &::before {
      @extend .fa;
      width: 15px;
      height: 14px;
      content: '\f042';
      font-size: 12px;
      font-weight: 600;
      line-height: 14px;
      padding-right: 16px;
    }
    .status-dot {
      display: none;
    }
  }


  &--employee,
  &--approved,
  &--approved-auto,
  &--invoiced,
  &--delayed,
  &--completed,
  &--paid,
  &--executed,
  &--accepted,
  &--active {
    color: $medium-green;
  }

  &--info,
  &--contractor,
  &--posted {
    color: $lightblue-two;
  }

  &--in-progress,
  &--budget-requested,
  &--cashout-requested,
  &--waiting-client-payment,
  &--deferred-payment,
  &--amendment-requested,
  &--raised,
  &--pending,
  &--pending-approval,
  &--pending-review,
  &--pending-signature {
    color: $pending;
  }

  &--declined,
  &--rejected,
  &--removed,
  &--cancelled,
  &--deleted,
  &--stopped {
    color: $tomato-red;
  }

  &--expired,
  &--void,
  &--draft,
  &--preview,
  &--cancelled {
    color: $warm-gray;
  }
}

.status--tag {
  display: inline-flex;
  align-items: center;
  padding: 8px 10px;
  font-size: 13px;
  font-weight: $font-weight-regular;
  line-height: 13px;
  border-radius: 32px;

  .status-dot {
    margin-left: 0;
    flex-grow: 0;
    flex-shrink: 0;
  }

  &.status--default {
    background-color: $graphite-light;
  }

  &.status--light {
    background-color: $pale-gray;
  }

  &.status--approved,
  &.status--approved-auto,
  &.status--invoiced,
  &.status--completed,
  &.status--paid,
  &.status--executed,
  &.status--accepted,
  &.status--active {
    background-color: $success-bg-transparent;
  }

  &.status--posted {
    background-color: $info-bg-transparent;
  }

  &.status--removed {
    background-color: $error-bg-transparent;
  }

  &.status--in-progress,
  &.status--budget-requested,
  &.status--raised,
  &.status--waiting-client-payment,
  &.status--cashout-requested,
  &.status--deferred-payment,
  &.status--amendment-requested,
  &.status--pending,
  &.status--pending-approval,
  &.status--pending-review,
  &.status--pending-signature {
    background-color: $pending-bg-transparent;
  }

  &.status--cancelled,
  &.status--deactivated,
  &.status--draft,
  &.status--expired,
  &.status--preview,
  &.status--void {
    background-color: $graphite-light;
  }

  &.status--declined,
  &.status--rejected,
  &.status--deleted,
  &.status--stopped {
    background-color: $error-bg-transparent;
  }
}

.onboarding-status {
  position: relative;
  bottom: -1px;
  text-align: right;
  margin-bottom: -30px;
  line-height: 30px;

  &--center-aligned {
    text-align: center;
  }

  > .status {
    display: inline-flex;
    align-items: center;
    padding-left: 10px;
    background-color: #fff;
    position: relative;
    top: -1px;
    z-index: 1;
  }

  &--with-background-stripe {
    &:before {
      display: block;
      content: "";
      width: 100%;
      height: 1px;
      left: 0;
      bottom: -10px;
      top: calc(50% - 1px);
      position: absolute;
    }
  }

  &--pending {
    &:before {
      background-color: $pending;
    }
  }

  &--expired,
  &--cancelled {
    &:before {
      background-color: $warm-gray;
    }
  }

  &--removed,
  &--rejected {
    &:before {
      background-color: $tomato-red;
    }
  }
}
