.chat {

  &__navbar {
    .badge {
      background-color: $td-blue;
      color: $white;
      font-weight: $font-weight-semibold;
      min-width: 15px;
      height: 20px;
      padding: 0 3px;
      border-radius: 50%;
      font-size: 11px;
      line-height: 20px;
      border: none;
      position: absolute;
      z-index: 2;
      top: 50%;
      transform: translateY(-50%);
      right: 1rem;
    }

    .navbar__menuItem,
    .navbar__sectionItem {
      border-radius: 0;
    }

    .navbar__sectionItem {
      font-size: 16px;

      i {
        font-size: 40px;
      }
    }
  }

  &__page {
    background-color: $pale-gray-two;
  }

  &__box {
    height: 75vh;

    small {
      color: $warm-gray;
    }

    &-body {
      height: calc(100% - 126px);
      flex-direction: column-reverse;

      @media (max-width: map-get($grid-breakpoints, sm)) {
        height: calc(100% - 160px);
      }

      @media (max-width: map-get($grid-breakpoints, xs)) {
        height: calc(100% - 182px);
      }
    }

    &-caption {
      position: relative;

      &:after {
        display: block;
        position: absolute;
        top: calc(50% - 1px);
        content: '';
        height: 1px;
        width: 100%;
        background-color: $pale-gray;
      }

      > div {
        position: relative;
        display: inline-block;
        z-index: 1;
        padding: 0 10px;
        margin: 0 30px;
        background-color: #fff;
      }
    }

    &-message {
      background: $graphite-light;
      border-radius: 4px;
    }
  }

  &__footer {
    background-color: $white !important;
    border-top: 1px solid $pale-gray !important;
  }

  &__logo {
    &-container {
      background: $pale-gray;
    }
  }

  &__header {
    &-label {
      color: $greyish-brown;

      &-muted {
        color: $warm-gray;
      }
    }
  }

  &__box-message {
    width: fit-content;
  }
}

.chat__box-message {
  max-width: 80%;
}

.chat-modal {
  color: $black;

  &__title, h1 {
    font-size: 26px;
    line-height: 1.5;
  }

  &__sub, h3 {
    font-size: 18px;
    line-height: 1.5;
    background: transparent;
    margin-bottom: 4rem;
    width: 75%;
  }

  &__semibold, h4 {
    font-weight: $font-weight-semibold;
    font-size: 16px;
    line-height: 1.5;
  }

  &__arrow {
    font-size: 24px;
    color: $graphite-light;
  }

  &__close {
    color: $warm-gray;
    cursor: pointer;
    position: absolute;
    top: 1rem;
    right: 1.5rem;
    font-size: 18px;
  }

  &__icon {
    font-size: 24px;
    color: white;
    border-radius: 50%;
  }

  .fa-comment {
    background: $tomato-red;
  }

  .fa-envelope-open-text {
    background: $pending;
  }

}