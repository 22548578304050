.widget-empty-placeholder {
  margin-top: 53px;
  margin-bottom: 48px;
  align-items: center;
  display: flex;
  flex-direction: column;

  &__icon {
    font-size: 64px;
    margin-bottom: 15px;
    color: $graphite-dark;
  }

  &__content {
    max-width: 45%;
    text-align: center;
  }
}
