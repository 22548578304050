.td-btn-group {

  .btn {
    background-color: $graphite-light;
    color: $greyish-brown;
    border: 0;

    &:hover {
      background-color: lighten($graphite-dark, 20%);
    }

    &:active {
      background-color: $graphite-dark !important;
    }

    &--active {
      background-color: $graphite-dark;
      color: $white;

      &:hover {
        background-color: $graphite-dark;
        color: $white;
      }
    }
  }
}