$nav-bar-width: 250px;

.navbar-col {
  background: #2B304F;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-basis: $nav-bar-width;
  flex-shrink: 0;
  min-width: 1px;
  max-width: $nav-bar-width;
  box-sizing: content-box;
  visibility: visible;
  box-shadow: 1px 0 0 $graphite-light;
  z-index: 12;
  overflow-y: auto;
  overflow-x: hidden;

  .account-menu__sub-item.dropdown-item.active,
  .account-drop-down__sub-item.dropdown-item.active,
  .account-drop-down__sub-item.dropdown-item:active {
    background: $pale-gray-two;
    color: $black;
  }

  .navbar__container {
    overflow: auto;
  }

  .navbar__menu {
    overflow: auto;
    &--disabled > * {
      pointer-events: none;
      opacity: 0.35;
    }
  }

  .navbar-section {
    position: absolute;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background: #2B304F;
    transform: translateX(-100%);
    transition: transform .22s;
    z-index: 5;
    max-width: 100%;

    &--open {
      transform: translateX(0);
      height: calc(100vh - 285px - 70px);
      position: static;
    }

    &__header {
      padding: 0px 19px 15px;

      i {
        font-size: 16px;
        color: $white;
        padding: 0 10px;
        margin: 0 -10px;
      }
    }

    &__title {
      color: $black;
      font-size: 16px;
      font-weight: $font-weight-semibold;
      margin-left: 20px;
    }
  }

  .time-tracker-active {
    background-color: $pending;
    border-radius: 4px;
    color: $white;
    font-size: 12px;
  }
}

.navbar_user-info {
  display: flex;
  margin: 15px 0 15px;
  min-height: 38px;
  padding: 0 15px;

  .profile-pic {
    margin-right: 14px;
  }
}

.navbar_user-details {
  max-width: 170px;
}

.navbar__name {
  color: $black-two;
  font-size: 16px;
  font-weight: $font-weight-semibold;
}

.navbar__job-title {
  color: $warm-gray;
  font-size: 12px;
}

.navbar__top-section {
  flex-grow: 0;
  flex-basis: 100%;
  overflow-y: auto;
}

.navbar__footer {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-end;
  position: relative;
  padding: 15px 0;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
  justify-content: flex-end;
  margin-top: 50px;

  .navbar__footer-section {
    padding: 0 15px 16px;
  }

  .navbar-footer-toggle,
  .td-drop-button__toggle {
    color: $white;
    display: block;
    width: 100%;
    border-radius: 4px;
    transition: background-color 0.2s;

    &--active {
      color: #fff;
      background-color: $white-40;
    }

    &:hover {
      background-color: $white-40;
    }
  }

  .navbar__footer-plan-link {
    align-items: center;
    border-radius: 4px;
    color: $white-75;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    padding: 12px 14px;
    text-decoration: none;

    .countdown-timer {
      background-color: $white-75;
    }

    .countdown-timer__inner {
      color: $white-75 !important;
      background-color: $black;
    }

    .countdown-timer__circle .mask .fill {
      background-color: $white;
    }

    &:hover {
      background-color: $white-40;
      text-decoration: none;

      .countdown-timer__inner {
        background-color: #7f8396;
      }
    }

    &--active,
    &--active:hover {
      background-color: $graphite-dark;
      color: $white;

      .countdown-timer__inner {
        background-color: $graphite-dark;
      }
    }
  }

  > div {
    width: 100%;
    color: $white;
  }

  img {
    height: 20px;
    margin-top: -3px;
    margin-left: 3px;
  }
}
.navbar__menu {
  list-style: none;
  padding: 0 15px;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  &-inActive {
    overflow: hidden;
    max-height: 0;
  }
}

.navbar__icon--active {
  display: none;
}

.navbar__submenu {
  display: block;
  list-style: none;
  padding-bottom: 8px;

  li {
    background: $white-10;
    border-radius: 4px;
    display: block;
    margin-top: 8px;

    > a {
      display: block;
      color: $white-75;
      font-size: 14px;
      font-weight: $font-weight-regular;
      padding: 6px 15px 6px 45px;
      border-radius: $borderRadius;

      &:hover,
      &:focus,
      &:active {
        background-color: $white-40;
        text-decoration: none;
      }
    }

    > a.navbar__menuItem--state-active {
      color: $white;
      font-weight: $font-weight-semibold;
      background-color: $graphite-dark;
    }
  }
}

.navbar__sectionItem {
  color: $black;
  border-radius: $borderRadius;

  .get-started-progress {
    font-size: 10px;
    min-width: 6rem;
    position: absolute;
    right: 15px;
    text-transform: uppercase;
    top: 50%;
    transform: translateY(-50%);
  }
}

.navbar__menuItem--state-active {
  .navbar__icon--active{
    display: inline;
  }

  .navbar__icon--inactive {
    display: none;
  }
}

.navbar__menuItem {
  width: 100%;
  display: block;
  border-radius: $borderRadius;
  overflow: hidden;
  position: relative;

  &:not(:first-child) {
    margin-top: 5px;
  }

  > a {
    color: $white;
    display: block;
    font-size: 14px;
    font-weight: $font-weight-semibold;
    padding: 10px 15px;

    /* Deprecated */
    > img {
      margin-right: 15px;
      vertical-align: middle;
      margin-top: -4px;
    }

    i {
      font-size: 16px;
      margin-right: 12px;
      color: $white;
    }

    &:hover,
    &:active {
      color: $white;
      background-color: $white-40;
      text-decoration: none;

      i {
        color: $white;
      }
    }
  }

  &.navbar__menuItem--state-active-dark > a {
    background-color: $white-40;
    color: $white;

    .fal {
      color: $white;
    }

    .fas {
      color: $white;
    }
  }
}

.navbar-footer-toggle {
  background: $white-10;
  color: $white;
  font-size: 14px;
  font-weight: $font-weight-semibold;
  display: flex;
  align-items: center;
  padding: 10px 15px;

  > div:first-child > i,
  > i {
    font-size: 16px;
    margin-right: 15px;
  }
}

.navbar-item__chevron {
  font-size: 11px;
}

.navbar-item__slide-over-icon {
  font-size: 16px;
}

.disabled-menu-notification {
  background: #FFFFFF;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  border-radius: 4px;

  &__title {
    position: relative;
    font-size: 14px;
    font-weight: $font-weight-semibold;
    padding: 15px;
    border-bottom: 1px solid $pale-gray;
  }

  &__message {
    font-size: 12px;
    line-height: 1.5;
    padding: 15px;
  }

  &__close {
    position: absolute;
    top: 17px;
    right: 16px;
    font-size: 14px;
    color: $warm-gray;

    &:hover,
    &:active {
      color: $black;
      cursor: pointer;
    }
  }
}


.mobile-navbar {

  .navbar__footer {
    padding-top: 0;
  }

  .dropdown-item {
    width: auto !important;
  }

  .account-menu__sub-item.dropdown-item.active,
  .account-drop-down__sub-item.dropdown-item.active,
  .account-drop-down__sub-item.dropdown-item:active {
    background: $pale-gray-two;
    color: $black;
  }

  .help-drop-down__sub-item.dropdown-item:active {
    background: $pale-gray-two;
    color: $black;
  }

  .navbar__backdrop {
    display: block;
    background: $black;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    transition: opacity .30s;
    z-index: 1;
    transition-delay: .22s;

    &--active {
      opacity: 1;
      transition-delay: 0s;
    }
  }

  .navbar-section {
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: auto;
    transform: translateX(-100%);
    transition: transform .22s;
    z-index: 5;
    max-width: 100%;
    transition-delay: 0.15s;
    box-shadow: 0 1px #a0a0a0;

    &--open {
      transform: translateX(0);
      display: block;
    }

    &__header {
      padding: 25px 19px 15px;

      i {
        font-size: 16px;
        color: $white;
        padding: 0 10px;
        margin: 0 -10px;
      }
    }

    &__title {
      color: $black;
      font-size: 16px;
      font-weight: $font-weight-semibold;
      margin-left: 20px;
    }
  }

  .navbar-notifications-list {
    margin: 0;
    padding: 0;

    &__item {
      font-size: 13px;
      color: $black;
      font-weight: 400;
      display: flex;
      position: relative;

      &:hover,
      &:active {
        text-decoration: none;
      }

      > div {
        width: 100%;
        padding: 10px 15px 10px 46px;
        white-space: normal;
        color: $black;
        box-shadow: inset 0 1px $pale-gray;
        position: relative;
        background-color: $pale-gray-two;
      }

      &.seen > div {
        background: $white;
      }

      p {
        font-weight: 400;
        margin: 0;
        padding: 0;
        line-height: 17px;
        min-height: 30px;
        color: $black;
      }
    }

    &__icon {
      color: $warm-gray;
      font-size: 16px;
      margin-right: 0;
      position: absolute;
      left: 15px;
      top: 13px;
      z-index: 1;
    }

    &__meta {
      font-size: 11px;
      line-height: 1.3;
      float: none;
      color: $warm-gray;
      margin-top: 5px;
    }

    &__see-all {
      font-weight: 600;
      color: $greyish-brown;
      background-color: #fff;
      text-align: center;
      padding: 15px 10px;
      display: block;
      font-size: 13px;
      border-top: 1px solid $pale-gray-three;
      margin: 5px 0;
    }
  }

  .account-setup-list {
    .account-setup__header,
    .account-setup__step {
      box-sizing: border-box !important;
    }

    .account-setup__header {
      margin-left: 0!important;
      margin-right: 0 !important;
      padding-left: 15px;

      &:before {
        left: 15px;
      }
    }
  }
}

.settings-menu,
.settings-drop-down {
  overflow: hidden;
  .dropdown-item {
    padding: 10px 15px;
    font-size: 14px;
    font-weight: $font-weight-regular;
    display: flex;
    width: auto;
    align-items: center;
    border-radius: 4px;
    color: $white;
    &:hover {
      background-color: rgba(255, 255, 255, 0.4);
      text-decoration: none;
    }

    &.active,
    &.active:hover {
      background-color: $pale-gray;
      font-weight: 600;
    }

    i {
      margin-right: 15px;
      font-size: 16px;
    }
  }

  &__sub-item.dropdown-item {
    padding: 6px 15px 6px 45px;
    color: rgba(255, 255, 255, 0.75);
    background: rgba(255, 255, 255, 0.1);
    margin-top: 8px;

    &:hover,
    &:focus,
    &:active {
      background-color: rgba(255, 255, 255, 0.4);
      text-decoration: none;
    }

    &.active,
    &.active:hover {
      background-color: $graphite-dark;
      text-decoration: none;
      font-weight: 600;
      color: $white;
    }
  }
}
