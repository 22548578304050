.note-review-card-item {
  display: flex;
  margin-bottom: 15px;
  width: 100%;
  border: none;

  &:last-child {
    margin-bottom: 0;

    .note-review-card-item__content {
      padding-bottom: 0;
      border-bottom: none;
    }
  }

  &__profile-pic {
    width: 50px;
    margin-right: 15px;
  }

  &__name {
    color: $black;
    font-size: 18px;
    line-height: 17px;
  }

  &__meta {
    color: $warm-gray;
    font-size: 13px;
    line-height: 17px;
    box-shadow: inset 0 1px 0 0 #EDEFF2;
  }

  .rating {
    flex-basis: 145px;
  }

  &__project-title {
    color: $warm-gray;

    i {
      font-size: 11px;
      line-height: 14px;
      margin-right: 10px;
    }
  }

  &__job-title {
    font-size: 14px;
    color: $warm-gray;
  }

  &__content {
    font-size: 14px;
    font-weight: $font-weight-regular;
    color: $gray;
    margin: 7px 0 0;
  }

  .action-links {
    display: none;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 5;

    a {
      color: $warm-gray;
      font-size: 14px;

      &:hover,
      &:active,
      &:focus {
        color: $black;
        text-decoration: none;
        cursor: pointer;
      }

      & + a {
        margin-left: 25px;
      }
    }
  }

  &:hover {
    .action-links{
      display: block;
    }
  }
}

.review-request-item {
  background-color: $graphite-light;
}
