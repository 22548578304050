.dashboard-widget {
  height: 100%;

  &__footer {
    padding: 10px 16px !important;
    background-color: transparent !important;
    border-top: 1px solid $pale-gray !important;

    &--link {
      color: $td-blue !important;
      font-weight: 400 !important;
    }
  }

  &__header {
    min-height: 71px;
    line-height: 40px !important;
    border-color: $pale-gray !important;
  }

  &+ .dashboard-widget {
    margin-top: 24px;
  }

  &.pending-actions {
    i {
      font-size: 20px;
    }

    .service-order-needs-amended, .service-order-needs-confirmed, .service-order-needs-approved {
      &.worksheet .fa-file-invoice {
        --fa-secondary-color: #{$pending};
        --fa-secondary-opacity: 1;
      }

      &.expense .fa-file-invoice {
        --fa-secondary-color: #{$tomato-red};
        --fa-secondary-opacity: 1;
      }

      &.proforma-invoice .fa-file-invoice {
        --fa-secondary-color: #{$td-blue};
        --fa-secondary-opacity: 1;
      }
    }

    .task-invitation, .project-invitation {
      i {
        --fa-secondary-color: #{$tomato-red};
        --fa-secondary-opacity: 1;
      }
    }

    .task-rate-amendment, .rate-amendment, .onboarding-form-needs-review {
      i {
        --fa-secondary-color: #{$pending};
        --fa-secondary-opacity: 1;
      }
    }

    .contract-template i {
      --fa-secondary-color: #{$pending};
      --fa-secondary-opacity: 1;
    }

    .budget-request i {
      --fa-secondary-color: #{$td-blue};
      --fa-secondary-opacity: 1;
    }

    .task-completion-request i {
      --fa-secondary-color: #{$tomato-red};
      --fa-secondary-opacity: 1;
    }

    .project-application i {
      --fa-secondary-color: #{$pending-orange};
      --fa-secondary-opacity: 1;
    }

    td {
      border: 0;
      padding: 5px 15px !important;
    }

    tbody tr:last-of-type td {
      padding-bottom: 10px !important;
    }

    .icon-container {
      width: 30px;
    }
  }
}
