.dropdown-menu.help-drop-down {
  padding: 20px 0;
  width: 220px;
  max-width: 220px;
  font-size: 14px;

  &:before,
  &:after {
    display: none;
  }
}

.help-menu,
.help-drop-down {
  .dropdown-item {
    padding: 11px 17px;
    font-size: 14px;
    font-weight: $font-weight-regular;
    display: flex;
    width: 100%;
    align-items: center;

    &.active,
    &.active:hover, {
      font-weight: $font-weight-semibold;
    }

    i {
      color: $greyish-brown;
      font-size: 16px;
    }

    i, img {
      margin-right: 15px;
    }
  }

  &__sub-item.dropdown-item {
    padding-left: 55px;

    &.active,
    &.active:hover {
      background-color: $graphite-dark;
      font-weight: $font-weight-semibold;
      color: $white;
    }
  }

  strong {
    font-weight: 600;
  }

  &__name {
    font-size: 16px;
    font-weight: $font-weight-semibold;
  }

  &__job-title {
    font-weight: normal;
    font-size: 12px;
  }
}

.help-drop-down__toggle {
  border-radius: 4px;
  transition: background-color 0.2s;
}

.help-drop-down__toggle:hover,
.td-drop-button__toggle--active > .help-drop-down__toggle {
  background-color: $athens-gray;
  color: $black;
}

.help-drop-down__toggle--active,
.help-drop-down__toggle--active:hover {
  background-color: $graphite-dark !important;
  color: $white !important;
}

