.card {
  margin-bottom: 0;
  border-width: 0;
  border: none;
  box-shadow: $shadow-sm;
  border-radius: $borderRadius;

  &.shadow-none {
    border: 1px solid $graphite-light;
  }

  &.discreet {
    opacity: 0.55;
  }

  .card-header {
    background-color: transparent;
    border-color: $graphite-light;
    font-size: 18px;
    line-height: 19px;
    font-weight: $font-weight-regular;
    color: $greyish-brown;
    padding: 1.5rem 1.25rem;

    &--dark {
      background-color: $pale-gray-two;
      border-bottom: none;

      a {
        color: $warm-gray;
      }

      a:hover,
      a:active {
        color: $greyish-brown;
        text-decoration: none;
      }
    }

    i {
      font-size: 12px;
    }

    .toggle-element {
      color: $warm-gray;
      cursor: pointer;

      &.fa-chevron-left {
        // Add padding only to .fa-chevron-left so that it is alligned to .fa-chevron-down
        padding: 0 3px;
      }

      &:hover,
      &:active {
        color: $greyish-brown;
      }
    }
  }

  .card-header-tabs {
    margin-bottom: 0;
  }

  .card-body {
    background-color: #fff;
    border-radius: $borderRadius;

    &--dark {
      background-color: $pale-gray-two;
    }
  }

  .card-footer {
    padding: 10px 20px;
    line-height: 25px;
    min-height: 46px;
    background-color: $pale-gray-two;
    border-top: none;

    .no-tags {
      line-height: 39px;
      font-size: 13px;
      color: $warm-gray;
    }
  }

  &--disabled {
    opacity: 0.5;
  }

  .card-footer {
    display: block;

    > .row {
      margin-left: -20px;
      margin-right: -20px;
    }

    a {
      text-decoration: none;
      color: $warm-gray;
      line-height: 26px;
      font-size: 13px;
      font-weight: $font-weight-semibold;

      &:hover,
      &:active {
        cursor: pointer;
        text-decoration: none;
        color: $black;
      }

      &.disabled,
      &.disabled:hover {
        opacity: 0.7;
        color: $warm-gray;
        cursor: default;
      }
    }

    .action-buttons {
      text-align: right;

      .btn {
        color: $warm-gray;
        cursor: pointer;
        line-height: 24px;
        font-size: 18px;
        border: none;
        padding: 2px 0;
        min-width: 24px;

        &:hover,
        &:active,
        &:focus {
          color: $black;
          text-decoration: none;
        }

        .fal,
        .fas {
          font-size: 15px;
        }
      }

      .btn + .btn,
      .btn + .btn-group {
        margin-left: 20px;
      }
    }
  }

  .card-body {
    padding: 15px;
    > .list-group .list-group-item {
      border-radius: 0;
      border-width: 0;

      & + .list-group-item {
        border-top-width: 1px;
      }
    }
  }

  &.card--collapsible {
    .card-header {
      cursor: pointer;
    }
  }

  &.no-styling {
    border: none;
    margin: 0;
    box-shadow: none;
    background: none;

    .card-body {
      background: none !important;
      padding: 20px;
    }
  }

  + .card {
    margin-top: 1rem;
  }

  &--pending,
  &--rejected {
    border-color: rgba($pending, 0.1) !important;

    .card-footer {
      a:hover {
        color: $white;
      }
    }
  }

  .card-footer {
    a:hover {
      text-decoration: underline;
    }
  }

  &--pending, &--draft {
    border: 1px solid rgba($pending, 0.2) !important;

    .card-body {
      background-color: rgba($pending, 0.1);
    }
    .card-footer {
      background-color: $pending;
    }
  }

  &--rejected, &--deleted {
    .card-body {
      background-color: rgba($tomato-red, 0.1);
    }
    .card-footer {
      background-color: $tomato-red;
    }
  }

  &--light-gray, &--deactivated {
    .card-body {
      background-color: $list-item-background-color;
    }
  }

  &--deactivated {
    opacity: 0.8;
    .card-footer {
      background-color: $warm-gray-two;
    }
  }

  &--accepted {
    .card-footer {
      background-color: $medium-green;
    }
  }

  .card-footer {
    &--secondary {
      background-color: $transparent;
      border-top: 1px solid $pale-gray;
      a {
        color: $td-blue;
        font-weight: 400;
        &:hover {
          color: $td-blue;
        }
      }
    }
  }
}
