.navbar__menu,
.nav-item {
  .badge {
    background-color: $td-blue;
    color: $white;
    font-weight: $font-weight-semibold;
    display: inline-block;
    min-width: 14px;
    height: 20px;
    padding: 0 3px;
    border-radius: 15px;
    font-size: 11px;
    text-align: center;
    line-height: 20PX;
    margin-top: -1px;
    border: none;

    &.badge--inline {
      line-height: 15px;
      background-color: $td-blue;
      color: $white;
      border-radius: 2px;
      height: 16px;
    }
  }
}
