.organization-switcher {
  position: relative;
  z-index: 7;

  a:hover,
  a:active {
    text-decoration: none;
  }
  &__active-org-logo,
  .organization-logo {
    margin-right: 10px;
  }

  &__selected-org {
    display: block;
    cursor: pointer;
    position: relative;

    .organization-switcher__switch-icon {
      color: $white;
    }

    &:hover {
      background-color: $white-40;

      .organization-switcher__switch-icon {
        color: $white;
      }
    }
  }

  &__orgs-list {
    li {
      display: block;
      box-shadow: 0 1px $graphite-light inset;

      &:hover {
        background-color: $pale-gray-two;
      }

      a {
        padding: 16px 15px 15px;
        display: block;
      }
    }
  }

  &__switch-icon {
    position: absolute;
    top: 25px;
    right: 17px;
    color: $graphite-light;
    font-size: 16px;
  }

  &__org-item {
    padding: 15px 40px 15px 15px;
    display: block;
    color: $black;
    font-size: 15px;
    font-weight: $font-weight-semibold;
    height: 40px;

    &:hover,
    &:focus,
    &:active {
      color: $black;
      text-decoration: none;
    }

    &--active {
      background: $pale-gray;
    }
  }

  &__org-title {
    color: $white;
    margin-top: 3px;
    display: block;
    line-height: 20px;
  }

  &__user-role {
    display: block;
    font-size: 12px;
    line-height: 13px;
    color: $white;
    font-weight: $font-weight-regular;
  }

  &__create-org {
    padding: 20px 15px;
    box-shadow: 0 1px $graphite-light inset;

    a {
      display: block;
      padding: 10px;
      color: $black;
      font-size: 14px;
      font-weight: $font-weight-semibold;
      line-height: 17px;
      background-color: $white;
      border-radius: 4px;
      border: 1px solid $warm-gray-three;
      text-align: center;

      &:hover,
      &:active {
        background-color: $pale-gray-five;
      }
    }

    i {
      color: $black;
      margin-right: 5px;
    }
  }

  &__backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    visibility: hidden;
    opacity: 0;
    z-index: 1;
    transition: all 0.3s;
  }

  &__expanded-area {
      position: absolute;
      box-shadow: 0 6px 5px -5px rgba(0,0,0,.2);
      width: 100%;
      background: #fff;
      display: none;
  }

  &--open {
    .organization-switcher__expanded-area {
      display: block;
    }
    .organization-switcher__selected-org {
      background-color: $graphite-light;
    }

    &:hover .organization-switcher__switch-icon,
    .organization-switcher__switch-icon {
      color: $white-40;
    }

    &+ .organization-switcher__backdrop {
      visibility: visible;
      opacity: 1;
      transition-delay: 0;
    }

    .organization-switcher__org-title {
      color: $black;
    }

    .organization-switcher__user-role {
      color: $black;
    }
  }
}

.mobile-navbar {
  .organization-switcher {
    &__selected-org {
      .organization-switcher__switch-icon {
        color: $white;
      }
    }
  }
}
