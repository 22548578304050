.bank-account-card {
    border-radius: $borderRadius;

    .card-body {
      padding: 23px 15px;
      border-radius: $borderRadius;
    }

    &__title {
      font-weight: $font-weight-semibold;
    }

    &__amount {
      font-weight: $font-weight-semibold;
    }

    &__dropdown_item_info {
      text-align: center;
    }

    .status {
      font-weight: $font-weight-regular;
      line-height: 1;
    }

  }

  .bank-account-selector{
    &.hide-help-block{
      .help-block {
        display: none !important;
      }
    }
  }

  .bank-account {
    &--payoneer-status {
      i {
        font-size: 60px;
      }
    }
  }
