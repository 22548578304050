// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #555!default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$theme-colors: (
  "primary": $td-blue,
  "success": $medium-green,
  "warning": $pending,
  "danger": $tomato-red,
  "info": $lightblue-two,
);

$box-shadow-sm: $shadow-sm; // Set on core/assets/sass/abstract/variables

// Buttons

$btn-transition: all 0;
$input-btn-focus-width: 0;

// Tabs

$nav-tabs-link-active-bg: transparent;

// Tooltips

$tooltip-bg: $black;
$tooltip-opacity: 1;
$tooltip-font-size: 1.1rem;


// Spacers
$spacer: 1rem;
$spacers: ();

// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
    (
      0: 0,
      1: ($spacer * .25),
      2: ($spacer * .5),
      3: $spacer,
      4: ($spacer * 1.5),
      5: ($spacer * 2)
    ),
    $spacers
);
