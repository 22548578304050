.error-page{
  max-width: 740px;
  padding: 0 30px;
  margin: 0 auto;

  &__logo-container{
    display: block;
    width: 180px;
    margin: 80px auto 0;

    img{
      display: inline-block;
      width: 100%;
      height: auto;
    }
  }

  &__icon{
    display: block;
    float: left;
    width: 96px;
    margin: 5px 30px 100px 0;
  }

  &__code{
    color: $td-blue;
    font-size: 60px;
    letter-spacing: -1.4px;
    line-height: 1;
    font-weight: $font-weight-semibold;
    margin: 0 0 5px;
  }

  &__title{
    color: $greyish-brown;
    font-size: 18px;
    letter-spacing: -0.4px;
    font-weight: $font-weight-semibold;
    margin: 0 0 10px;
  }

  &__msg{
    padding: 0;
    margin: 0 0 10px;
    font-weight: 300;
  }

  a{
    color: $td-blue;
  }

  /* Inline version: */
  &__wrapper{
    padding: 35% 0 50px;

    &--inline{
      padding: 30px;
      
      .error-page{
        &__icon{
          display: block;
          float: left;
          width: 40px;
          margin-right: 20px;
        }

        &__code{
          font-size: 40px;
          margin: 0;
        }

        &__title{
          font-size: 14px;
          margin: 0 0 10px;
        }
      }
    }
  }
}
