@page {
  size: A4;
  margin: 10mm;
}

@media print {
  html, body {
    width: 210mm;
    height: 297mm;
  }

  html {
    .do-not-print {
      display: none !important;
    }

    .react-bs-table-container .react-bs-table{
      min-width: unset;
    }

    .react-bs-table-container{
      overflow: hidden;
    }

    .finance-report__heading {
      display: none;
    }

    .finance-report__content {
      padding: 0;
    }

    .finance-report__meta {
      float: right;
    }

    .finance-report__content .container{
      border: none;
    }

    .react-bs-table .table>tbody>tr>td,
    .react-bs-table .table>tbody>tr>th,
    .react-bs-table .table>tfoot>tr>td,
    .react-bs-table .table>tfoot>tr>th,
    .react-bs-table .table>thead>tr>td,
    .react-bs-table .table>thead>tr>th {
      padding: 8px;
    }

    .react-bs-table colgroup col{
      width: auto !important;
      min-width: unset !important;
    }

    .finance-report__summary>div>label{
      width: 200px;
    }
    .finance-report__summary>div>span {
      width: 110px;
    }

    .finance-report__summary {
      padding: 10px 0;

      > div {
        padding: 4px 8px 2px;

        label,
        span {
          padding: 0;
        }
      }
    }

    .discreet{
      color: #777 !important;
    }

    .finance-report .invoice-table-wrapper .react-bs-container-header, 
    .finance-report .worksheets-table-wrapper .react-bs-container-header {
      border-bottom: none;
    }

    .finance-report__organization-text-logo{
      float: left !important;
      display: inline-block !important;
      padding: 34px 20px!important;
      font-size: 15px !important;
      min-width: 200px !important;
      float: left !important;
      max-width: 300px !important;
    }
  }
}