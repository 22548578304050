.worksheet-breakdown {
  border: 1px solid $graphite-light;
  .description {
    width: 55%;
  }

  td {
    padding: 15px;
    font-size: 14px;
    color: $black;
  }
}
