.dropdown-toggle-split {
  padding-left: 15px;
  padding-right: 15px;

  > .fal{
    font-size: 14px !important;
  }

  &:hover {
    &:before {
      opacity: 0.1;
    }
  }

  &:not([disabled]):before {
    content: "";
    display: block !important;
    width: 1px;
    height: 70%;
    background-color: $white;
    opacity: 0.3;
    position: absolute;
    left: 0px;
    top: 15%;
    z-index: 5;
  }

  &:after {
    display: none;
  }
}

[disabled] + .dropdown-toggle-split:before {
  display: none !important;
}

.td-dropdown.btn-group {
  text-wrap: nowrap;

  .btn-primary {
    &:focus,
    &:hover {
      border-color: #1276B1;
    }

    &:active,
    &:active:hover,
    &:active:focus {
      border-color: $td-blue;
    }
  }
}
