.content-col {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 1px;
  overflow-y: auto;
  overflow-x: hidden;
}

/* Appended to body */
.actions-dropdown {
  margin-top: 25px !important;

  &:before {
    right: 19px !important;
  }
  &:after {
    right: 20px !important;
  }
}

.content-header {
  width: 100%;
  z-index: 5;

  &__wrapper {
    position: relative;
    box-shadow: 0 -1px 0 0 $pale-gray inset;

    &:after {
      content: "";
      display: block;
      width: 105%;
      height: 6px;
      box-shadow: 0 2px 5px -2px rgba(0,0,0,.25);
      position: absolute;
      left: -10px;
      z-index: 5;
      pointer-events: none;
      bottom: 0;
    }
  }

  .btn {
    text-transform: none;
  }

}

.content-box {
  padding: 0 30px 40px 30px;

  &__header {
    margin: -20px -20px 20px;
    background-color: transparent;
    border-bottom: 1px solid $pale-gray;
    padding: 20px 20px 17px;
    font-size: 13px;
    line-height: 1em;
    color: $greyish-brown;
    text-transform: uppercase;
    font-weight: $font-weight-semibold;
  }
}

blockquote {
  font-size: 14px;
  color: $warm-gray;
  position: relative;
  overflow: hidden;
  padding: 10px 10px 10px 20px;
  &::after{
    content: "";
    height: 100%;
    background: $gray-400;
    width: 6px;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 5px;
    opacity: 1;
  }
}


@media (max-width: map-get($grid-breakpoints, sm)) {
  .content-header .td-drop-button__toggle {
    .btn,
    .btn:hover,
    .btn:active {
      background-color: transparent !important;
      border: none;
      padding-left: 10px;
      padding-right: 10px;

      span {
        display: none;
      }

      img {
        margin: 0;
      }
    }

    .btn:active {
      img {
        opacity: 0.8;
      }
    }
  }

  .actions-dropdown {
    margin-top: 15px !important;

    &:before {
      right: 9px !important;
    }
    &:after {
      right: 10px !important;
    }
  }
}
