@font-face {
  font-family: 'AvertaPE';
  src:  url("~core/assets/fonts/AvertaPE/AvertaPERegular.woff2") format("woff2"),
        url("~core/assets/fonts/AvertaPE/AvertaPERegular.woff") format("woff");
  font-weight: normal
}

@font-face {
  font-family: 'AvertaPE';
  src:  url("~core/assets/fonts/AvertaPE/AvertaPESemibold.woff2") format("woff2"),
        url("~core/assets/fonts/AvertaPE/AvertaPESemibold.woff") format("woff");
  font-weight: 600
}

/**
 * Basic typography style for copy text
 */
body {
  font-family: $text-font-stack;
  font-size: 14px;
  line-height: 1.4em;
  font-weight: $font-weight-regular;
  color: $text-color;
}

//a {
//  &:hover {
//    text-decoration: underline;
//  }
//}

.hint {
  color: $warm-gray;
  font-size: 13px;
}

h1, h2, h3{
  font-weight: $font-weight-semibold;
  line-height: 1.5em;
  color: $heading-color;
}

h1{
  font-size: 26px;
  font-weight: $font-weight-regular;
  margin: 10px 0 20px;
  color: $greyish-brown;
}

h2{
  font-size: 22px;
  font-weight: $font-weight-regular;
  margin: 10px 0 20px;
}

h3{
  font-size: 18px;
  font-weight: $font-weight-regular;
  margin: 10px 0 20px;

  &.heading-block {
    display: inline-block;
    width: 100%;
    font-size: 12px;
    line-height: 15px;
    font-weight: $font-weight-semibold;
    padding: 10px 15px;
    margin: 0 0 15px;
    color: $black;
    background-color: $pale-gray-two;
    text-transform: uppercase
  }

  &.has-sub {
    display: block;
    padding-bottom: 5px;
  }
}

h4{
  font-size: 16px;
  margin: 10px 0 10px;

  &.heading-sub {
    display: block;
    width: 100%;
    font-size: 12px;
    line-height: 15px;
    font-weight: $font-weight-semibold;
    padding: 0px 15px;
    margin: 0 0 15px;
    color: $warm-gray;
    background-color: $pale-gray-two;
  }
}

h5{
  font-size: 14px;
  font-weight: $font-weight-regular;
  text-transform: uppercase;
  color: $warm-gray;
}

.font-smaller {
  font-size: 13px;
}

.font-small {
  font-size: 12px;
}

.font-bigger {
  font-size: 16px;
}

label{
  font-size: 14px;
  font-weight: $font-weight-regular;
  color: $black;

  .sublabel {
    color: $warm-gray;
    font-size: 13px;
  }
}

.discreet{
  color: $warm-gray;
}

.font-weight-bolder {
  font-weight: $font-weight-semibold !important;
}

.form-control{
  font-size: 14px;
  font-weight: $font-weight-regular;
  color: $input-color;
  border-radius: $form-control-radius;
}

.empty-list-message {
  font-size: 14px;
  font-weight: $font-weight-regular;
  min-height: 320px;

  &--no-min-height {
    min-height: auto;
  }

  i {
    font-size: 51px;
    color: $graphite-dark;
    margin-bottom: 15px;
  }

  .system-message {
    i {
      color: inherit;
      margin-bottom: 0;
    }
  }
}

::-webkit-input-placeholder {
   font-style: italic;
}
:-moz-placeholder {
   font-style: italic;
}
::-moz-placeholder {
   font-style: italic;
}
:-ms-input-placeholder {
   font-style: italic;
}

.badge-pill {
  height: 16px;
  min-width: 8px;
  text-align: center;
  padding: 0 4px;
  line-height: 16px;
  font-size: 13px;
  font-weight: 600;
  color: $black;
  border-radius: 50%;
}

.fa-duotone.inverted-colors {
  --fa-primary-opacity: 0.4;
  --fa-secondary-opacity: 1;
}
