.import-worksheets-page {

    &--info--section {
        padding-left: 2rem;
    }

    &--download--section {
        .help-block {
            max-width: 225px;
            text-align: center;
        }

        .help-block.d-inline-block.mt-3 {
            position: absolute;
            top: 44px;
            left: 25px;
        }
    }

    @media (max-width: 1250px) {
        &--download--section {
            width: 40%;
        }

        &--info--section {
            padding-left: 0;
            width: 60%;
        }
    }

    @media (max-width: 576px) {
        &--wrapper {
            flex-direction: column;
        }

        &--download--section {
            width: 100%;
        }

        &--info--section {
            padding-left: 2rem;
            width: 100%;
        }
    }


    &--download--info {
        font-size: 13px;
    }

    &--download--checkbox {
        max-width: 235px;
        display: block;
        padding-left: 1rem;
    }

    &--check--list {
        color: $greyish-brown;
        font-size: 12px;

        span {
            color: $td-blue;
        }
    }

    &--toggle-view-checkbox {
        color: #1584c6 !important;
        font-weight: 400;
        float: right;
        font-size: 15px;
    }
}
