.password-strength-meter {
  position: relative;

  .form-control-feedback {
    position: absolute;
    top: 35px;
    right: 10px;
    pointer-events: all;
    max-width: 40%;
    width: auto;
    font-size: 13px;

    i {
      position: relative;
      top: -1px;
    }
  }

  &__msg {
    margin-top: 10px;
    padding: 0 50px 0 0;
  }

  .help-block {
    margin-bottom: 0;
  }
}